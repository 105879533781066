import React from 'react';

import moment from 'moment';

import { ExportCsv } from '@material-table/exporters';

import { stocksChartOptions, makeSeries, xAxisInCET } from 'variables/charts';
import { DATE_FORMAT, DATE_FORMAT_DASH } from 'constants/general';
import { dangerColor, primaryColor } from 'assets/jss/material-dashboard-react';
import { makePlotOptions } from 'variables/charts';
import { LOCAL_ENV } from 'constants/env';

import { resampleArrToHoursDim, resampleArrToGran, oneDimArr, calcGranData } from 'utils/calcFunctions';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

import localForage from 'localforage';
import { HelpOutline } from '@material-ui/icons';

// mui styles
export const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  container: {
    minHeight: '31rem',
  },
  table: {
    width: '100%',
    height: '85vh',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  sizeS: {
    left: 400,
    top: '2rem',
    width: 'auto',
    height: 'auto',
  },
  block: {
    display: 'block',
  },
  relative: {
    position: 'relative',
  },
  textLeft: {
    textAlign: 'left',
  },
  bgColor: {
    backgroundColor: '#fff',
  },
  fullWidth: {
    width: '100%',
  },
  sticky: {
    position: 'sticky',
    top: '5rem',
  },
  noMargin: {
    margin: 0,
  },
  marginTopXs: {
    marginTop: '0.2rem',
  },
  marginBottomXs: {
    marginBottom: '0.2rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  marginBottomL: {
    marginBottom: '2rem',
  },
  error: {
    color: dangerColor,
  },
  headerDataGrid: {
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      lineHeight: '1.43rem',
      whiteSpace: 'normal',
      color: primaryColor,
      fontSize: '1em',
    },
  },
  cardCategory: {
    color: '#999999',
    margin: '10px 0',
    fontSize: '14px',
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    fontSize: '30px',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: '#999999',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  cardKpi: {
    fontSize: '18px',
  },
  cardContainer: {
    display: 'flex',
  },
  card: {
    minHeight: '600px',
    height: '100%',
  },
  stickyHead: {
    position: 'sticky',
    top: '10px',
    zIndex: '99',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
  cardCell: {
    border: '1px solid rgb(224, 224, 224)',
  },
  leftCellBorder: {
    borderLeft: '2px solid rgb(224, 224, 224)',
  },
  tableContainer: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    margin: '1.25rem 0 0',
    padding: '0 15px',
    position: 'relative',
    minHeight: '600px',
    height: '100%',
  },
  strategyHead: {
    margin: '0',
    position: 'absolute',
    backgroundColor: 'green',
    top: '-20px',
    padding: '15px',
    borderRadius: '10px',
    color: '#fff',
    background: 'linear-gradient(60deg, #66bb6a, #43a047)',
    boxShadow:
      '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
  },
  autocompleteMaxHeight: {
    position: 'initial',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  hiddenTable: {
    display: 'none',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  },
  fromStart: {
    justifyContent: 'start',
  }
};

// Measures
export const MEASURE_PROFIT = 'Profit';
export const MEASURE_PROFIT_MWH = 'Profit/MWh';
export const MEASURE_DAILY_AC = 'Daily accuracy';
export const MEASURE_SHARPE_RATIO = 'Sharpe ratio';
export const MEASURE_MAX_DRAWDOWN = 'Max drawdown';

// DAvsID, DAvsIB, IDvsID, IDvsIB, DA+IDvsIB, DAvsID+IB, DA+IDvsID+IB
export const STRATEGIES = ['DAvsIB', 'DAvsID', 'IDvsID', 'IDvsIB', 'DAvsDA', 'DAIDvsIB', 'DAvsIDIB'];

export const CONTRACTS = ['1h','30min','15min'];

const ONE_DAY_INTERVAL = 86400;

// Axes
const AXES = (currency) => ({
    [MEASURE_PROFIT_MWH]: {
        value: MEASURE_PROFIT_MWH,
        unit: `${currency}/MWh`,
    },
    [MEASURE_PROFIT]: {
        value: MEASURE_PROFIT,
        unit: `${currency}`,
    },
    [MEASURE_DAILY_AC]: {
        value: MEASURE_DAILY_AC,
        unit: '%',
    },
    [MEASURE_MAX_DRAWDOWN]: {
        value: MEASURE_MAX_DRAWDOWN,
        unit: `%`,
    },
    [MEASURE_SHARPE_RATIO]: {
        value: MEASURE_SHARPE_RATIO,
        unit: '',
    },
});

// Array of days from 1 to 31
export const daysBackInit = Array.from({ length: 31 }, (_, i) => (i + 1).toString());

const renderColumnTitle = (title, description) => (
    <div 
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
        }}
    >
        <span>{title}</span>
        <CustomTooltip title={description}>
            <HelpOutline fontSize='small'/>
        </CustomTooltip>
    </div>
)

// Table Columns of Material Table
export const columns = (currency, helpModeActive, battery, menu = '') => {
    const baseColumns = [
        {
            title: 'Model Name',
            titleExport: 'Model Name',
            field: 'name',
            width: '20rem',
            cellStyle: {
                backgroundColor: 'white',
                position: 'sticky',
                left: 0,
                zIndex: 1,
            },
            headerStyle: {
                left: '0px',
                zIndex: 3,
            },
        },
        {
            title: 'Market',
            titleExport: 'Market',
            field: 'market',
            width: '8rem',
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Avg PnL/MWh, ${currency}/MWh`, 'The average PnL per MWh over the selected period')
                : `Avg PnL/MWh, ${currency}/MWh`,
            titleExport: `Avg PnL/MWh, ${currency}/MWh`,
            field: 'avgPnlMWh',
            width: 180,
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Total PnL, ${currency}`, 'The total PnL for the selected period across all trades')
                : `Total PnL, ${currency}`,
            titleExport: `Total PnL, ${currency}`,
            field: 'totalPnlFormatted',
            width: '12rem',
            render: (params) => params.totalPnlFormatted.toLocaleString(),
        },
        {
            title: helpModeActive
                ? renderColumnTitle(`Gross Profit, ${currency}`, 'The total profit before accounting for losses')
                : `Gross Profit, ${currency}`,
            titleExport: `Gross Profit, ${currency}`,
            field: 'grossProfit',
            width: '10rem',
            render: (params) => params.grossProfit.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Gross Loss, ${currency}`, 'The total losses for the selected period')
                : `Gross Loss, ${currency}`,
            titleExport: `Gross Loss, ${currency}`,
            field: 'grossLoss',
            width: '10rem',
            render: (params) => params.grossLoss.toLocaleString(),
        },
        {
            title: helpModeActive
                ? renderColumnTitle(`Max Daily Pnl, ${currency}`, 'The maximum daily profit')
                : `Max Daily Pnl, ${currency}`,
            titleExport: `Max Daily Pnl, ${currency}`,
            field: 'maxPnl',
            width: '10rem',
            render: (params) => params.maxPnl.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Min Daily Pnl, ${currency}`, 'The minimum daily profit')
                : `Min Daily Pnl, ${currency}`,
            titleExport: `Min Daily Pnl, ${currency}`,
            field: 'minPnl',
            width: '10rem',
            render: (params) => params.minPnl.toLocaleString(),
        },
        ...(menu === 'pnlPeriod' 
            ? [
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Optimised, ${currency}`, 'The cumulative financial result combining all optimised values (day-ahead, intraday, and imbalance)') 
                        : `Total Optimised, ${currency}`,
                    titleExport: 'Total Optimised',
                    field: 'totalOptimised',
                    width: '8rem',
                    render: (params) => params.totalOptimised.toLocaleString(),
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Base, ${currency}`, 'The cumulative PnL values combining all baseline results (day-ahead and imbalance)') 
                        : `Total Base, ${currency}`,
                    titleExport: 'Total Base',
                    field: 'totalBase',
                    width: '8rem',
                    render: (params) => params.totalBase.toLocaleString(),
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Added value, ${currency}`, 'The added value obtained from the optimisation compared to the base values. Calculated as the difference between "Total Optimised" and "Total Base"') 
                        : `Added value, ${currency}`,
                    titleExport: 'Added value',
                    field: 'addedValue',
                    width: '8rem',
                    render: (params) => params.addedValue.toLocaleString(),
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Volume, MWh`, 'The total energy traded in MWh') 
                        : `Total Volume, MWh`,
                    titleExport: 'Total Volume, MWh',
                    field: 'totalEnergy',
                    width: '8rem',
                    render: (params) => params.totalEnergy.toLocaleString(),
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Buy Volume, MWh`, 'The total volume of energy purchased in MWh')
                        : `Total Buy Volume, MWh`,
                    titleExport: `Total Buy Volume, MWh`,
                    field: 'totalBuy',
                    width: '9rem',
                    render: (params) => params.totalBuy.toLocaleString()
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Sell volume, MWh`, 'The total volume of energy sold in MWh')
                        : `Total Sell volume, MWh`,
                    titleExport: `Total Sell volume, MWh`,
                    field: 'totalSell',
                    width: '9rem',
                    render: (params) => params.totalSell.toLocaleString(),
                },
            ] : []
        ),
        {
            title: helpModeActive
                ? renderColumnTitle(`Sharpe Ratio`, 'An indicator of risk-adjusted return')
                : `Sharpe Ratio`,
            titleExport: `Sharpe Ratio`,
            field: 'sharpeRatio',
            width: '11rem',
            render: (params) => params.sharpeRatio.toLocaleString(),
        },
        {
            title: helpModeActive
                ? renderColumnTitle(`Risk Ratio`, 'The level of risk associated with the trading strategy')
                : `Risk Ratio`,
            titleExport: `Risk Ratio`,
            field: 'riskRatio',
            width: '10rem',
            render: (params) => params.riskRatio.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Sortino Ratio`, 'A return metric focusing on downside risk only')
                : `Sortino Ratio`,
            titleExport: `Sortino Ratio`,
            field: 'sortinoRatio',
            width: '11rem',
            render: (params) => params.sortinoRatio.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Max Drawdown, %`, 'The maximum percentage decline from the peak')
                : `Max Drawdown, %`,
            titleExport: `Max Drawdown, %`,
            field: 'maxDrawDown',
            width: '13rem',
            render: (params) => params.maxDrawDown.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Maximum peak to trough, %`, 'The maximum difference between the peak and the lowest point')
                : `Maximum peak to trough, %`,
            titleExport: `Maximum peak to trough, %`,
            field: 'maxPeakToTrought',
            width: '12rem',
            render: (params) => params.maxPeakToTrought.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Total PnL/Gross loss`, 'The ratio of total profit to gross losses')
                : `Total PnL/Gross loss`,
            titleExport: `Total PnL/Gross loss`,
            field: 'profitFactor',
            width: '10rem',
            render: (params) => params.profitFactor.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Total PnL/Max Drawdown`, 'The ratio of total profit to maximum drawdown') 
                : `Total PnL/Max Drawdown`,
            titleExport: `Total PnL/Max Drawdown`,
            field: 'netProfit',
            width: '10rem',
            render: (params) => params.netProfit.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`PnL Volatility, %`, 'The variability of profit or loss over time, expressed as a percentage')
                : `PnL Volatility, %`,
            titleExport: `PnL Volatility, %`,
            field: 'volaility',
            width: '12rem',
            render: (params) => params.volaility.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Avg positive PnL, ${currency}/MWh`, 'The average of daily profits')
                : `Avg positive PnL, ${currency}/MWh`,
            titleExport: `Avg positive profit, ${currency}/MWH`,
            field: 'avgPosPnl',
            width: '10rem',
            render: (params) => params.avgPosPnl.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Avg negative PnL, ${currency}/MWh`, 'The average of daily losses') 
                : `Avg negative PnL, ${currency}/MWh`,
            titleExport: `Avg negative loss, ${currency}/MWh`,
            field: 'avgNegPnl',
            width: '10rem',
            render: (params) => params.avgNegPnl.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Ratio Avg pos to neg`, 'The ratio of average daily profits to average daily losses') 
                : `Ratio Avg pos to neg`,
            titleExport: `Ratio Avg pos to neg`,
            field: 'ratioAvgPosNeg',
            width: '10rem',
            render: (params) => params.ratioAvgPosNeg.toLocaleString(),
        },
        ...(menu !== 'pnlPeriod'
            ? [
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Volume, MWh`, 'The total energy traded in MWh') 
                        : `Total Volume, MWh`,
                    titleExport: 'Total Volume, MWh',
                    field: 'totalEnergy',
                    width: '8rem',
                    render: (params) => params.totalEnergy.toLocaleString(),
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Buy Volume, MWh`, 'The total volume of energy purchased in MWh')
                        : `Total Buy Volume, MWh`,
                    titleExport: `Total Buy Volume, MWh`,
                    field: 'totalBuy',
                    width: '9rem',
                    render: (params) => params.totalBuy.toLocaleString()
                },
                {
                    title: helpModeActive 
                        ? renderColumnTitle(`Total Sell volume, MWh`, 'The total volume of energy sold in MWh')
                        : `Total Sell volume, MWh`,
                    titleExport: `Total Sell volume, MWh`,
                    field: 'totalSell',
                    width: '9rem',
                    render: (params) => params.totalSell.toLocaleString(),
                },
            ] : []
        ),
        {
            title: helpModeActive 
                ? renderColumnTitle(`Ratio of Buys and sells`, 'The ratio of bought and sold volume') 
                : `Ratio of Buys and sells`,
            titleExport: `Ratio of Buys and sells`,
            field: 'ratioBuySell',
            width: '9rem',
            render: (params) => params.ratioBuySell.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Total number of trades`, 'The total number of trading operations') 
                : `Total number of trades`,
            titleExport: `Total number of trades`,
            field: 'totalTradesNum',
            width: '10rem',
            render: (params) => params.totalTradesNum.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Number of non traded hours`, 'The total number of hours without trading activity') 
                : `Number of non traded hours`,
            titleExport: `Number of non traded hours`,
            field: 'zeroTrades',
            width: '10rem',
            render: (params) => params.zeroTrades.toLocaleString(),
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Number of positive pnl hours`, 'The number of hours with a positive profit outcome') 
                : `Number of positive pnl hours`,
            titleExport: `Number of positive pnl hours`,
            field: 'winTrades',
            width: '10rem',
            render: (params) =>
                params.isDaVsIbPark ? params.winTrades.toLocaleString() : '-',
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Number of negative pnl hours`, 'The number of hours with a negative profit outcome') 
                : `Number of negative pnl hours`,
            titleExport: `Number of negative pnl hours`,
            field: 'looseTrades',
            width: '10rem',
            render: (params) =>
                params.isDaVsIbPark ? params.looseTrades.toLocaleString() : '-',
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Hourly accuracy, %`, 'The share of trades that ended with a profit') 
                : `Hourly accuracy, %`,
            titleExport: `Hourly accuracy, %`,
            field: 'percentProf',
            width: '10rem',
            render: (params) =>
                params.isDaVsIbPark ? params.percentProf.toLocaleString() : '-',
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Number of positive pnl days`, 'The total number of days with positive financial results') 
                : `Number of positive pnl days`,
            titleExport: `Number of positive pnl days`,
            field: 'posDays',
            width: '10rem',
            render: (params) => params.posDays,
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Number of negative pnl days`, 'The total number of days with negative financial results') 
                : `Number of negative pnl days`,
            titleExport: `Number of negative pnl days`,
            field: 'negDays',
            width: '10rem',
            render: (params) => params.negDays,
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Daily accuracy, %`, 'The share of days with positive PnL results') 
                : `Daily accuracy, %`,
            titleExport: `Daily accuracy, %`,
            field: 'accuracyDay',
            width: '10rem',
            render: (params) => params.accuracyDay,
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Max consecutive profitable days`, 'The maximum number of consecutive profitable days') 
                : `Max consecutive profitable days`,
            titleExport: `Max consecutive profitable days`,
            field: 'maxConPosDays',
            width: '10rem',
            render: (params) => params.maxConPosDays,
        },
        {
            title: helpModeActive 
                ? renderColumnTitle(`Max consecutive loss days`, 'The maximum number of consecutive loss-making days') 
                : `Max consecutive loss days`,
            titleExport: `Max consecutive loss days`,
            field: 'maxConNegDays',
            width: '10rem',
            render: (params) => params.maxConNegDays,
        },
    ];
    // if battery is not null, so Battery Trading Report menu, add battery columns
    // for battery we need two avgNbCyclesFormated and avgNbCyclesFormated
    // these columns should be after totalPnlFormatted
    // find index of totalPnlFormatted and add battery columns after it
    if (battery && menu == 'pnlPeriod') {
        const totalPnlIndex = baseColumns.findIndex((col) => col.field === 'totalPnlFormatted');
        baseColumns.splice(totalPnlIndex + 1, 0, {
            title: helpModeActive 
                ? renderColumnTitle(`Avg Nb Cycles`, 'The average number of cycles per day.') 
                : `Avg Nb Cycles`,
            titleExport: `Avg Nb Cycles`,
            field: 'avgNbCyclesFormated',
            width: '10rem',
            render: (params) => params.avgNbCyclesFormated.toLocaleString(),
        });
        baseColumns.splice(totalPnlIndex + 2, 0, {
            title: helpModeActive 
                ? renderColumnTitle(`PnL / MWh, ${currency}/MWh`, '') 
                : `PnL / MWh, ${currency}/MWh`,
            titleExport: `PnL / MWh, ${currency}/MWh`,
            field: 'pnLMWhFormated',
            width: '10rem',
            render: (params) => params.pnLMWhFormated.toLocaleString(),
        });
    }
    return baseColumns;
};

const exportFunc = (cols, datas) => {
    const colsExport = cols.map((col) => ({
        ...col,
        title: col.titleExport ? col.titleExport : col.title,
    }));
    return ExportCsv(colsExport, datas, 'PnlKpiData');
};

// options of Material Table
export const options = (pageSize) => ({
    title: 'Title',
    tableLayout: 'fixed',
    paging: true,
    maxBodyHeight: '85vh',
    headerStyle: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        color: primaryColor,
    },
    pageSizeOptions: [10, 25, 50, 100],
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    draggable: false,
    doubleHorizontalScroll: true,
    exportMenu: [
        {
            label: 'Export CSV',
            exportFunc: exportFunc,
        },
    ],
})

// Range Buttons
export const rangeButtons = [
    {
        type: 'week',
        count: 1,
        text: '1w',
        title: 'View 1 week',
        order: 0,
        preloading: true,
    },
    {
        type: 'week',
        count: 2,
        text: '2w',
        title: 'View 2 weeks',
        order: 1,
        preloading: true,
    },
    {
        type: 'mtd',
        count: 1,
        text: 'mtd',
        title: 'View month-to-date',
        order: 2,
        preloading: true,
    },
    {
        type: 'month',
        count: 1,
        text: '1m',
        title: 'View 1 month',
        order: 3,
        preloading: true,
    },
    {
        type: 'month',
        count: 2,
        text: '2m',
        title: 'View 2 months',
        order: 4,
        preloading: true,
    },
    {
        type: 'month',
        count: 6,
        text: '6m',
        title: 'View 6 months',
        order: 5,
        preloading: true,
    },
    {
        type: 'ytd',
        count: 1,
        text: 'ytd',
        title: 'View year-to-date',
        order: 6,
        preloading: true,
    },
    {
        type: 'year',
        count: 1,
        text: '1y',
        title: 'View 1 year',
        order: 7,
        preloading: true,
    },
    {
        type: 'year',
        count: 2,
        text: '2y',
        title: 'View 2 years',
        order: 8,
        preloading: true,
    },
];

//Maximum drawdown
export const maxDrawdownFun = (prices) => {
    if (prices.length <= 1) return 0;
    const arrDD = [0];
    for (let i = 1; i < prices.length; i++) {
        const max = Math.max(...prices.slice(0, i + 1));
        if (max === 0) arrDD.push(0);
        else arrDD.push((prices[i] - max) / max);
    }
    return Math.abs(Math.min(...arrDD));
};

//Amount of curves visible in the chart
export const CURVES_SHOW = 10;

// Get range of dates based of passed a type: "week, month, mtd, ytd, year"
// and a count of items: 1 for 1 week, etc.
export const getRange = (type, count) => {
    // First available day is yesterday
    const dtTo = moment().add(-1, 'days');
    let dtFrom;
    switch (type) {
        case 'ytd':
            // As starting date is always yesterday,
            // we should get month/year value from that date
            dtFrom = dtTo.clone().startOf('year');
            break;
        case 'mtd':
            dtFrom = dtTo.clone().startOf('month');
            break;
        default:
            dtFrom = moment().add(-count, type);
    }
    return { dtFrom, dtTo };
};
export const INITIAL_DATE_FROM = moment().add(-7, 'days');
export const INITIAL_DATE_TO = moment().add(-1, 'days');

export const modelsCount = 100;

//Volatility
const calcVolatility = (returns, avgReturns) => {
    const deviationSq = returns.map(
        (el) => (el - avgReturns) * (el - avgReturns)
    );
    let sumDeviationSq = 0;
    let variance = 0;
    let volaility = 0;
    deviationSq.forEach((el) => (sumDeviationSq += el));
    if (sumDeviationSq && deviationSq.length) {
        variance = sumDeviationSq / (deviationSq.length - 1);
        volaility = parseFloat(Math.sqrt(variance).toFixed(2));
    }

    return volaility;
};

const getDeviation = (arr, avgArr) => {
    const divArr = arr.map((el, i) => (el - avgArr) * (el - avgArr));
    const avgDivArr = divArr.length - 1 ? divArr.reduce((total, value) => total + value, 0) / (divArr.length - 1) : 0;
    const stdDivArr = parseFloat(Math.sqrt(avgDivArr).toFixed(2));
    return stdDivArr;
}

const calcSharpeRatio = (returns, avgReturns) => {
    const stDivRet = getDeviation(returns, avgReturns);
    const sharpeRatio = avgReturns && stDivRet
        ? Math.abs(parseFloat(((avgReturns / stDivRet) * Math.sqrt(365)).toFixed(2)))
        : 0;
    return sharpeRatio;
}

const calcRestrictedPnl = (restrictions, parkData, currencyType) => {
    const extraData = JSON.parse(parkData.extra_data);
    const pl_samawatt = JSON.parse(parkData.pl_samawatt);
    const pl_customer = JSON.parse(parkData.pl_customer);
    const ibDt = extraData.parameters[2]; 
    const da_volumes_samawatt = JSON.parse(parkData.da_volumes_samawatt);
    const id_volumes_samawatt = JSON.parse(parkData.id_volumes_samawatt);
    const ib_volumes_gran = JSON.parse(parkData.ib_volumes_samawatt);
    const da_volumes_gran = da_volumes_samawatt.map((arr, i) => 
        resampleArrToGran(arr, extraData.parameters[0][i], ibDt));
    const id_volumes_gran = id_volumes_samawatt.map((arr, i) => 
        resampleArrToGran(arr, extraData.parameters[1][i], ibDt));
    
    let restrictedDaVolumes = [...da_volumes_gran];
    let restrictedIdVolumes = [...id_volumes_gran];
    let restrictedIbVolumes = [...ib_volumes_gran];
    let restricted_volumes_stage_0 = restrictedDaVolumes.length ? [...da_volumes_gran[0]] : [...id_volumes_gran[0]];
    
    const calcRestrictedVolumes = (arr, type, dateFrom, dateTo, datetimeFrom, datetimeTo) => {
        return arr.map((el, idx) => {
            const currentUnixTime = moment(parkData.date).unix() + idx * ibDt * 3600;
            const isInTimeRange = currentUnixTime >= datetimeFrom && currentUnixTime < datetimeTo;

            const isSameDates = parkData.date === dateFrom.format(DATE_FORMAT_DASH) && 
                dateFrom.format(DATE_FORMAT_DASH) === dateTo.format(DATE_FORMAT_DASH);

            const isStartDate = parkData.date === dateFrom.format(DATE_FORMAT_DASH) && 
                dateFrom.format(DATE_FORMAT_DASH) !== dateTo.format(DATE_FORMAT_DASH);

            const isEndDate = parkData.date === dateTo.format(DATE_FORMAT_DASH) && 
                dateFrom.format(DATE_FORMAT_DASH) !== dateTo.format(DATE_FORMAT_DASH);
            
            const isBetween = (moment(parkData.date) < dateTo && moment(parkData.date) > dateFrom);

            if ((isSameDates || isStartDate || isEndDate || isBetween) && isInTimeRange) {
                return (type === 'buy' && el < 0) || 
                    (type === 'sell' && el > 0) ||
                    (type === 'both')
                        ? null 
                        : el
            }

            return el
        })
    }

    restrictions.forEach(restriction => {
        const dateFrom = moment(restriction.dateFrom);
        const dateTo = moment(restriction.dateTo);

        const datetimeFrom = moment(`${parkData.date} ${restriction.timeFrom.split(' ')[1]}`).unix();
        const datetimeTo = moment(`${parkData.date} ${restriction.timeTo.split(' ')[1]}`).unix();

        if ((parkData.date === dateFrom.format(DATE_FORMAT_DASH) && 
                restriction.days.includes(moment(parkData.date).format('dddd'))) || 
            (parkData.date === dateTo.format(DATE_FORMAT_DASH) && 
                restriction.days.includes(moment(parkData.date).format('dddd'))) || 
            (moment(parkData.date) < dateTo && 
                moment(parkData.date) > dateFrom && 
                restriction.days.includes(moment(parkData.date).format('dddd')))
        ) {
            restricted_volumes_stage_0 = calcRestrictedVolumes(
                restricted_volumes_stage_0,
                restriction.type,
                dateFrom,
                dateTo,
                datetimeFrom,
                datetimeTo
            );
        }
    })

    restrictedDaVolumes = restrictedDaVolumes.map((arr) => {
        return arr.map((volume, i) => restricted_volumes_stage_0[i] !== null ? volume : 0)
    });

    restrictedIbVolumes = restrictedIbVolumes.map((volume, i) => {
        return restricted_volumes_stage_0[i] !== null ? volume : 0
    });

    restrictedIdVolumes = restrictedIdVolumes.map((arr) => {
        return arr.map((volume, i) => restricted_volumes_stage_0[i] !== null ? volume : 0)
    });

    const restrictedPlSamawatt = pl_samawatt.map((total, i) => 
        restricted_volumes_stage_0[i] !== null ? total : 0
    );

    const restrictedPlCustomer = pl_customer.map((total, i) => 
        restricted_volumes_stage_0[i] !== null ? total : 0
    );

    const restrictedAddedValues = restrictedPlSamawatt.map((el, i) => 
        el - restrictedPlCustomer[i]
    );
    
    return {
      restrictedPnl:
        currencyType === 'Local'
          ? restrictedAddedValues
          : restrictedAddedValues.map((el) =>
              Number((el / (parkData.exchangeRate || 1)).toFixed(2))
            ),
      restrictedDaVolumes,
      restrictedIbVolumes,
      restrictedIdVolumes,
    };
}

export const calculateOptsAndRows = (
    data,
    parks,
    xAxis, 
    yAxis,
    davsibParksId,
    currencyType = 'Local',
    batteryCapacity = 0,
) => {
    const dataRows = [];
    const data_ = data.filter((park) => park);
    const opts = data_.map((park) => {
        // if park has DAvsIB stratagy
        const isDaVsIbPark = davsibParksId?.includes(park.id) ? true : false;

        // Process all days for particular park
        let totalPnl = 0;
        let totalEnergy = 0;
        let totalPosVolumes = 0;
        let grossProfit = 0;
        let grossLoss = 0;
        let posDays = 0;
        let negDays = 0;
        let zeroDays = 0;
        let conPosDaysAr = [0];
        let conNegDaysAr = [0];
        let pnlArr = [];
        let pnlNonZeroArr = [];

        let totalTradesNum = 0;
        let totalBuy = 0;
        let totalSell = 0;
        let zeroTrades = 0;
        let looseTrades = 0;
        let winTrades = 0;
        let maxTradedVolumeDay = [];

        // Build a cumulative pnl values
        const parkDataSortByDate = [...park.data];
        parkDataSortByDate.sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1);
        let cum_profit = 0;
        const pl_data_optimised_cumulative = [];
        const timeInterval = moment(
            parkDataSortByDate[parkDataSortByDate.length - 1].date
        ).diff(moment(parkDataSortByDate[0].date), 'days') + 1;
        for (let i = 0; i < parkDataSortByDate.length; i++) {
            cum_profit += (currencyType === 'Local' || !parkDataSortByDate[i].added_value_eur) ? parkDataSortByDate[i].added_value : parkDataSortByDate[i].added_value_eur;
            pl_data_optimised_cumulative.push([
                moment(parkDataSortByDate[i].date).unix() * 1000,
                cum_profit,
            ]);
        }

        // Add values for a particular day
        parkDataSortByDate.forEach((v, i) => {
            // Calculate DA, ID, IB energy amounts
            const extraData = JSON.parse(v.extra_data);
            const ibDt = extraData.parameters[2];

            const id_trades = oneDimArr(extraData.id_trades.map(sub => sub.map(el => el[2])));
            const da_volumes_samawatt = oneDimArr(JSON.parse(v.da_volumes_samawatt));
            const id_volumes_samawatt = oneDimArr(JSON.parse(v.id_volumes_samawatt));
            const da_volumes_samawatt_arr = JSON.parse(v.da_volumes_samawatt);
            const id_volumes_samawatt_arr = JSON.parse(v.id_volumes_samawatt);
            
            const { dt, id_dt, ib_dt } = v.parameters;
            const volumesGran = [...dt, id_dt];
            const volumesArr = [...da_volumes_samawatt_arr, ...id_volumes_samawatt_arr];
            const granVolumesArr = volumesArr.map((el, i) => calcGranData(el, volumesGran[i], ib_dt, 'vol'));
            
            totalPosVolumes += granVolumesArr[0]
                .map((_, i) => volumesArr.reduce((sum, arr) => sum + arr[i], 0))
                .filter(el => el > 0)
                .reduce((acc, value) => acc + value, 0);

            maxTradedVolumeDay.push(Math.max(
                da_volumes_samawatt.length ? Math.abs(Math.max(...da_volumes_samawatt.map(Math.abs))) : 0,
                id_volumes_samawatt.length ? Math.abs(Math.max(...id_volumes_samawatt.map(Math.abs))) : 0
            ));
            const energyDA = da_volumes_samawatt
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            const energyID = id_volumes_samawatt
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            const energyIB = JSON.parse(v.ib_volumes_samawatt)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            pnlArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);
            if(v.added_value !== 0) pnlNonZeroArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);

            // Accumulate data for chart & table
            totalPnl += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            // Dividing by 2 is needed, because every position was opened and closed
            const energy = (energyDA + energyID + energyIB) / 2;
            totalEnergy += Math.abs(energy);
            totalTradesNum +=
            da_volumes_samawatt.filter((v) => v !== 0).length +
            id_trades.length;
            totalBuy +=
            da_volumes_samawatt
                .filter((v) => v < 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0) +
            id_trades
                .filter((v) => v < 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            totalSell +=
            da_volumes_samawatt
                .filter((v) => v > 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0) +
            id_trades
                .filter((v) => v > 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            zeroTrades +=
                da_volumes_samawatt.filter((v) => v === 0).length +
                id_volumes_samawatt.filter((v) => v === 0).length;
            if (isDaVsIbPark) {
                winTrades += resampleArrToHoursDim(
                    JSON.parse(v.pl_samawatt),
                    ibDt
                ).filter((v) => v > 0).length;
                looseTrades += resampleArrToHoursDim(
                    JSON.parse(v.pl_samawatt),
                    ibDt
                ).filter((v) => v < 0).length;
            }

            if (v.added_value > 0) {
                posDays += 1;
                grossProfit += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            } else if (v.added_value < 0) {
                negDays += 1;
                grossLoss += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            } else if(v.added_value === 0) {
                zeroDays += 1;
            }

            if (i === 0) {
                if (v.added_value > 0) conPosDaysAr.push(1);
                else if (v.added_value < 0) conNegDaysAr.push(1);
            } else {
                if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value > 0) {
                    conPosDaysAr[conPosDaysAr.length - 1] += 1;
                } else if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value <= 0) {
                    conPosDaysAr.push(1);
                } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value < 0) {
                    conNegDaysAr[conNegDaysAr.length - 1] += 1;
                } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value >= 0) {
                    conNegDaysAr.push(1);
                }
            }
        });
        // Get the average Profit/MWh
        const avgPnlMWh =
            totalPnl && totalEnergy
                ? parseFloat((totalPnl / totalEnergy).toFixed(2))
                : 0;
        const totalPnlFormatted = parseInt(totalPnl.toFixed(0));

        const avgNbCycles = batteryCapacity ? (totalPosVolumes / batteryCapacity) / (timeInterval || 1) : 0;
        const avgNbCyclesFormated = parseFloat(avgNbCycles.toFixed(2));

        const pnLMWh = batteryCapacity ? totalPnl / batteryCapacity : 0;
        const pnLMWhFormated = parseFloat(pnLMWh.toFixed(2));
        
        //Gross profit, gross loss and their avg
        const grossProfitFormated = parseInt(grossProfit.toFixed(0));
        const grossLossFormated = parseInt(grossLoss.toFixed(0));

        const avgPosPnl =
            grossProfit && posDays ? parseFloat((grossProfit / posDays).toFixed(2)) : 0;
        const avgNegPnl =
            grossLoss && negDays ? parseFloat((grossLoss / negDays).toFixed(2)) : 0;

        const ratioAvgPosNeg =
            avgPosPnl && avgNegPnl ? Math.abs(parseFloat((avgPosPnl / avgNegPnl).toFixed(2))) : 0;
        
        //Profit factor
        const profitFactorFormated =
            totalPnl && grossLoss
                ? Math.abs(parseFloat((totalPnl / grossLoss).toFixed(2)))
                : 0;
        
        //Max consecutive positive days
        const maxConPosDays = conPosDaysAr.length ? Math.max(...conPosDaysAr) : 0;
        const maxConNegDays = conNegDaysAr.length ? Math.max(...conNegDaysAr) : 0;

        //Max and min pnl
        const maxPnl = pnlArr.length ? Math.max(...pnlArr) : 0;
        const minPnl = pnlArr.length ? Math.min(...pnlArr) : 0;

        //Calc maxdrowdown
        const maxDrawDown = parseInt((maxDrawdownFun(pnlNonZeroArr) * 100).toFixed(0));

        //Net profit
        const netProfit =
            totalPnl && maxDrawDown
                ? parseFloat((totalPnl / (maxDrawDown / 100)).toFixed(2))
                : 0;

        const returns = [];
        pnlNonZeroArr.forEach((el, i) => {
            if (i !== 0) {
                returns.push(pnlNonZeroArr[i - 1] !== 0 ? el / pnlNonZeroArr[i - 1] - 1 : 0);
            }
        });
        const avgReturns =
                returns.reduce((total, value) => total + value, 0) / returns.length;
        
        const volaility = calcVolatility(returns, avgReturns);

        //Persent profitable
        const percentProf =
            winTrades && totalTradesNum
                ? parseInt(((winTrades / totalTradesNum) * 100).toFixed(0))
                : 0;

        //Ratio of buys to sells
        const ratioBuySell =
            totalBuy && totalSell ? parseFloat((totalBuy / totalSell).toFixed(2)) : 0;

        // Process axis values depending on selected axis settings
        const getAxisValue = (axis) => {
            if (axis === 'x') {
                if(xAxis === MEASURE_PROFIT_MWH) {
                    return avgPnlMWh;
                }
                else if(xAxis === MEASURE_PROFIT) {
                    return totalPnlFormatted;
                }
                else if(xAxis === MEASURE_DAILY_AC) {
                    return accuracyDay;
                }
                else if(xAxis === MEASURE_MAX_DRAWDOWN) {
                    return maxDrawDown;
                }
                else if(xAxis === MEASURE_SHARPE_RATIO) {
                    return sharpeRatio;
                }
            } else {// axis === 'y'
                if(yAxis === MEASURE_PROFIT_MWH) {
                    return avgPnlMWh;
                }
                else if(yAxis === MEASURE_PROFIT) {
                    return totalPnlFormatted;
                }
                else if(yAxis === MEASURE_DAILY_AC) {
                    return accuracyDay;
                }
                else if(yAxis === MEASURE_MAX_DRAWDOWN) {
                    return maxDrawDown;
                }
                else if(yAxis === MEASURE_SHARPE_RATIO) {
                    return sharpeRatio;
                }
            }
        };

        //accuracy
        const accuracyDay =
            posDays && parkDataSortByDate.length
                ? parseInt(((posDays / (parkDataSortByDate.length - zeroDays)) * 100).toFixed(0))
                : 0;
        const sharpeRatio = calcSharpeRatio(returns, avgReturns);

        // sortino ratio
        const calcSortinoRatio = (returns, avgReturns) => {
            const negRetArr = returns.filter(el => el < 0);
            const avgNegRetArr = negRetArr.reduce((total, value) => total + value, 0) / negRetArr.length;
            const downDivReturns = getDeviation(negRetArr, avgNegRetArr);
            const sortinoRatio = avgReturns && downDivReturns
                ? Math.abs(parseFloat(((avgReturns / downDivReturns) * Math.sqrt(365)).toFixed(2)))
                : 0;
            return sortinoRatio;
        };
        const sortinoRatio = calcSortinoRatio(returns, avgReturns);

        //risk ratio
        const calcRiskRatio = (arr) => {
            const pnlAvg = arr.reduce((total, value) => total + value, 0) / arr.length;
            const pnlDev = getDeviation(arr, pnlAvg);
            const riskRatio = pnlAvg && pnlDev
                ? Math.abs(parseFloat(((pnlAvg / pnlDev) * Math.sqrt(365)).toFixed(2)))
                : 0;
            return riskRatio;
        };
        const riskRatio = calcRiskRatio(pnlArr);

        //max peak to drought
        const calcMaxPeakToTrought = (arr) => {
            let prevValue = 0;
            const cumulative = arr.map(value => {
                const prev = prevValue;
                prevValue = prev + value;
                return prev + value
            })
            const futureMin = cumulative.map((value, i) => {
                const arr = cumulative.slice(i);
                return Math.min(...arr);
            });
            const dd = futureMin.map((value, i) => value - cumulative[i]);
            const minDD = Math.min(...dd);
            const maxCumulative = Math.max(...cumulative);

            return maxCumulative === 0 ? 0 : Math.abs(((minDD / maxCumulative) * 100).toFixed(0));
        };
        const maxPeakToTrought = calcMaxPeakToTrought(pnlArr);
        const maxTradedVolume = maxTradedVolumeDay.length ? Math.max(...maxTradedVolumeDay) : 0;
        
        const totalOptimised = parseFloat(parkDataSortByDate.reduce((acc, v) => acc + v.total_samawatt, 0).toFixed(2));
        const totalBase = parseFloat(parkDataSortByDate.reduce((acc, v) => acc + v.total_customer, 0).toFixed(2));
        const addedValue = parseFloat(parkDataSortByDate.reduce((acc, v) => acc + (
            currencyType === 'Local' || !v.added_value_eur ? v.added_value : v.added_value_eur
        ), 0).toFixed(2));

        const parkData = parks.filter((p) => p.id === park.id);
        // Add value to the row table
        dataRows.push({
            id: park.id,
            name: park.name,
            market: parkData.length ? parkData[0].market : '',
            // API can return values like 0.1 or 0.025, so rounding is required
            totalEnergy: totalEnergy,
            totalOptimised: totalOptimised,
            totalBase: totalBase,
            addedValue: addedValue,
            avgPnlMWh: avgPnlMWh,
            totalPnlFormatted: totalPnlFormatted,
            avgNbCyclesFormated: avgNbCyclesFormated,
            pnLMWhFormated: pnLMWhFormated,
            grossProfit: grossProfitFormated,
            grossLoss: grossLossFormated,
            avgPosPnl: avgPosPnl,
            avgNegPnl: avgNegPnl,
            profitFactor: profitFactorFormated,
            ratioAvgPosNeg: ratioAvgPosNeg,
            maxConPosDays: maxConPosDays,
            maxConNegDays: maxConNegDays,
            maxPnl: maxPnl,
            minPnl: minPnl,
            maxTradedVolume: maxTradedVolume,
            maxDrawDown: maxDrawDown,
            netProfit: netProfit,
            volaility: volaility,
            isDaVsIbPark: isDaVsIbPark,
            totalTradesNum: totalTradesNum,
            totalBuy: totalBuy,
            totalSell: totalSell,
            zeroTrades: zeroTrades,
            winTrades: winTrades,
            looseTrades: looseTrades,
            percentProf: percentProf,
            ratioBuySell: ratioBuySell,
            posDays: posDays,
            negDays: negDays,
            accuracyDay: accuracyDay,
            sharpeRatio: sharpeRatio,
            sortinoRatio: sortinoRatio,
            riskRatio: riskRatio,
            maxPeakToTrought: maxPeakToTrought,
            axisY: getAxisValue('y'),
        });

        return [
            {
                x: getAxisValue('x'),
                y: getAxisValue('y'),
                name: park.name,
                cumulative: pl_data_optimised_cumulative,
            },
        ];
    }).sort((a, b) => b[0].y > a[0].y ? 1 : -1).flat();
    
    //Sorting by Y 
    if(yAxis === MEASURE_MAX_DRAWDOWN)
        dataRows.sort((a, b) => b.axisY < a.axisY ? 1 : -1);
    else
        dataRows.sort((a, b) => b.axisY > a.axisY ? 1 : -1);

    return {
        opts: opts,
        rows: dataRows
    }
}

export const calculateChartData = (
    opts,
    xAxis, 
    yAxis, 
    visibleLegends, 
    currency,
    visibleSeriesNames = new Set(), 
    setVisibleSeriesNames = () => {}, 
) => {
    //Collect ccomulative data for all parks
    const accomulativeData = opts.map((el) => ({
        name: el.name,
        cumulative: el.cumulative,
    }));
    // Sort options by Y scale & set the rank of items "i of N"
    const optionsLen = opts.length;
    let sortedOptions = opts
        .map((option, i) => ({
            ...option,
            rankY: i + 1,
        }));
    // Set the rank of items "i of N"
    sortedOptions = sortedOptions.map((option, i) => ({
        ...option,
        rankX: i + 1,
    }));
    // Sort by model name then to have legends sorted alphabetically
    sortedOptions = sortedOptions
        .map((option) => {
            const pointWeight =
                (optionsLen - (option.rankY + option.rankX) / 2) / optionsLen;
            // Prepare series for chart
            return makePlotOptions({
                ...option,
                // Add marker size
                marker: {
                    // "6" is marker min size, "24" is max (6 + 18)
                    size: Math.round(6 + 18 * pointWeight),
                },
                // Add tooltip
                text:
                    `<b>Strategy: ${option.name}</b><br><br>` +
                    `${yAxis}: ${window.numberWithCommas(option.y)} ` +
                    `${AXES(currency)[yAxis].unit}, (${option.rankY} of ${optionsLen})` +
                    `<br>${window.numberWithCommas(xAxis)}: ${option.x} ` +
                    `${AXES(currency)[xAxis].unit}, (${option.rankX} of ${optionsLen})`,
            });
        });
    
    return {
        // optionsCumulative: optionsCumulative,
        options: sortedOptions
    }
}

export const calculateStrategiesData = (data, parks, xAxis, yAxis, davsibParksId, visibleLegends = [], currency, currencyType = 'Local') => {
    const dataRows = [];
    const data_ = data.filter((park) => park);
    const opts = data_.map((park) => {
        // if park has DAvsIB stratagy
        const isDaVsIbPark = davsibParksId?.includes(park.id) ? true : false;

        // Process all days for particular park
        let totalPnl = 0;
        let totalEnergy = 0;
        let grossProfit = 0;
        let grossLoss = 0;
        let posDays = 0;
        let negDays = 0;
        let zeroDays = 0;
        let conPosDaysAr = [0];
        let conNegDaysAr = [0];
        let pnlArr = [];
        let pnlNonZeroArr = [];

        let totalTradesNum = 0;
        let totalBuy = 0;
        let totalSell = 0;
        let zeroTrades = 0;
        let looseTrades = 0;
        let winTrades = 0;
        let maxTradedVolumeDay = [];

        // Build a cumulative pnl values
        const parkDataSortByDate = [...park.data];
        parkDataSortByDate.sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1);
        let cum_profit = 0;
        const pl_data_optimised_cumulative = [];
        for (let i = 0; i < parkDataSortByDate.length; i++) {
            cum_profit += (currencyType === 'Local' || !parkDataSortByDate[i].added_value_eur) ? parkDataSortByDate[i].added_value : parkDataSortByDate[i].added_value_eur;
            pl_data_optimised_cumulative.push([
                moment(parkDataSortByDate[i].date).unix() * 1000,
                cum_profit,
            ]);
        }

        // Add values for a particular day
        parkDataSortByDate.forEach((v, i) => {
            // Calculate DA, ID, IB energy amounts
            const extraData = JSON.parse(v.extra_data);
            const ibDt = extraData.parameters[2];

            const id_trades = oneDimArr(extraData.id_trades.map(sub => sub.map(el => el[2])));
            const da_volumes_samawatt = oneDimArr(JSON.parse(v.da_volumes_samawatt));
            const id_volumes_samawatt = oneDimArr(JSON.parse(v.id_volumes_samawatt));
            maxTradedVolumeDay.push(Math.max(
                da_volumes_samawatt.length ? Math.abs(Math.max(...da_volumes_samawatt.map(Math.abs))) : 0,
                id_volumes_samawatt.length ? Math.abs(Math.max(...id_volumes_samawatt.map(Math.abs))) : 0
            ));
            const energyDA = da_volumes_samawatt
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            const energyID = id_volumes_samawatt
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            const energyIB = JSON.parse(v.ib_volumes_samawatt)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            pnlArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);
            if(v.added_value !== 0) pnlNonZeroArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);

            // Accumulate data for chart & table
            totalPnl += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            // Dividing by 2 is needed, because every position was opened and closed
            const energy = (energyDA + energyID + energyIB) / 2;
            totalEnergy += Math.abs(energy);
            totalTradesNum +=
              da_volumes_samawatt.filter((v) => v !== 0).length +
              id_trades.length;
            totalBuy +=
              da_volumes_samawatt
                .filter((v) => v < 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0) +
              id_trades
                .filter((v) => v < 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            totalSell +=
              da_volumes_samawatt
                .filter((v) => v > 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0) +
              id_trades
                .filter((v) => v > 0)
                .map(Math.abs)
                .reduce((total, value) => total + value, 0);
            zeroTrades +=
                da_volumes_samawatt.filter((v) => v === 0).length +
                id_volumes_samawatt.filter((v) => v === 0).length;
            if (isDaVsIbPark) {
                winTrades += resampleArrToHoursDim(
                    JSON.parse(v.pl_samawatt),
                    ibDt
                ).filter((v) => v > 0).length;
                looseTrades += resampleArrToHoursDim(
                    JSON.parse(v.pl_samawatt),
                    ibDt
                ).filter((v) => v < 0).length;
            }

            if (v.added_value > 0) {
                posDays += 1;
                grossProfit += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            } else if (v.added_value < 0) {
                negDays += 1;
                grossLoss += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
            } else if(v.added_value === 0) {
                zeroDays += 1;
            }

            if (i === 0) {
                if (v.added_value > 0) conPosDaysAr.push(1);
                else if (v.added_value < 0) conNegDaysAr.push(1);
            } else {
                if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value > 0) {
                    conPosDaysAr[conPosDaysAr.length - 1] += 1;
                } else if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value <= 0) {
                    conPosDaysAr.push(1);
                } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value < 0) {
                    conNegDaysAr[conNegDaysAr.length - 1] += 1;
                } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value >= 0) {
                    conNegDaysAr.push(1);
                }
            }
        });
        // Get the average Profit/MWh
        const avgPnlMWh =
            totalPnl && totalEnergy
                ? Number((totalPnl / totalEnergy).toFixed(2))
                : 0;
        const totalPnlFormatted = Number(totalPnl.toFixed(0));

        //Gross profit, gross loss and their avg
        const grossProfitFormated = Number(grossProfit.toFixed(0));
        const grossLossFormated = Number(grossLoss.toFixed(0));

        const avgPosPnl =
            grossProfit && posDays ? Number((grossProfit / posDays).toFixed(2)) : 0;
        const avgNegPnl =
            grossLoss && negDays ? Number((grossLoss / negDays).toFixed(2)) : 0;

        const ratioAvgPosNeg =
            avgPosPnl && avgNegPnl ? Math.abs(Number((avgPosPnl / avgNegPnl).toFixed(2))) : 0;
        //Profit factor
        const profitFactorFormated =
            totalPnl && grossLoss
                ? Math.abs(Number((totalPnl / grossLoss).toFixed(2)))
                : 0;
        //Max consecutive positive days
        const maxConPosDays = conPosDaysAr.length ? Math.max(...conPosDaysAr) : 0;
        const maxConNegDays = conNegDaysAr.length ? Math.max(...conNegDaysAr) : 0;

        //Max and min pnl
        const maxPnl = pnlArr.length ? Math.max(...pnlArr) : 0;
        const minPnl = pnlArr.length ? Math.min(...pnlArr) : 0;

        //Calc maxdrowdown
        const maxDrawDown = Number((maxDrawdownFun(pnlNonZeroArr) * 100).toFixed(0));

        //Net profit
        const netProfit =
            totalPnl && maxDrawDown
                ? Number((totalPnl / (maxDrawDown / 100)).toFixed(2))
                : 0;

        //Volatility
        const returns = [];
        pnlNonZeroArr.forEach((el, i) => {
            if (i !== 0) {
                returns.push(pnlNonZeroArr[i - 1] !== 0 ? el / pnlNonZeroArr[i - 1] - 1 : 0);
            }
        });
        const avgReturns =
            returns.reduce((total, value) => total + value, 0) / returns.length;
        const deviationSq = returns.map(
            (el) => (el - avgReturns) * (el - avgReturns)
        );
        let sumDeviationSq = 0;
        let variance = 0;
        let volaility = 0;
        deviationSq.forEach((el) => (sumDeviationSq += el));
        if (sumDeviationSq && deviationSq.length) {
            variance = sumDeviationSq / (deviationSq.length - 1);
            volaility = Number(Math.sqrt(variance).toFixed(2));
        }

        //Persent profitable
        const percentProf =
            winTrades && totalTradesNum
                ? Number(((winTrades / totalTradesNum) * 100).toFixed(0))
                : 0;

        //Ratio of buys to sells
        const ratioBuySell =
            totalBuy && totalSell ? Number((totalBuy / totalSell).toFixed(2)) : 0;

        // Process axis values depending on selected axis settings
        const getAxisValue = (axis) => {
            if (axis === 'x') {
                if(xAxis === MEASURE_PROFIT_MWH) {
                    return avgPnlMWh;
                }
                else if(xAxis === MEASURE_PROFIT) {
                    return totalPnlFormatted;
                }
                else if(xAxis === MEASURE_DAILY_AC) {
                    return accuracyDay;
                }
                else if(xAxis === MEASURE_MAX_DRAWDOWN) {
                    return maxDrawDown;
                }
                else if(xAxis === MEASURE_SHARPE_RATIO) {
                    return sharpeRatio;
                }
            } else {// axis === 'y'
                if(yAxis === MEASURE_PROFIT_MWH) {
                    return avgPnlMWh;
                }
                else if(yAxis === MEASURE_PROFIT) {
                    return totalPnlFormatted;
                }
                else if(yAxis === MEASURE_DAILY_AC) {
                    return accuracyDay;
                }
                else if(yAxis === MEASURE_MAX_DRAWDOWN) {
                    return maxDrawDown;
                }
                else if(yAxis === MEASURE_SHARPE_RATIO) {
                    return sharpeRatio;
                }
            }
        };

        //accuracy
        const accuracyDay =
            posDays && parkDataSortByDate.length
                ? Number(((posDays / (parkDataSortByDate.length - zeroDays)) * 100).toFixed(0))
                : 0;

        // sharpe ratio
        const stDivRet = getDeviation(returns, avgReturns);
        const sharpeRatio =
          avgReturns && stDivRet
            ? Math.abs(Number(((avgReturns / stDivRet) * Math.sqrt(365)).toFixed(2)))
            : 0;

        // sortino ratio
        const negRetArr = returns.filter(el => el < 0);
        const avgNegRetArr = negRetArr.reduce((total, value) => total + value, 0) / negRetArr.length;
        const downDivReturns = getDeviation(negRetArr,avgNegRetArr);
        const sortinoRatio = 
          avgReturns && downDivReturns
          ? Math.abs(Number(((avgReturns / downDivReturns) * Math.sqrt(365)).toFixed(2)))
          : 0;
        
        //risk ratio
        const pnlAvg = pnlArr.reduce((total, value) => total + value, 0) / pnlArr.length;
        const pnlDev = getDeviation(pnlArr, pnlAvg);
        const riskRatio = pnlAvg && pnlDev
        ? Math.abs(Number(((pnlAvg / pnlDev) * Math.sqrt(365)).toFixed(2)))
        : 0;

        //max peak to drought
        let prevValue = 0;
        const cumulative = pnlArr.map(value => {
            const prev = prevValue;
            prevValue = prev + value;
            return prev + value
        })
        const futureMin = cumulative.map((value, i) => {
            const arr = cumulative.slice(i);
            return Math.min(...arr);
          });
        const dd = futureMin.map((value, i) => value - cumulative[i]);
        const minDD = Math.min(...dd);
        const maxCumulative = cumulative.length ? Math.max(...cumulative) : Math.max(cumulative);
        const maxPeakToTrought = maxCumulative === 0 ? 0 : Math.abs(((minDD/maxCumulative)*100).toFixed(0));
        const maxTradedVolume = maxTradedVolumeDay.length ? Math.max(...maxTradedVolumeDay) : 0;

        const parkData = parks.filter((p) => p.id === park.id);
        // Add value to the row table
        dataRows.push({
            id: park.id,
            name: park.name,
            market: parkData.length ? parkData[0].market : '',
            // API can return values like 0.1 or 0.025, so rounding is required
            totalEnergy: totalEnergy,
            avgPnlMWh: avgPnlMWh,
            totalPnlFormatted: totalPnlFormatted,
            grossProfit: grossProfitFormated,
            grossLoss: grossLossFormated,
            avgPosPnl: avgPosPnl,
            avgNegPnl: avgNegPnl,
            profitFactor: profitFactorFormated,
            ratioAvgPosNeg: ratioAvgPosNeg,
            maxConPosDays: maxConPosDays,
            maxConNegDays: maxConNegDays,
            maxPnl: maxPnl,
            minPnl: minPnl,
            maxTradedVolume: maxTradedVolume,
            maxDrawDown: maxDrawDown,
            netProfit: netProfit,
            volaility: volaility,
            isDaVsIbPark: isDaVsIbPark,
            totalTradesNum: totalTradesNum,
            totalBuy: totalBuy,
            totalSell: totalSell,
            zeroTrades: zeroTrades,
            winTrades: winTrades,
            looseTrades: looseTrades,
            percentProf: percentProf,
            ratioBuySell: ratioBuySell,
            posDays: posDays,
            negDays: negDays,
            accuracyDay: accuracyDay,
            sharpeRatio: sharpeRatio,
            sortinoRatio: sortinoRatio,
            riskRatio: riskRatio,
            maxPeakToTrought: maxPeakToTrought,
            axisY: getAxisValue('y'),
        });

        return {
            x: getAxisValue('x'),
            y: getAxisValue('y'),
            name: park.name,
            cumulative: pl_data_optimised_cumulative,
        };
    });
    //Sorting by Y 
    if(yAxis === MEASURE_MAX_DRAWDOWN)
        dataRows.sort((a, b) => b.axisY < a.axisY ? 1 : -1);
    else
        dataRows.sort((a, b) => b.axisY > a.axisY ? 1 : -1);
    opts.sort((a, b) => b.y > a.y ? 1 : -1);

    //Collect ccomulative data for all parks
    const accomulativeData = opts.map((el) => ({
        name: el.name,
        cumulative: el.cumulative,
    }));
    // Prepare chart data
    const serieses = accomulativeData.map((el, ind) =>
        makeSeries(
            el.name,
            el.cumulative.map((val) => [val[0], val[1]]),
            { valueDecimals: 2, colorIndex: ind, visible: false }
        )
    );
    const serLen = serieses.length;
    const tempSerieses = [];
    if (serLen) {
        //Show only first 10 curves
        for (let i = 0; i < (serLen < CURVES_SHOW ? serLen : CURVES_SHOW); i++) {
            serieses[i].visible = true;
            tempSerieses.push(serieses[i]);
        }
    }

    if(visibleLegends === true) {
        serieses.forEach((el) => {
            el.visible = true;
        });
    } else if(visibleLegends === false) {
        serieses.forEach((el) => {
            el.visible = false;
        });
    }

    const optionsCumulative = {
        ...stocksChartOptions({ filename: `Daily P&L Accumulated ` }),
        yAxis: [
            {
                title: {
                    text: `Profit(Loss), ${currency || ''}`,
                },
            },
            {
                title: {
                    text: `Added Value, ${currency|| ''}`,
                },
                opposite: true,
            },
        ],
        ...xAxisInCET,
        series: serieses,
    };

    //Change the height of the chart related on how much parks in it
    const defChartHeight = optionsCumulative.chart.height;
    optionsCumulative.chart.height =
        defChartHeight + serLen * 0.02 * defChartHeight;

    // Sort options by Y scale & set the rank of items "i of N"
    const optionsLen = opts.length;
    let sortedOptions = opts
        .map((option, i) => ({
            ...option,
            rankY: i + 1,
        }));
    // Set the rank of items "i of N"
    sortedOptions = sortedOptions.map((option, i) => ({
        ...option,
        rankX: i + 1,
    }));
    // Sort by model name then to have legends sorted alphabetically
    sortedOptions = sortedOptions
        .map((option) => {
            const pointWeight =
                (optionsLen - (option.rankY + option.rankX) / 2) / optionsLen;
            // Prepare series for chart
            return makePlotOptions({
                ...option,
                // Add marker size
                marker: {
                    // "6" is marker min size, "24" is max (6 + 18)
                    size: Math.round(6 + 18 * pointWeight),
                },
                // Add tooltip
                text:
                    `<b>Strategy: ${option.name}</b><br><br>` +
                    `${yAxis}: ${window.numberWithCommas(option.y)} ` +
                    `${AXES(currency)[yAxis].unit}, (${option.rankY} of ${optionsLen})` +
                    `<br>${window.numberWithCommas(xAxis)}: ${option.x} ` +
                    `${AXES(currency)[xAxis].unit}, (${option.rankX} of ${optionsLen})`,
            });
        });

    return {
        options: sortedOptions,
        rows: dataRows,
        optionsCumulative: optionsCumulative,
    };
};

export const layoutOptions = (data, strategies, xAxis, yAxis, currency) => ({
    title: `${strategies.join(', ')} Models Comparison`,
    xaxis: {
        zeroline: false,
        title: `${xAxis}, ${AXES(currency)[xAxis].unit}`,
    },
    yaxis: {
        zeroline: false,
        title: `${yAxis}, ${AXES(currency)[yAxis].unit}`,
    },
    height: 900 + data.length * 0.015 * 900,
    autosize: true,
    showlegend: true,
    legend: { orientation: 'h', y: -0.2 },
    legendwidth: 50,
});

export const loadData = (markets, filteredParks, dateFrom, dateTo, fetchData) =>
(filteredParks.length &&
    markets.length &&
    fetchData({
        parks: LOCAL_ENV ? filteredParks.slice(0, modelsCount) : filteredParks,
        dateFrom: dateFrom.format(DATE_FORMAT),
        dateTo: dateTo.format(DATE_FORMAT),
    }))

export const getDatesObj = (dateFrom, dateTo) => {
    let dateObject = {};
    let currentDate = moment(dateFrom);
    while (currentDate <= moment(dateTo)) {
        const dateKey = currentDate.format("YYYY-MM-DD");
        dateObject[dateKey] = undefined;
        currentDate = currentDate.add(1, 'day');
    }

    return dateObject;
  };

export const fillUndefinedValues = (obj) => {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];
      const prevKey = keys[i - 1];
      const nextKey = keys[i + 1];

      if (obj[currentKey] === undefined) {
        if (obj[prevKey] !== undefined) {
          obj[currentKey] = obj[prevKey];
        } else if (obj[nextKey] !== undefined) {
          obj[currentKey] = obj[nextKey];
        } else if (obj[keys[i + 2]] !== undefined) {
          obj[currentKey] = obj[keys[i + 2]];
        } else if  (obj[keys[i + 3]] !== undefined) {
          obj[currentKey] = obj[keys[i + 3]];
        } else {
            obj[currentKey] = obj[keys[i + 4]];
        }
      }
    }
  };



  const calcAll = async (data, parks, xAxis, yAxis, davsibParksId, currencyType, restrictions, calcRestrictedKpi, forgeVariable) => {
    const dataRows = [];
    const data_ = data.filter((park) => park);
        const opts = data_.map((park) => {
            // if park has DAvsIB stratagy
            const isDaVsIbPark = davsibParksId?.includes(park.id) ? true : false;
            let capacity = parks.find((p) => p.id === park.id)?.capacity;
            // Process all days for particular park
            let totalPnl = 0;
            let totalEnergy = 0;
            let grossProfit = 0;
            let grossLoss = 0;
            let posDays = 0;
            let negDays = 0;
            let zeroDays = 0;
            let conPosDaysAr = [0];
            let conNegDaysAr = [0];
            let pnlArr = [];
            let pnlNonZeroArr = [];

            let totalTradesNum = 0;
            let totalBuy = 0;
            let totalSell = 0;
            let zeroTrades = 0;
            let looseTrades = 0;
            let winTrades = 0;

            let restrictedPnlObj = {};
            let restrictedDaVolumesObj = {};
            let restrictedIdVolumesObj = {};
            let restrictedIbVolumesObj = {};

            let restrictedTotalPnl = 0;
            let restrictedTotalEnergy = 0;
            let restrictedGrossProfit = 0;
            let restrictedGrossLoss = 0;
            let restrictedPosDays = 0;
            let restrictedNegDays = 0;
            let restrictedZeroDays = 0;
            let restrictedConPosDaysArr = [0];
            let restrictedConNegDaysArr = [0];
            let restrictedPnlArr = [];
            let restrictedPnlNonZeroArr =[];
            let restrictedTotalTradesNum = 0;
            let restrictedTotalBuy = 0;
            let restrictedTotalSell = 0;
            let restrictedZeroTrades = 0;
            let restrictedLooseTrades = 0;
            let restrictedWinTrades = 0;

            // Build a cumulative pnl values
            const parkDataSortByDate = [...park.data];
            parkDataSortByDate.sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1);
            let cum_profit = 0;
            let cum_pnl = 0;
            const pl_data_optimised_cumulative = [];
            const pl_data_with_restrictions = [];

            for (let i = 0; i < parkDataSortByDate.length; i++) {
                if (restrictions.length) {
                    const { 
                        restrictedPnl, 
                        restrictedDaVolumes, 
                        restrictedIdVolumes,
                        restrictedIbVolumes 
                    } = calcRestrictedPnl(restrictions, parkDataSortByDate[i], currencyType);
                    restrictedPnlObj = {
                        ...restrictedPnlObj,
                        [parkDataSortByDate[i].date]: restrictedPnl
                    };
                    restrictedDaVolumesObj = {
                        ...restrictedDaVolumesObj,
                        [parkDataSortByDate[i].date]: restrictedDaVolumes.flat()
                    };
                    restrictedIdVolumesObj = {
                        ...restrictedIdVolumesObj,
                        [parkDataSortByDate[i].date]: restrictedIdVolumes.flat()
                    };
                    restrictedIbVolumesObj = {
                        ...restrictedIbVolumesObj,
                        [parkDataSortByDate[i].date]: restrictedIbVolumes.flat()
                    };
                    cum_pnl += parseFloat(restrictedPnl.reduce((a, v) => a + v, 0).toFixed(2)); 
                    pl_data_with_restrictions.push([
                        moment(parkDataSortByDate[i].date).unix() * 1000,
                        cum_pnl
                    ]);
                }

                cum_profit += (currencyType === 'Local' || !parkDataSortByDate[i].added_value_eur) ? parkDataSortByDate[i].added_value : parkDataSortByDate[i].added_value_eur;
                pl_data_optimised_cumulative.push([
                    moment(parkDataSortByDate[i].date).unix() * 1000,
                    cum_profit,
                ]);
            }

            // Add values for a particular day
            parkDataSortByDate.forEach((v, i) => {
                // Calculate DA, ID, IB energy amounts
                const extraData = JSON.parse(v.extra_data);
                const ibDt = extraData.parameters[2];

                const id_trades = oneDimArr(extraData.id_trades.map(sub => sub.map(el => el[2])));
                const da_volumes_samawatt = oneDimArr(JSON.parse(v.da_volumes_samawatt));
                const id_volumes_samawatt = oneDimArr(JSON.parse(v.id_volumes_samawatt));
                const energyDA = da_volumes_samawatt
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0);
                const energyID = id_volumes_samawatt
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0);
                const energyIB = JSON.parse(v.ib_volumes_samawatt)
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0);
                pnlArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);
                if(v.added_value !== 0) pnlNonZeroArr.push((currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur);

                if (restrictions.length) {
                    const restricted_added_value = parseFloat(restrictedPnlObj[v.date].reduce((a, v) => a + v, 0).toFixed(2));

                    restrictedPnlArr.push(restricted_added_value);
                    if (restricted_added_value !== 0) restrictedPnlNonZeroArr.push(restricted_added_value);

                    restrictedTotalPnl += restricted_added_value;

                    const energyDA = restrictedDaVolumesObj[v.date].map(Math.abs).reduce((total, value) => total + value, 0);
                    const energyID = restrictedIdVolumesObj[v.date].map(Math.abs).reduce((total, value) => total + value, 0);
                    const energyIB = restrictedIbVolumesObj[v.date].map(Math.abs).reduce((total, value) => total + value, 0);
                    const energy = (energyDA + energyID + energyIB) / 2;

                    restrictedTotalEnergy += Math.abs(energy);
                    restrictedTotalTradesNum += restrictedDaVolumesObj[v.date].filter((el) => el !== 0).length +
                        restrictedIdVolumesObj[v.date].filter((el) => el !== 0).length;
                    restrictedTotalBuy += 
                        restrictedDaVolumesObj[v.date]
                            .filter(el => el < 0)
                            .map(Math.abs)
                            .reduce((total, value) => total + value, 0) +
                        restrictedIdVolumesObj[v.date]
                            .filter(el => el < 0)
                            .map(Math.abs)
                            .reduce((total, value) => total + value, 0);
                    restrictedTotalSell += 
                        restrictedDaVolumesObj[v.date]
                            .filter(el => el > 0)
                            .map(Math.abs)
                            .reduce((total, value) => total + value, 0) +
                        restrictedIdVolumesObj[v.date]
                            .filter(el => el > 0)
                            .map(Math.abs)
                            .reduce((total, value) => total + value, 0);
                    restrictedZeroTrades +=
                        restrictedDaVolumesObj[v.date].filter(el => el === 0).length +
                        restrictedIdVolumesObj[v.date].filter(el => el === 0).length;

                    if (isDaVsIbPark) {
                        restrictedWinTrades += resampleArrToHoursDim(
                            restrictedPnlObj[v.date],
                            ibDt
                        ).filter((v) => v > 0).length;
                        restrictedLooseTrades += resampleArrToHoursDim(
                            restrictedPnlObj[v.date],
                            ibDt
                        ).filter((v) => v < 0).length;
                    }

                    if (restricted_added_value > 0) {
                        restrictedPosDays += 1;
                        restrictedGrossProfit += restricted_added_value;
                    } else if (restricted_added_value < 0) {
                        restrictedNegDays += 1;
                        restrictedGrossLoss += restricted_added_value;
                    } else if(restricted_added_value === 0) {
                        restrictedZeroDays += 1;
                    }

                    if (i === 0) {
                        if (restricted_added_value > 0) restrictedConPosDaysArr.push(1);
                        else if (restricted_added_value < 0) restrictedConNegDaysArr.push(1);
                    } else {
                        const prev_restricted_added_value = parseFloat(
                            restrictedPnlObj[parkDataSortByDate[i - 1].date].reduce((a, v) => a + v, 0).toFixed(2)
                        );

                        if (restricted_added_value > 0 && prev_restricted_added_value > 0) {
                            restrictedConPosDaysArr[restrictedConPosDaysArr.length - 1] += 1;
                        } else if (restricted_added_value > 0 && prev_restricted_added_value <= 0) {
                            restrictedConPosDaysArr.push(1);
                        } else if (restricted_added_value < 0 && prev_restricted_added_value < 0) {
                            restrictedConNegDaysArr[restrictedConNegDaysArr.length - 1] += 1;
                        } else if (restricted_added_value < 0 && prev_restricted_added_value >= 0) {
                            restrictedConNegDaysArr.push(1);
                        }
                    }
                }
                // Accumulate data for chart & table
                totalPnl += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
                // Dividing by 2 is needed, because every position was opened and closed
                const energy = (energyDA + energyID + energyIB) / 2;
                totalEnergy += Math.abs(energy);
                totalTradesNum +=
                da_volumes_samawatt.filter((v) => v !== 0).length +
                id_trades.length;
                totalBuy +=
                da_volumes_samawatt
                    .filter((v) => v < 0)
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0) +
                id_trades
                    .filter((v) => v < 0)
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0);
                totalSell +=
                da_volumes_samawatt
                    .filter((v) => v > 0)
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0) +
                id_trades
                    .filter((v) => v > 0)
                    .map(Math.abs)
                    .reduce((total, value) => total + value, 0);
                zeroTrades +=
                    da_volumes_samawatt.filter((v) => v === 0).length +
                    id_volumes_samawatt.filter((v) => v === 0).length;
                if (isDaVsIbPark) {
                    winTrades += resampleArrToHoursDim(
                        JSON.parse(v.pl_samawatt),
                        ibDt
                    ).filter((v) => v > 0).length;
                    looseTrades += resampleArrToHoursDim(
                        JSON.parse(v.pl_samawatt),
                        ibDt
                    ).filter((v) => v < 0).length;
                }

                if (v.added_value > 0) {
                    posDays += 1;
                    grossProfit += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
                } else if (v.added_value < 0) {
                    negDays += 1;
                    grossLoss += (currencyType === 'Local' || !v.added_value_eur) ? v.added_value : v.added_value_eur;
                } else if(v.added_value === 0) {
                    zeroDays += 1;
                }

                if (i === 0) {
                    if (v.added_value > 0) conPosDaysAr.push(1);
                    else if (v.added_value < 0) conNegDaysAr.push(1);
                } else {
                    if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value > 0) {
                        conPosDaysAr[conPosDaysAr.length - 1] += 1;
                    } else if (v.added_value > 0 && parkDataSortByDate[i - 1].added_value <= 0) {
                        conPosDaysAr.push(1);
                    } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value < 0) {
                        conNegDaysAr[conNegDaysAr.length - 1] += 1;
                    } else if (v.added_value < 0 && parkDataSortByDate[i - 1].added_value >= 0) {
                        conNegDaysAr.push(1);
                    }
                }
            });
            // Get the average Profit/MWh
            const avgPnlMWh =
                totalPnl && totalEnergy
                    ? parseFloat((totalPnl / totalEnergy).toFixed(2))
                    : 0;
            const totalPnlFormatted = parseInt(totalPnl.toFixed(0));

            const avgNbCycles = capacity ? totalEnergy / capacity : 0;
            const avgNbCyclesFormated = parseInt(avgNbCycles.toFixed(0));

            const pnLMWh = capacity ? totalPnl / capacity : 0;
            const pnLMWhFormated = parseInt(pnLMWh.toFixed(0));

            const restrictedAvgPnlMWh = restrictedTotalPnl && restrictedTotalEnergy 
                ? parseFloat((restrictedTotalPnl / restrictedTotalEnergy).toFixed(2)) 
                : 0;
            const restrictedTotalPnlFormated = parseInt(restrictedTotalPnl.toFixed(0));

            const restrictedAvgNbCycles = capacity ? restrictedTotalEnergy / capacity : 0;
            const restrictedAvgNbCyclesFormated = parseInt(restrictedAvgNbCycles.toFixed(0));

            const restrictedPnLMWh = capacity ? restrictedTotalPnl / capacity : 0;
            const restrictedPnLMWhFormated = parseInt(restrictedPnLMWh.toFixed(0));
            
            //Gross profit, gross loss and their avg
            const grossProfitFormated = parseInt(grossProfit.toFixed(0));
            const grossLossFormated = parseInt(grossLoss.toFixed(0));

            const avgPosPnl =
                grossProfit && posDays ? parseFloat((grossProfit / posDays).toFixed(2)) : 0;
            const avgNegPnl =
                grossLoss && negDays ? parseFloat((grossLoss / negDays).toFixed(2)) : 0;

            const ratioAvgPosNeg =
                avgPosPnl && avgNegPnl ? Math.abs(parseFloat((avgPosPnl / avgNegPnl).toFixed(2))) : 0;
            
            //Profit factor
            const profitFactorFormated =
                totalPnl && grossLoss
                    ? Math.abs(parseFloat((totalPnl / grossLoss).toFixed(2)))
                    : 0;
            
            //Max consecutive positive days
            const maxConPosDays = conPosDaysAr.length ? Math.max(...conPosDaysAr) : 0;
            const maxConNegDays = conNegDaysAr.length ? Math.max(...conNegDaysAr) : 0;

            //Max and min pnl
            const maxPnl = pnlArr.length ? Math.max(...pnlArr) : 0;
            const minPnl = pnlArr.length ? Math.min(...pnlArr) : 0;

            //Calc maxdrowdown
            const maxDrawDown = parseInt((maxDrawdownFun(pnlNonZeroArr) * 100).toFixed(0));
            const retsrictedMaxDrawDown = parseInt((maxDrawdownFun(restrictedPnlNonZeroArr) * 100).toFixed(0));

            //Net profit
            const netProfit =
                totalPnl && maxDrawDown
                    ? parseFloat((totalPnl / (maxDrawDown / 100)).toFixed(2))
                    : 0;

            const returns = [];
            pnlNonZeroArr.forEach((el, i) => {
                if (i !== 0) {
                    returns.push(pnlNonZeroArr[i - 1] !== 0 ? el / pnlNonZeroArr[i - 1] - 1 : 0);
                }
            });
            const avgReturns =
                    returns.reduce((total, value) => total + value, 0) / returns.length;

            const restrictedReturns = [];
            restrictedPnlNonZeroArr.forEach((el, i) => {
                if (i !== 0) {
                    restrictedReturns.push(restrictedPnlNonZeroArr[i - 1] !== 0 ? el / restrictedPnlNonZeroArr[i - 1] - 1 : 0);
                }
            });
            const restrictedAvgReturns =
                restrictedReturns.reduce((total, value) => total + value, 0) / restrictedReturns.length;
            
            const volaility = calcVolatility(returns, avgReturns);

            //Persent profitable
            const percentProf =
                winTrades && totalTradesNum
                    ? parseInt(((winTrades / totalTradesNum) * 100).toFixed(0))
                    : 0;

            //Ratio of buys to sells
            const ratioBuySell =
                totalBuy && totalSell ? parseFloat((totalBuy / totalSell).toFixed(2)) : 0;

            // Process axis values depending on selected axis settings
            const getAxisValue = (axis, withRestrictions = false) => {
                if (axis === 'x') {
                    if(xAxis === MEASURE_PROFIT_MWH) {
                        return withRestrictions ? restrictedAvgPnlMWh : avgPnlMWh;
                    }
                    else if(xAxis === MEASURE_PROFIT) {
                        return withRestrictions ? restrictedTotalPnlFormated : totalPnlFormatted;
                    }
                    else if(xAxis === MEASURE_DAILY_AC) {
                        return withRestrictions ? restrictedAccuracyDay : accuracyDay;
                    }
                    else if(xAxis === MEASURE_MAX_DRAWDOWN) {
                        return withRestrictions ? retsrictedMaxDrawDown : maxDrawDown;
                    }
                    else if(xAxis === MEASURE_SHARPE_RATIO) {
                        return withRestrictions ? restrictedSharpeRatio : sharpeRatio;
                    }
                } else {// axis === 'y'
                    if(yAxis === MEASURE_PROFIT_MWH) {
                        return withRestrictions ? restrictedAvgPnlMWh : avgPnlMWh;
                    }
                    else if(yAxis === MEASURE_PROFIT) {
                        return withRestrictions ? restrictedTotalPnlFormated : totalPnlFormatted;
                    }
                    else if(yAxis === MEASURE_DAILY_AC) {
                        return withRestrictions ? restrictedAccuracyDay : accuracyDay;
                    }
                    else if(yAxis === MEASURE_MAX_DRAWDOWN) {
                        return withRestrictions ? retsrictedMaxDrawDown : maxDrawDown;
                    }
                    else if(yAxis === MEASURE_SHARPE_RATIO) {
                        return withRestrictions ? restrictedSharpeRatio : sharpeRatio;
                    }
                }
            };

            //accuracy
            const accuracyDay =
                posDays && parkDataSortByDate.length
                    ? parseInt(((posDays / (parkDataSortByDate.length - zeroDays)) * 100).toFixed(0))
                    : 0;
            const restrictedAccuracyDay = restrictedPosDays && parkDataSortByDate.length
                ? parseInt(((restrictedPosDays / (parkDataSortByDate.length - restrictedZeroDays)) * 100).toFixed(0))
                : 0;

            const sharpeRatio = calcSharpeRatio(returns, avgReturns);
            const restrictedSharpeRatio = calcSharpeRatio(restrictedReturns, restrictedAvgReturns);

            // sortino ratio
            const calcSortinoRatio = (returns, avgReturns) => {
                const negRetArr = returns.filter(el => el < 0);
                const avgNegRetArr = negRetArr.reduce((total, value) => total + value, 0) / negRetArr.length;
                const downDivReturns = getDeviation(negRetArr, avgNegRetArr);
                const sortinoRatio = avgReturns && downDivReturns
                    ? Math.abs(parseFloat(((avgReturns / downDivReturns) * Math.sqrt(365)).toFixed(2)))
                    : 0;
                return sortinoRatio;
            };
            const sortinoRatio = calcSortinoRatio(returns, avgReturns);

            //risk ratio
            const calcRiskRatio = (arr) => {
                const pnlAvg = arr.reduce((total, value) => total + value, 0) / arr.length;
                const pnlDev = getDeviation(arr, pnlAvg);
                const riskRatio = pnlAvg && pnlDev
                    ? Math.abs(parseFloat(((pnlAvg / pnlDev) * Math.sqrt(365)).toFixed(2)))
                    : 0;
                return riskRatio;
            };
            const riskRatio = calcRiskRatio(pnlArr);

            //max peak to drought
            const calcMaxPeakToTrought = (arr) => {
                let prevValue = 0;
                const cumulative = arr.map(value => {
                    const prev = prevValue;
                    prevValue = prev + value;
                    return prev + value
                })
                const futureMin = cumulative.map((value, i) => {
                    const arr = cumulative.slice(i);
                    return Math.min(...arr);
                });
                const dd = futureMin.map((value, i) => value - cumulative[i]);
                const minDD = Math.min(...dd);
                const maxCumulative = cumulative.length ? Math.max(...cumulative) : Math.max(cumulative);

                return maxCumulative === 0 ? 0 : Math.abs(((minDD / maxCumulative) * 100).toFixed(0));
            };
            const maxPeakToTrought = calcMaxPeakToTrought(pnlArr);

            const parkData = parks.filter((p) => p.id === park.id);
            // Add value to the row table
            dataRows.push({
                id: park.id,
                name: park.name,
                market: parkData.length ? parkData[0].market : '',
                // API can return values like 0.1 or 0.025, so rounding is required
                totalEnergy: totalEnergy,
                avgPnlMWh: avgPnlMWh,
                totalPnlFormatted: totalPnlFormatted,
                avgNbCyclesFormated: avgNbCyclesFormated,
                pnLMWhFormated: pnLMWhFormated,
                grossProfit: grossProfitFormated,
                grossLoss: grossLossFormated,
                avgPosPnl: avgPosPnl,
                avgNegPnl: avgNegPnl,
                profitFactor: profitFactorFormated,
                ratioAvgPosNeg: ratioAvgPosNeg,
                maxConPosDays: maxConPosDays,
                maxConNegDays: maxConNegDays,
                maxPnl: maxPnl,
                minPnl: minPnl,
                maxDrawDown: maxDrawDown,
                netProfit: netProfit,
                volaility: volaility,
                isDaVsIbPark: isDaVsIbPark,
                totalTradesNum: totalTradesNum,
                totalBuy: totalBuy,
                totalSell: totalSell,
                zeroTrades: zeroTrades,
                winTrades: winTrades,
                looseTrades: looseTrades,
                percentProf: percentProf,
                ratioBuySell: ratioBuySell,
                posDays: posDays,
                negDays: negDays,
                accuracyDay: accuracyDay,
                sharpeRatio: sharpeRatio,
                sortinoRatio: sortinoRatio,
                riskRatio: riskRatio,
                maxPeakToTrought: maxPeakToTrought,
                axisY: getAxisValue('y'),
            });

            if (calcRestrictedKpi && restrictions.length) {
                const restrictedGrossProfitFormated = parseInt(restrictedGrossProfit.toFixed(0));
                const restrictedGrossLossFormated = parseInt(restrictedGrossLoss.toFixed(0));

                const restrictedAvgPosPnl = restrictedGrossProfit && restrictedPosDays 
                    ? parseFloat((restrictedGrossProfit / restrictedPosDays).toFixed(2)) 
                    : 0;
                const restrictedAvgNegPnl = restrictedGrossLoss && restrictedNegDays 
                    ? parseFloat((restrictedGrossLoss / restrictedNegDays).toFixed(2)) 
                    : 0;
                
                const restrictedRatioAvgPosNeg = restrictedAvgPosPnl && restrictedAvgNegPnl 
                    ? Math.abs(parseFloat((restrictedAvgPosPnl / restrictedAvgNegPnl).toFixed(2))) 
                    : 0;

                const restrictedProfitFactorFormated = restrictedTotalPnl && restrictedGrossLoss
                    ? Math.abs(parseFloat((restrictedTotalPnl / restrictedGrossLoss).toFixed(2)))
                    : 0;

                const restrictedMaxConPosDays = restrictedConPosDaysArr.length ?  Math.max(...restrictedConPosDaysArr) : 0;
                const restrictedMaxConNegDays = restrictedConNegDaysArr.length ?  Math.max(...restrictedConNegDaysArr) : 0;

                const restrictedMaxPnl = restrictedPnlArr.length ? Math.max(...restrictedPnlArr) : 0;
                const restrictedMinPnl = restrictedPnlArr.length ? Math.min(...restrictedPnlArr) : 0;

                const restrictedNetProfit = restrictedTotalPnl && retsrictedMaxDrawDown
                    ? parseFloat((restrictedTotalPnl / (retsrictedMaxDrawDown / 100)).toFixed(2))
                    : 0;

                const restrictedVolaility = calcVolatility(restrictedReturns, restrictedAvgReturns);

                const restrictedPercentProf = restrictedWinTrades && restrictedTotalTradesNum
                    ? parseInt(((restrictedWinTrades / restrictedTotalTradesNum) * 100).toFixed(0))
                    : 0;
                
                const restrictedRatioBuySell = restrictedTotalBuy && restrictedTotalSell 
                    ? parseFloat((restrictedTotalBuy / restrictedTotalSell).toFixed(2)) 
                    : 0;

                const restrictedSortinoRatio = calcSortinoRatio(restrictedReturns, restrictedAvgReturns);

                const restrictedRiskRatio = calcRiskRatio(restrictedPnlArr);

                const restrictedMaxPeakToTrought = calcMaxPeakToTrought(restrictedPnlArr);

                dataRows.push({
                    id: `${park.id}_restricted`,
                    name: `${park.name} with restrictions`,
                    market: parkData.length ? parkData[0].market : '',
                    // API can return values like 0.1 or 0.025, so rounding is required
                    totalEnergy: restrictedTotalEnergy,
                    avgPnlMWh: restrictedAvgPnlMWh,
                    totalPnlFormatted: restrictedTotalPnlFormated,
                    avgNbCyclesFormated: restrictedAvgNbCyclesFormated,
                    pnLMWhFormated: restrictedPnLMWhFormated,
                    grossProfit: restrictedGrossProfitFormated,
                    grossLoss: restrictedGrossLossFormated,
                    avgPosPnl: restrictedAvgPosPnl,
                    avgNegPnl: restrictedAvgNegPnl,
                    profitFactor: restrictedProfitFactorFormated,
                    ratioAvgPosNeg: restrictedRatioAvgPosNeg,
                    maxConPosDays: restrictedMaxConPosDays,
                    maxConNegDays: restrictedMaxConNegDays,
                    maxPnl: restrictedMaxPnl,
                    minPnl: restrictedMinPnl,
                    maxDrawDown: retsrictedMaxDrawDown,
                    netProfit: restrictedNetProfit,
                    volaility: restrictedVolaility,
                    isDaVsIbPark: isDaVsIbPark,
                    totalTradesNum: restrictedTotalTradesNum,
                    totalBuy: restrictedTotalBuy,
                    totalSell: restrictedTotalSell,
                    zeroTrades: restrictedZeroTrades,
                    winTrades: restrictedWinTrades,
                    looseTrades: restrictedLooseTrades,
                    percentProf: restrictedPercentProf,
                    ratioBuySell: restrictedRatioBuySell,
                    posDays: restrictedPosDays,
                    negDays: restrictedNegDays,
                    accuracyDay: restrictedAccuracyDay,
                    sharpeRatio: restrictedSharpeRatio,
                    sortinoRatio: restrictedSortinoRatio,
                    riskRatio: restrictedRiskRatio,
                    maxPeakToTrought: restrictedMaxPeakToTrought,
                    axisY: getAxisValue('y'),
                });
            }

            return [
                {
                    x: getAxisValue('x'),
                    y: getAxisValue('y'),
                    name: park.name.replace(/</g, '&lt;'),
                    cumulative: pl_data_optimised_cumulative,
                },
                ...(restrictions.length 
                    ? [{
                        x: getAxisValue('x', true),
                        y: getAxisValue('y', true),
                        name: `${park.name.replace(/</g, '&lt;')} with restrictions`,
                        cumulative: pl_data_with_restrictions,
                    }] : [])
            ];
        }).sort((a, b) => b[0].y > a[0].y ? 1 : -1).flat();
    // }
    //Sorting by Y 
    if(yAxis === MEASURE_MAX_DRAWDOWN)
        dataRows.sort((a, b) => b.axisY < a.axisY ? 1 : -1);
    else
        dataRows.sort((a, b) => b.axisY > a.axisY ? 1 : -1);

    await localForage.setItem(
        forgeVariable,
        {
            opts: opts,
            rows: dataRows,
            updated_at : moment().unix()
        },
        (err) => {
            err && console.log(err);
        }
    );
    return {
        opts: opts,
        rows: dataRows
    }
}

  export const calculateOptsAndRowsTemp = async (
    data,
    parks,
    xAxis, 
    yAxis,
    davsibParksId,
    currencyType = 'Local',
    restrictions = [],
    calcRestrictedKpi = false,
    dateFrom,
    dateTo,
    apiLabel
) => {
    const forgeVariable = `${parks.map((park) => park.id).join('_')}_${xAxis}_${yAxis}_${dateFrom}_${dateTo}_${currencyType}_${calcRestrictedKpi}_${JSON.stringify(restrictions)}_${apiLabel}`;
    const optsRowsData = await localForage.getItem(forgeVariable);

    if (!optsRowsData || optsRowsData.updated_at < moment().unix() - ONE_DAY_INTERVAL) {
        const calcData = await calcAll(data, parks, xAxis, yAxis, davsibParksId, currencyType, restrictions, calcRestrictedKpi, forgeVariable);
        return calcData;
    } else {
        return optsRowsData;
    };
}