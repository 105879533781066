import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import MaterialTable from '@material-table/core';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Textsms } from '@material-ui/icons';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CustomButton from 'components/CustomButtons/Button.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { security_fetch_params, get_markets_data } from 'actions/index';
import { logout } from 'utils/auth';
import LoginPage from 'views/Login/Oops.jsx';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import classNames from 'classnames';
import moment from 'moment';
import alertify from 'alertifyjs';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: (data) => dispatch(get_markets_data(data)),
});

const MaintenancesList = ({ isLoggedIn, classes, helpModeActive, tooltips, conn, markets, getMarkets }) => {
  const [globalMaintenances, setGlobalMaintenances] = useState([]);
  const [localMaintenances, setLocalMaintenances] = useState([]);

  const getMaintenances = async (type) => {
    const url =
      type === 'global'
        ? 'https://kube.sama.energy/gateway/api/maintenances'
        : `${conn.API_URL}/api/v1/maintenance?start_time=${
            moment.utc().add(-1, 'week').format('YYYY-MM-DDT00:00:00')
          }&end_time=${
            moment.utc().add(1, 'week').format('YYYY-MM-DDT23:59:59')
          }`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...security_fetch_params
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      logout();
      return null;
    } else {
      alertify.error(`Error getting ${type} maintenances list!`, 7);
      return null;
    }
  }

  const getGlobalMaintenances = async () => {
    const maintenances = await getMaintenances('global');
    if (maintenances) {
      const latestMaintenances = {};
  
      for (const maintenance of maintenances) {
        const { event_id, id } = maintenance;
    
        if (!latestMaintenances[event_id] || latestMaintenances[event_id].id < id) {
          latestMaintenances[event_id] = maintenance;
        }
      }
    
      const filteredMaintenances = Object.values(latestMaintenances).sort((a, b) => a.id < b.id ? 1 : -1);
      setGlobalMaintenances(filteredMaintenances);
    }
  }

  const getLocalMaintenances = async () => {
    const maintenances = await getMaintenances('local');
    if (maintenances) {
      const filteredMaintenances = maintenances.data
        .filter(el => el.event_id.includes('markets'))
        .map(el => {
          const { event_id } = el;
          const start = event_id.indexOf(':') + 1;
          const end = event_id.lastIndexOf(':');
          const objString = event_id.substring(start, end);

          if (objString.includes('"')) {
            const obj = JSON.parse(objString);
            return {
              ...el,
              ...obj
            };
          } else {
            return null;
          }
        })
        .filter(el => el)
        .sort((a, b) => moment(a.start_time) < moment(b.start_time) ? 1 : -1);

      setLocalMaintenances(filteredMaintenances);
    }
  }

  const deleteMaintenance = async (maintenanceId) => {
    const response = await fetch(`https://kube.sama.energy/gateway/api/maintenances/${maintenanceId}`, {
      method: 'DELETE',
      headers: {
      'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...security_fetch_params
    });

    const data = await response.json();
    console.log(data)
    getGlobalMaintenances();
  }
  
  useEffect(() => {
    getMarkets();
    getGlobalMaintenances();
    getLocalMaintenances();
  }, [])

  const optionsMatTable = {
    toolbar: false,
    tableLayout: 'fixed',
    paging: false,
    maxBodyHeight: window.innerHeight - 330,
    headerStyle: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    emptyRowsWhenPaging: false,
    pageSize: 6,
    draggable: false,
    search: false,
    cellStyle: {
      textAlign: 'center',
    },
  };

  const columnSettings = {
    sorting: false,
    width: 45,
    textAlign: 'center',
    headerStyle: {
      fontSize: '1em',
      padding: '1em 0',
      textAlign: 'center',
    },
  };

  const tableColumns = (type) => [
    ...(type === 'global' ? [
      {
        title: 'Message kind',
        field: 'message_kind',
        ...columnSettings,
      },
      {
        title: 'Exchanges',
        field: 'exchanges',
        ...columnSettings,
      },
    ] : []),
    ...(type === 'local' ? [
      {
        title: 'Markets',
        field: 'markets',
        ...columnSettings,
      },
      {
        title: 'Parks',
        field: 'parks',
        ...columnSettings,
      },
    ] : []),
    {
      title: 'Start time (UTC)',
      field: 'start_time',
      ...columnSettings,
    },
    {
      title: 'End time (UTC)',
      field: 'end_time',
      ...columnSettings,
    },
    ...(type === 'global' ? [
      {
        title: 'Comment',
        field: 'comment',
        ...columnSettings,
      },
      {
        title: 'Edit',
        field: 'edit',
        ...columnSettings,
      },
      // {
      //   title: 'Delete',
      //   field: 'delete',
      //   ...columnSettings,
      // },
    ] : []),
  ];

  const renderData = (data, type) => {
    const dataTradCon = { ...data };
    delete dataTradCon.tableData;
    const exchange = data?.contract_type?.exchanges?.join(', ') || '';
    const marketsStr = data?.markets?.map(el => markets.find(market => market.country_iso3166a2 === el).name).join(', ') || '';
    const parks = data?.parks?.join(', ') || '';
    return (
      <TableRow>
        {type === 'global' && (
          <>
            <TableCell align="center">{data.message_kind}</TableCell>
            <TableCell align="center">{exchange}</TableCell>
          </>
        )}
        {type === 'local' && (
          <>
            <TableCell align="center">{marketsStr}</TableCell>
            <TableCell align="center">{parks}</TableCell>
          </>
        )}
        <TableCell align="center">
            {moment(data.start_time).utc().format("DD-MM-YYYY HH:mm")}
        </TableCell>
        <TableCell align="center">
            {moment(data.end_time).utc().format("DD-MM-YYYY HH:mm")}
        </TableCell>
        {type === 'global' && (
          <>
            <TableCell align='center'>{data.comment}</TableCell>
            <TableCell align="center">
              <Link
                to={{
                  pathname: '/maintenance-details',
                  state: {
                    maintenance_id: data.id,
                    maintenanceType: 'Global',
                    typeOperation: 'edit',
                  },
                }}
              >
                <Textsms />
              </Link>
            </TableCell>
            {/* <TableCell align="center">
              <Textsms
                style={{ cursor: 'pointer' }}
                onClick={() => deleteMaintenance(data.id)}
              />
            </TableCell> */}
          </>
        )}
      </TableRow>
    );
  };

  if (isLoggedIn) {
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classNames(classes.cardTitleWhite)}>Global Maintenances</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  columns={tableColumns('global')}
                  data={globalMaintenances}
                  options={optionsMatTable}
                  components={{
                    Row: ({ data }) => renderData(data, 'global'),
                  }}
                />
              </CardBody>
              <CardFooter>
                <Link
                  to={{
                    pathname: '/maintenance-details',
                    state: {
                      maintenanceType: 'Global',
                      typeOperation: 'create',
                    },
                  }}
                >
                  <CustomButton 
                    color="primary" 
                    tooltip={tooltips?.new_maintenance || ''}
                    helpModeActive={helpModeActive} 
                  >
                    New Global Maintenance
                  </CustomButton>
                </Link>
              </CardFooter>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classNames(classes.cardTitleWhite)}>Local Maintenances</h4>
              </CardHeader>
              <CardBody>
                <MaterialTable
                  columns={tableColumns('local')}
                  data={localMaintenances}
                  options={optionsMatTable}
                  components={{
                    Row: ({ data }) => renderData(data, 'local'),
                  }}
                />
              </CardBody>
              <CardFooter>
                <Link
                  to={{
                    pathname: '/maintenance-details',
                    state: {
                      maintenanceType: 'Local',
                      typeOperation: 'create',
                    },
                  }}
                >
                  <CustomButton 
                    color="primary" 
                    tooltip={tooltips?.new_maintenance || ''}
                    helpModeActive={helpModeActive} 
                  >
                    New Local Maintenance
                  </CustomButton>
                </Link>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <LoginPage />
    )
  }
};

const ConnectedMaintenancesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenancesList);
export default withStyles(dashboardStyle)(ConnectedMaintenancesList);
