import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  buildAPIRequest,
  security_fetch_params,
  getDefaultPark,
  set_active_park,
  set_current_page,
  getModelsData,
  set_show_non_active_parks,
} from 'actions/index';

import MaterialTable from '@material-table/core';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import ParkSelector from 'components/ParkSelector/ParkSelector';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Textsms, ContentCopy } from '@material-ui/icons';

import CustomButton from 'components/CustomButtons/Button.jsx';

import { logout } from 'utils/auth';
import { downloadParks, getParkData } from 'utils/getDataMethods';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    activeParkState: state.conn.activePark,
    curPage: state.conn.curPage,
    showNonActive: state.conn.showNonActiveParks,
  };
};

const mapDispatchToProps = (dispatch) => ({
  set_active_park: (activePark) => dispatch(set_active_park(activePark)),
  set_current_page: (activePark) => dispatch(set_current_page(activePark)),
  getModelsData: () => dispatch(getModelsData()),
  setShowNonActive: (data) => dispatch(set_show_non_active_parks(data)),
});

const TradingConnectorsList = ({
  conn,
  classes,
  activeParkState,
  set_active_park,
  showNonActive,
  setShowNonActive,
  helpModeActive,
  tooltips
}) => {
  const [tradingConnectors, setTradingConnectors] = useState([]);
  const [activePark, setActivePark] = useState(null);
  const [parks, setParks] = useState([]);
  const [isActivePark, setIsActivePark] = useState(true);
  
  const getParks = async () => {
    const parks = await downloadParks(null, null, showNonActive);
    setParks(parks.data);
  };

  const getAllConnectors = async (parks) => {
    const connectors = await Promise.all(parks.map(async (park) => {
      let [url, headers] = buildAPIRequest(
        `/api/windparks/${park.id}/connector`
      );
      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...security_fetch_params
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          alertify.error("Response error");
          console.log(data.error);
          return [];
        }
        const parkConnectors = data.map(el => ({...el, park_name: park.name, park_id: park.id}));
        return parkConnectors;
      } else {
        alertify.error(`Error getting trading connectors for ${park.name}`);
        return [];
      }
    }));
    
    const allConnnectors = connectors.flat().sort((a, b) => a.park_name > b.park_name ? 1 : -1);

    setTradingConnectors(allConnnectors);
  }

  const downloadParkData = async (activePark) => {
    const park = await getParkData({ API_NAME: conn.label, API_URL: conn.API_URL }, activePark);

    if (park && park.stages.optimization_job && !isActivePark) {
      setIsActivePark(true);
    } else if ((!park || !park.stages.optimization_job) && isActivePark) {
      setIsActivePark(false);
    }
  }

  useEffect(() => {
    getParks();
  }, [showNonActive]);

  useEffect(() => {
    getAllConnectors(parks);
  }, [parks]);

  const handleChange = (_, value) => {
    if (!activeParkState || !value || value.id !== activeParkState.id) {
      set_active_park(value);
      setActivePark(value);
    }
  };

  useEffect(() => {
    if (activePark && activePark.id !== -1) {
      downloadParkData(activePark);
    }
  }, [activePark, tradingConnectors])

  const filteredConn = useMemo(
    () => tradingConnectors.filter(
      (el) => activePark ? el.park_id === activePark.id : el
    ), [tradingConnectors, activePark]
  );

  const optionsMatTable = {
    toolbar: false,
    tableLayout: 'fixed',
    paging: false,
    maxBodyHeight: window.innerHeight - 330,
    headerStyle: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    emptyRowsWhenPaging: false,
    pageSize: 6,
    draggable: false,
    search: false,
    cellStyle: {
      textAlign: 'center',
    },
  };

  const columnSettings = {
    sorting: false,
    width: 45,
    textAlign: 'center',
    headerStyle: {
      fontSize: '1em',
      padding: '1em 0',
      textAlign: 'center',
    },
  };

  const columnsTradConnNames = [
    {
      title: 'Park Name',
      field: 'park_name',
      ...columnSettings,
    },
    {
      title: 'Transport',
      field: 'transport',
      ...columnSettings,
    },
    {
      title: 'Enabled',
      field: 'enabled',
      ...columnSettings,
    },
    {
      title: 'Edit',
      field: 'edit',
      ...columnSettings,
    },
    {
      title: 'Copy',
      field: 'copy',
      ...columnSettings,
    },
    {
      title: 'Delete',
      field: 'delete',
      ...columnSettings,
    },
  ];

  const dataTradConn = [];
  filteredConn.forEach((item) => {
    const enabled = item.enabled ? 'Yes' : 'No';
    dataTradConn.push({
      id: item.id,
      park_id: item.park_id,
      park_name: item.park_name,
      transport: item.transport,
      enabled: enabled,
    });
  });

  const deleteItem = (parkId, tradeConnId) => {
    let [url, headers] = buildAPIRequest(
      `/api/windparks/${parkId}/connector/${tradeConnId}`
    );
    fetch(url, {
      method: 'DELETE',
      headers: headers,
      ...security_fetch_params,
    })
      .then((response) => {
        if (response.ok) {
          alertify.success('Trading connector has deleted', 5);
          getAllConnectors(parks);
        } else if (response.status === 401) {
          logout();
          return;
        } else {
          alertify.error(response.statusText, 5);
        }
      })
      .catch((error) => {
        console.log(error);
        alertify.error(error, 5);
      });
  };

  const renderData = (data) => {
    const dataTradCon = { ...data };
    delete dataTradCon.tableData;
    return (
      <TableRow>
        <TableCell align="center">{data.park_name}</TableCell>
        <TableCell align="center">{data.transport}</TableCell>
        <TableCell align="center">
            {data.enabled}
        </TableCell>
        <TableCell align="center">
          <Link
            to={{
              pathname: '/tradeconn-details',
              state: {
                tradeConId: data.id,
                parkId: data.park_id,
                typeOperation: 'edit',
              },
            }}
          >
            <Textsms />
          </Link>
        </TableCell>
        <TableCell align="center">
          <Link
            to={{
              pathname: '/tradeconn-details',
              state: {
                tradeConId: data.id,
                parkId: data.park_id,
                typeOperation: 'copy',
              },
            }}
          >
            <ContentCopy />
          </Link>
        </TableCell>
        <TableCell align="center">
          <Textsms
            style={{ cursor: 'pointer' }}
            onClick={() => deleteItem(data.park_id, data.id)}
          />
        </TableCell>
      </TableRow>
    );
  };
  return (
    <div>
      <Grid container spacing={4}>
        <ParkSelector 
          country_code={""}
          country_name={""}
          options={parks}
          selected={activePark}
          handleChange={handleChange}
          capacity={activeParkState?.capacity === null
            ? 'N/A'
            : activeParkState?.capacity.toFixed(1)}
          nonActiveParks={showNonActive}
          handleChangeNonActiveParks={(_, v) => setShowNonActive(v)}
          helpModeActive={helpModeActive}
        />
        <Grid item xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Trading Connectors</h4>
            </CardHeader>
            <CardBody>
              <MaterialTable
                columns={columnsTradConnNames}
                data={dataTradConn}
                options={optionsMatTable}
                components={{
                  Row: ({ data }) => renderData(data),
                }}
              />
            </CardBody>
            <CardFooter>
              {!isActivePark ? (
                <CustomButton 
                color="primary" 
                tooltip='Add new Trading Connector'
                disabled={true}
              >
                New Trading Connector
              </CustomButton>
              ) : (
                <Link
                  to={{
                    pathname: '/tradeconn-details',
                    state: {
                      parkId: activeParkState?.id,
                      typeOperation: 'new',
                    },
                  }}
                >
                  <CustomButton 
                    color="primary" 
                    tooltip={tooltips?.new_connector_button || 'Add new Trading Connector'}
                    disabled={!isActivePark || !activePark}
                    helpModeActive={helpModeActive} 
                  >
                    New Trading Connector
                  </CustomButton>
                </Link>
              )}
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const ConnectedTradingConnectorsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(TradingConnectorsList);
export default withStyles(styles)(ConnectedTradingConnectorsList);
