import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { TextField, Grid, Autocomplete, InputLabel, Checkbox, FormGroup } from '@mui/material';
import alertify from 'alertifyjs';
import classNames from 'classnames';
import localforage from 'localforage';
import moment from 'moment';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import LoginPage from 'views/Login/Oops.jsx';
import { buildAPIRequest, security_fetch_params, get_markets_data, pullParksFromInstance } from 'actions/index';
import { logout } from "utils/auth";
import Button from 'components/CustomButtons/Button.jsx';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { _schedule_api2display, _schedule_display2api } from 'utils/calcFunctions';
import { downloadParks } from 'utils/getDataMethods';
import { STRATEGIES } from 'constants/general';


const mapStateToProps = (state) => { 
  return {
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    markets: state.markets.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: (data) => dispatch(get_markets_data(data)),
})

const styles = {
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  hidden: {
    visibility: "hidden",
  },
  fullWidth: {
    width: '100%'
  }
};

const baseConfig = {
  name: '',
  location_id: null,
  market_id: null,
  data_source: 'forecast',
  is_portfolio: null,
  client_name: '',
  park_type: 'Flat Volume',
  strategy: '',
  schedule: '',
  extra_config: {},
  optimization_job: {
    Pmax: 0,
    normalize: false,
    optimizer: 'npm_simple',
    fixed_generation: 0,
    track_external: null,
    base_exchange: 'EPEX',
    exchange: 'EPEX'
  }
}

const ParkCreation = ({ classes, isLoggedIn, conn, markets, getMarkets, helpModeActive, tooltips }) => {
  const [locations, setLocations] = useState([]);
  const [config, setConfig] = useState(baseConfig);
  const [parks, setParks] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [trackedPark, setTrackedPark] = useState(null);
  const sortedMarkets = useMemo(() => [...markets].sort((a, b) => a.name > b.name), [markets]);
  const schedulesRef = useRef([]);

  const instances = useMemo(() => 
    Object.keys(conn.loginInstances).map(
      key => conn.loginInstances[key]
    ), [conn.loginInstances])

  const parksOpts = useMemo(() => {
    const market = config.market_id ? sortedMarkets.find(el => el.id === config.market_id) : null;
    let obj = {};
    if (parks) {
      Object.keys(parks).forEach((instance, i) => {
        obj = {
          ...obj,
          [instance]: parks[instance].filter(park => {
            if (market) {
              return park.market === market.name;
            } else {
              return park;
            }
          })
        }
      });
      
      return obj;
    }

    return null;
  }, [parks, config.market_id, config.location_id]);

  const getLocations = async () => {
    const stored = await localforage.getItem(`locations_${conn.label}`);

    if (!stored || stored.updated_at < moment().unix() - 3600) {
      const [_, headers] = buildAPIRequest('/api/locations');
      const url = `${conn.API_URL}/api/locations`;
      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...security_fetch_params
      })
      if (response.status === 401) {
        logout();
        return
      } else if (!response.ok) {
        alertify.error('Response error');
        console.log(response.statusText);
        return;
      }
    
      const data = await response.json();
      data.updated_at = moment().unix();
      
      localforage.setItem(`locations_${conn.label}`, data);
      setLocations(data.data);
    } else {
      setLocations(stored.data);
    }
  }

  const getInstancesParks = async () => {
    const parksObj = {}
    instances.forEach(async (instance, i) => {
      const parks = await downloadParks(null, instance.API_URL, false);
      parksObj[instance.target] = parks.data;

      if (Object.keys(parksObj).length === instances.length) {
        setParks(parksObj);
      }
    });
  }

  const getParkData = async (parkId, API_URL) => {
    const url = `${API_URL}/api/v1/parks/${parkId}`;
    const [_, headers] = buildAPIRequest();
    const response = await fetch(url, {
      method: 'GET',
      headers,
      ...security_fetch_params
    })
    if (response.ok) {
      const data = await response.json();
      setTrackedPark(data.data);
      setConfig({...config, schedule: data.data.schedule})
    } else if (response.status === 401) {
      logout();
      return null;
    } else {
      alertify.error('Error getting park data!', 5);
      return null;
    }
  }

  useEffect(() => {
    getLocations();
    getMarkets();
    getInstancesParks();
  }, [])

  useEffect(() => {
    if (
      config.market_id && 
      config.client_name && 
      config.strategy &&
      (config.park_type === 'Physical Park' ? config.location_id : true)
    ) {
      setIsActive(true);
    } else if (
      (
        !config.market_id || 
        !config.client_name || 
        !config.strategy ||
        (config.park_type === 'Physical Park' ? !config.location_id : false)
      ) && isActive) {
      setIsActive(false);
    }
  }, [config])
  
  useEffect(() => {
    if (config.optimization_job.track_external && config.optimization_job.track_external[1]) {
      const { API_URL } = instances.find(el => el.target === config.optimization_job.track_external[0]);
      getParkData(config.optimization_job.track_external[1], API_URL);
    } else if ((!config.optimization_job.track_external || !config.optimization_job.track_external[1]) && trackedPark) {
      setTrackedPark(null);
    }
  }, [config.optimization_job.track_external])
  
  const onChange = (value, field, key) => {
    if (field === 'optimization_job') {
      if (key === 'exchange') {
        setConfig({...config, [field]: {...config[field], [key]: value, 'base_exchange': value}, schedule: ''});
        return null;
      }

      setConfig({...config, [field]: {...config[field], [key]: value}, schedule: ''});
    } else if (field === 'market_id') {
      setConfig({
        ...config, 
        [field]: value, 
        location_id : null, 
        optimization_job: {
          ...config.optimization_job, 
          track_external: config.optimization_job.track_external 
            ? [config.optimization_job.track_external[0], null] 
            : null 
        }
      });
    } else if (field === 'location_id') {
      setConfig({
        ...config,
        [field]: value,
        optimization_job: {
          ...config.optimization_job,
          track_external: config.optimization_job.track_external
            ? [config.optimization_job.track_external[0], null]
            : null
        }
      });
    } else if (field === 'park_type') {
      setConfig({...config, location_id: null, [field]: value});
    } else {
      setConfig({...config, [field]: value});
    }
  }

  const onScheduleChange = (e, index) => {
    let scheduleArr = config.schedule.split(';');
    const stageSchedule = _schedule_display2api(e.target.value);
    scheduleArr[index] = stageSchedule;
    const schedule = scheduleArr.join(';');
    onChange(schedule, 'schedule');
  }

  const removeItemsFromLocalforage = async (key) => {
    const allKeys = await localforage.keys();
    const keysToRemove = allKeys.filter(el => el.toLowerCase().includes(key));
    keysToRemove.forEach(forageKey => {
      localforage.removeItem(forageKey);
    });
    pullParksFromInstance(conn);
  };

  const handleSubmit = async () => {
    const { name: marketName } = markets.find(el => el.id === config.market_id);
    const locationName = config.location_id ? locations.find(el => el.id === config.location_id).name.split(' (')[0] : ''
    const parsedLocation = locationName ? locationName.charAt(0).toUpperCase() + locationName.slice(1) : ''
    const name = /.*-\d+$/.test(marketName) 
          ? `${marketName.split('-')[0]}` 
          : marketName;
    let location_id = config.location_id;

    if (config.park_type === 'Flat Volume') {
      if (
        config.optimization_job.track_external && 
        config.optimization_job.track_external?.[0] && 
        config.optimization_job.track_external?.[1]
      ) {
        const trackedParkLocation = locations.find(el => el.name === trackedPark.location.name) || null;
        location_id = trackedParkLocation ? trackedParkLocation.id : locations.filter(el => el.country_name === name)[0]?.id;
      } else {
        const marketLocations = locations.filter(el => el.country_name === name);
  
        if (marketLocations.length) {
          location_id = marketLocations[0].id
        }
      }
    }
    
    const data = {
      ...config, 
      name: `${config.park_type === 'Physical Park' ? parsedLocation : marketName} (${config.strategy}) ${config.client_name}`,
      location_id: location_id,
      ...(
        trackedPark && 
        trackedPark.stages.optimization_job.map(el => el[0].split(' ')[0]).join() === 'AU,ID' 
          ? { optimization_job: {...config.optimization_job, entry_over: trackedPark.optimization_job.entry_over} } 
          : {}
      )
    }
    const [url, headers] = buildAPIRequest('/api/windparks');
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
      ...security_fetch_params
    })

    if (response.ok) {
      const resData = await response.json();
      
      if (resData.error) {
        alertify.error('Error creating park!', 7);
        return null
      }

      alertify.success(`Park ${data.name} was successfully created!`, 5);
    } else {
      alertify.error('Error creating park!', 5);
    }

    removeItemsFromLocalforage('parks')
  }

  const renderScheduleFields = () => {
    const schedules = config.schedule.split(';');
    return (
      <Grid container spacing={2}>
        {schedules.map((schedule, i) => {
          return (
            <Grid item xs={12} key={`schedule_${i}`}>
              <CustomTooltip 
                title={tooltips?.schedule || 'Set the schedule of optimisations (Local TIme)'}
                disableFocusListener={!helpModeActive}
                disableHoverListener={!helpModeActive}
                disableTouchListener={!helpModeActive}
              >
                <TextField 
                  type='text'
                  label={`${trackedPark.stages.optimization_job[i][0]} schedule (Local Time)`}
                  ref={(el) => schedulesRef.current[i] = el}
                  variant='standard'
                  value={_schedule_api2display(schedule)}
                  fullWidth
                  onChange={(e) => onScheduleChange(e, i)}
                />
              </CustomTooltip>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  if (isLoggedIn) {
    return locations.length && sortedMarkets.length && parks ? (
      <Card>
        <CardHeader color='primary'>
          <h4 className={classNames(classes.cardTitleWhite, classes.hidden)}>Park Creation</h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={6} lg={4} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.park_type || 'Select park type'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <Autocomplete 
                      value={config?.park_type || ''}
                      onChange={(e, v) => onChange(v ? v : '', 'park_type')}
                      options={["Physical Park", "Flat Volume"]}
                      getOptionLabel={(option) => option}
                      disableClearable
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Park Type'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.market || 'Select the market of the park'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <Autocomplete 
                      value={sortedMarkets.find(el => el.id === config.market_id) || null}
                      onChange={(e, v) => onChange(v ? v.id : null, 'market_id')}
                      options={sortedMarkets}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Market'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                {config.park_type === 'Physical Park' && (
                  <Grid item xs={12}>
                    <CustomTooltip 
                      title={tooltips?.location || "Select the park's location associated with the selected market"}
                      disableFocusListener={!helpModeActive}
                      disableHoverListener={!helpModeActive}
                      disableTouchListener={!helpModeActive}
                    >
                      <Autocomplete 
                        value={locations.find(el => el.id === config.location_id) || null}
                        onChange={(e, v) => onChange(v ? v.id : null, 'location_id')}
                        options={locations.filter(
                          el => {
                            const name = sortedMarkets.find(
                              el => el.id === config.market_id
                            )?.name;
                            const marketName = name && /.*-\d+$/.test(name) ? name.split('-')[0] : name;
                            
                            return el.country_name === marketName;
                          }
                        )}
                        getOptionLabel={(option) => option.city}
                        disabled={!config.market_id}
                        renderInput={(params) => (
                          <TextField {...params} variant='standard' label='location'/>
                        )}
                      />
                    </CustomTooltip>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.client_name || 'Specify client name'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <TextField 
                      type='text'
                      variant='standard'
                      label='Client Name'
                      fullWidth
                      value={config.client_name}
                      onChange={(e) => onChange(e.target.value, 'client_name')}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.strategy || 'Select strategy'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <Autocomplete 
                      value={config?.strategy || ''}
                      onChange={(e, v) => onChange(v ? v : '', 'strategy')}
                      options={[...STRATEGIES]}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Strategy'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.data_source || 'Specify data source'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <TextField 
                      type='text'
                      variant='standard'
                      label='Data Source'
                      fullWidth
                      value={config.data_source}
                      disabled={true}
                      onChange={(e) => onChange(e.target.value, 'data_source')}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.pmax || 'Maximum capacity of the park (MW)'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <TextField 
                      type='number'
                      variant='standard'
                      label='Pmax'
                      value={`${config.optimization_job.Pmax}`}
                      onChange={(e) => onChange(Number(e.target.value), 'optimization_job', 'Pmax')}
                      fullWidth
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip title="Select the exchange for electricity trading">
                    <Autocomplete 
                      value={config.optimization_job.exchange}
                      onChange={(_, v) => onChange(v, 'optimization_job', 'exchange')}
                      options={["EPEX", "GME"]}
                      disableClearable={true}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Exchange'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup row sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <InputLabel htmlFor='normalize' disabled={true}>Normalize</InputLabel>
                    <CustomTooltip 
                      title={tooltips?.normalize || 'Enable/disable data normalization'}
                      disableFocusListener={!helpModeActive}
                      disableHoverListener={!helpModeActive}
                      disableTouchListener={!helpModeActive}
                    >
                      <Checkbox 
                        id='normalize'
                        disabled={true}
                        sx={{paddingRight: 0}} 
                        value={config.optimization_job.normalize} 
                        onChange={(e, v) => onChange(v, 'optimization_job', 'normalize')}
                      />
                    </CustomTooltip>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.optimizer || 'Define the optimization model'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <TextField 
                      type='text'
                      label='Optimizer'
                      variant='standard'
                      disabled={true}
                      value={config.optimization_job.optimizer}
                      fullWidth
                      onChange={(e) => onChange(e.target.value, 'optimization_job', 'optimizer')}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.tracked_instance || 'Select the instance associated with the park you want to track'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <Autocomplete 
                      value={instances.find(el => el.target === config.optimization_job.track_external?.[0]) || null}
                      onChange={(e, v) => onChange( v ? [v.target, null] : null, 'optimization_job', 'track_external')}
                      options={instances}
                      getOptionLabel={(option) => option.API_NAME}
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Tracked Instance'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.tracked_park || 'Select a park for tracking purposes, allowing the created park to replicate the selected one'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <Autocomplete 
                      value={parksOpts[config.optimization_job.track_external?.[0]]
                        ?.find(el => el.id === config.optimization_job.track_external?.[1]) || null}
                      onChange={(e, v) => 
                        onChange(
                          v ? [config.optimization_job.track_external[0], v.id] : [config.optimization_job.track_external[0], v], 
                          'optimization_job', 
                          'track_external'
                        )
                      }
                      disabled={!config.optimization_job.track_external}
                      options={parksOpts[config.optimization_job.track_external?.[0]] || []}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} variant='standard' label='Tracked Park'/>
                      )}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                  <CustomTooltip 
                    title={tooltips?.fixed_generation || 'A fixed level of generation (MW)'}
                    disableFocusListener={!helpModeActive}
                    disableHoverListener={!helpModeActive}
                    disableTouchListener={!helpModeActive}
                  >
                    <TextField 
                      type='number'
                      label='Fixed generation'
                      variant='standard'
                      disabled={true}
                      value={config.optimization_job.fixed_generation}
                      fullWidth
                      onChange={(e) => onChange(e.target.value, 'optimization_job', 'fixed_generation')}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid item xs={12}>
                <FormGroup row sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <InputLabel htmlFor='is_portfolio' disabled={true}>Is portfolio</InputLabel>
                    <CustomTooltip 
                      title={tooltips?.is_portfolio || 'Check this box to indicate if the park is a portfolio'}
                      disableFocusListener={!helpModeActive}
                      disableHoverListener={!helpModeActive}
                      disableTouchListener={!helpModeActive}
                    >
                      <Checkbox 
                        id='is_portfolio'
                        disabled={true}
                        sx={{paddingRight: 0}} 
                        value={config.is_portfolio || false} 
                        onChange={(e, v) => onChange(e.target.checked ? e.target.checked : null, 'is_portfolio')}
                      />
                    </CustomTooltip>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  {trackedPark && 
                  config.optimization_job.track_external &&
                  trackedPark.id === config.optimization_job.track_external[1] ? (
                    renderScheduleFields()
                  ) : (
                    <CustomTooltip 
                      title={tooltips?.schedule || 'Set the schedule of optimisations (Local TIme)'}
                      disableFocusListener={!helpModeActive}
                      disableHoverListener={!helpModeActive}
                      disableTouchListener={!helpModeActive}
                    >
                      <TextField 
                        type='text'
                        label='Schedule (Local Time)'
                        variant='standard'
                        value={_schedule_api2display(config.schedule)}
                        fullWidth
                        onChange={(e) => onScheduleChange(e, 0)}
                      />
                    </CustomTooltip>
                  )}
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField 
                    type='text'
                    label='extra_config'
                    variant='standard'
                    value={config.extra_config}
                    fullWidth
                    onChange={(e) => onChange(e.target.value.replace(/\s+/g, '') , 'extra_config')}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Button 
                    color="primary" 
                    className={classes.fullWidth} 
                    onClick={() => handleSubmit()}
                    disabled={!isActive}
                    tooltip={tooltips?.create_park_button || 'Submit the form to finalize park creation'}
                    helpModeActive={helpModeActive}
                  >
                    Create Park
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    ) : (
      <div className={classes.loadingContainer}>
        <div className="loader" alt="Loading report..." />
      </div>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedParkCreation = connect(mapStateToProps, mapDispatchToProps)(ParkCreation);
export default withStyles(styles)(ConnectedParkCreation);
