import { buildAPIRequest, buildKUBERequest, security_fetch_params } from "actions/index";
import moment from "moment";
import localForage from "localforage";
import { logout } from "utils/auth";

import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/default.min.css";
import alertify from "alertifyjs";

export const getTradesData = async (date, park, instanceData, forceReCalculate) => {
  const [, headers] = buildAPIRequest();
  const url = `${instanceData.API_URL}/api/windparks/${park.id}/pl_detail/${date}${forceReCalculate ? '?forceReCalculate=1' : ''}`;
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
    ...security_fetch_params,
  });

  if (response.ok) {
    const data = await response.json();
    
    if (data.error) {
      alertify.error(`${data.error} for ${instanceData.API_NAME} ${park.name}!`, 7);
      console.log(data.error);
      return null;
    }

    return data;
  } else if (response.status === 401) {
    logout();
    return null;
  } else {
    alertify.error(`Error getting ${instanceData.API_NAME} ${park.name} trades data!`, 7);
    return null;
  } 
}

export const getMarketData = async (
  marketId,
  variable,
  dtFrom,
  dtTo,
  apiLabel,
  apiUrl,
  INTERVAL = 1000
) => {
  const DATE_FORMAT_STR = "YYYY-MM-DDTHH:mm:ss";

  const data = await localForage.getItem(
    `marketsVariable_${variable}_${dtFrom.format(
      "YYYY-MM-DD"
    )}_${dtTo.format("YYYY-MM-DD")}_${apiLabel}`
  );
  if (!data || data.updated_at < moment().unix() - INTERVAL) {
    const [url, headers] = buildKUBERequest(
      `/api/markets/rich_values/${marketId}/${encodeURIComponent(variable)}` +
      `?start=${dtFrom.format(DATE_FORMAT_STR)}` +
      `&end=${dtTo.format(DATE_FORMAT_STR)}`
    );
    const response = await fetch(url, {
      method: "GET",
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
      alertify.error('Response error!');
      if (variable.includes('ecb.europa.eu')) {
        alertify.error("Can not get currency rates!");
      }
    }
    const resJson = await response.json();
    resJson.updated_at = moment().unix();
    if (!resJson.error) {
      await localForage.setItem(
        `marketsVariable_${variable}_${dtFrom.format(
          "YYYY-MM-DD"
        )}_${dtTo.format("YYYY-MM-DD")}_${apiLabel}`,
        resJson,
        (err) => {
          err && console.log(err);
        }
      );
    } else {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    return resJson;
  }
  return data;
};

export const getDpMarketData = async (
  variable,
  dtFrom,
  dtTo,
  type,
  source,
  since,
  da,
  apiLabel,
  skip = null
) => {
  const INTERVAL = 1000;
  const DATE_FORMAT_STR = "YYYYMMDDHHmm";
  const fullVarName = `${variable}${type && type !== "All" ? ` ${type}` : ""}${
    source ? ` : ${source}` : ""
  }`;

  const aditionalParams = [];
  if (since) aditionalParams.push(`since=${since.format(DATE_FORMAT_STR)}`);
  if (da) aditionalParams.push(`da=1`);
  if (skip) aditionalParams.push(`skip=${skip}`);
  const urlString = `/api/dp_market_data/${dtFrom.format(
    DATE_FORMAT_STR
  )}/${dtTo.format(DATE_FORMAT_STR)}/${encodeURIComponent(
    fullVarName
  )}?${aditionalParams.join("&")}`;
  const localForgeVarString = `dp_market_data_${encodeURIComponent(
    fullVarName
  )}_${dtFrom.format(DATE_FORMAT_STR)}_${dtTo.format(DATE_FORMAT_STR)}_${
    since && since.format(DATE_FORMAT_STR)
  }_${da}_${skip}_${apiLabel}`;
  const data = await localForage.getItem(localForgeVarString);
  if (!data || data.updated_at < moment().unix() - INTERVAL) {
    const [url, headers] = buildAPIRequest(urlString);
    const response = await fetch(url, {
      method: "GET",
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    }
    const resJson = await response.json();
    resJson.updated_at = moment().unix();
    if(!resJson.error) {
      await localForage.setItem(localForgeVarString, resJson, (err) => {
        err && console.log(err);
      });
    } else {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    return resJson;
  }
  return data;
};

export const getDpParkData = async (
  variable,
  dtFrom,
  dtTo,
  type,
  source,
  apiLabel
) => {
  const INTERVAL = 1000;
  const DATE_FORMAT_STR = "YYYYMMDDHHmm";
  const fullVarName = `${variable}${type && type !== "All" ? ` ${type}` : ""}${
    source ? ` : ${source}` : ""
  }`;

  const aditionalParams = [];
  const urlString = `/api/v1/dp_park_data/${dtFrom.format(
    DATE_FORMAT_STR
  )}/${dtTo.format(DATE_FORMAT_STR)}/${encodeURIComponent(
    fullVarName
  )}?${aditionalParams.join("&")}`;
  const localForgeVarString = `dp_park_data_${encodeURIComponent(
    fullVarName
  )}_${dtFrom.format(DATE_FORMAT_STR)}_${dtTo.format(DATE_FORMAT_STR)}_${apiLabel}`;
  const data = await localForage.getItem(localForgeVarString);
  if (!data || data.updated_at < moment().unix() - INTERVAL) {
    const [url, headers] = buildAPIRequest(urlString);
    const response = await fetch(url, {
      method: "GET",
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    }
    const resJson = await response.json();
    resJson.updated_at = moment().unix();
    if (!resJson.error) {
      await localForage.setItem(localForgeVarString, resJson, (err) => {
        err && console.log(err);
      });
    } else {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    return resJson;
  }
  return data;
};

export const getVariables = async (marketId, varCategory, apiLabel) => {
  const INTERVAL = 1000;
  const urlString = `/api/dp_datafeeds/${marketId}/${varCategory}`;
  const localForgeVarString = `dp_datafeeds_${marketId}_${varCategory}_${apiLabel}`;
  const data = await localForage.getItem(localForgeVarString);
  if (!data || data.updated_at < moment().unix() - INTERVAL) {
    const [url, headers] = buildAPIRequest(urlString);
    const response = await fetch(url, {
      method: "GET",
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    }
    const resJson = await response.json();
    resJson.updated_at = moment().unix();
    if (!resJson.error) {
      await localForage.setItem(localForgeVarString, resJson, (err) => {
        err && console.log(err);
      });
    } else {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    return resJson.data;
  }
  return data.data;
};

export const getParkVariables = async (parkId, varCategory, apiLabel) => {
  const INTERVAL = 1000;
  const urlString = `/api/v1/dp_datafeeds/${parkId}/${varCategory}`;
  const localForgeVarString = `dp_park_datafeeds_${parkId}_${varCategory}_${apiLabel}`;
  const data = await localForage.getItem(localForgeVarString);
  if (!data || data.updated_at < moment().unix() - INTERVAL) {
    const [url, headers] = buildAPIRequest(urlString);
    const response = await fetch(url, {
      method: "GET",
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    }
    const resJson = await response.json();
    resJson.updated_at = moment().unix();
    if (!resJson.error) {
      await localForage.setItem(localForgeVarString, resJson, (err) => {
        err && console.log(err);
      });
    } else {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    return resJson.data;
  }
  return data.data;
};

// Change format of data for Highcharts
export const serializeData = (data) => {
  const result = [];
  data.data.forEach((el, ind) => {
    const price = el[0];
    const time = moment.tz(data.index[ind], "UTC").unix() * 1000;
    result.push([time, price]);
  });
  return result;
};

export const getParkVariableData = async (
  parkId,
  types,
  variable,
  dateFrom,
  dateTo,
  apiLabel
) => {
  let category;
  let source;
  let keyWord;
  if (variable === "capacity") {
    category = "Capacity";
    source = "Api";
    keyWord = "Total";
  }
    // Get list of all variables of the park
  const variables = await getParkVariables(parkId, category, apiLabel);

  // Get list of needed variables
  const fundVariables = types.map((el, id) => {
    const sourceId = Object.keys(variables[el]).indexOf(source);
    if (Object.keys(variables[el]).length) {
      return {
        variable: variables[el][Object.keys(variables[el])[sourceId]].filter((el) =>
          el.includes(keyWord)
        ),
        type: el,
        source: Object.keys(variables[el]).filter((sourceVar) =>
          sourceVar.includes(source)
        )[0]
      };
    } else return null;
  });

  const allVariables = [
    ...fundVariables.filter((variable) => variable)
  ];

  // Get array of promises with data of all curves
  const dpParkData = allVariables.map(async (variable) => {
    const data = await getDpParkData(
      variable.variable[0],
      dateFrom,
      dateTo,
      variable.type,
      variable.source,
      apiLabel
    );
    return data;
  });

  // Get array of data of all curves
  const dpParkDataCurve = await Promise.all(dpParkData);
  return dpParkDataCurve.filter(el => el).map((el, id) => {
    let label = "";
    if (allVariables[id].type === "Actual") label = "Actual";
    else if (allVariables[id].da)
      label = `${allVariables[id].type} ${allVariables[id].source} DA`;
    else
      label = `${allVariables[id].type} ${allVariables[id].source} ${allVariables[id].issue_ts}`;
    return {
      data: !el.error ? serializeData(el) : null,
      label: label,
      issue_ts: allVariables[id].issue_ts,
      source: allVariables[id].source,
      da: allVariables[id].da ? 1 : 0,
    };
  });
};

export const getVariableData = async (
  marketId,
  types,
  variable,
  forecastCurveLables,
  dateFrom,
  dateTo,
  country,
  issue_ts,
  apiLabel
) => {
  let category;
  let source;
  let keyWord;
  if (variable === "consumption_forecast_ensemble") {
    category = "Consumption";
    source = "EQ";
    keyWord = "Total";
  } else if (
    variable === "da_price_forecast" ||
    variable === "da_price_forecast_ensemble"
  ) {
    category = "Auction";
    source = "EQPriceSpot";
    keyWord = "1200-CET";
  } else if (variable === "production_solar_forecast_ensemble") {
    category = "Generation";
    source = "EQPower";
    keyWord = "Solar";
  } else if (variable === "production_wind_forecast_ensemble") {
    category = "Generation";
    source = "EQPower";
    keyWord = "Wind";
  } else if (variable === "temperature_forecast_ensemble") {
    category = "Weather";
    source = "EQTemperature";
    keyWord = "Temperature";
  } else if (variable === "consumption_divided_capacity") {
    category = "Capacity";
    source = "Samawatt";
    keyWord = "Total";
  }

  // Get list of all variables of the market
  const variables = await getVariables(marketId, category, apiLabel);
  // Get list of needed variables
  const fundVariables = types.map((el, id) => {
    let issueTsValue = null;
    let daValue = null;
    if (id === 0) issueTsValue = issue_ts[0];
    else if (id === types.length - 1) {
      daValue = 1;
    } else {
      issueTsValue = issue_ts[id - 1];
    }
    const eqId = Object.keys(variables[el]).indexOf(source);
   
    const key =
      keyWord === '1200-CET' &&
      variables[el][Object.keys(variables[el])[eqId]].filter(
        (el) => el.includes(keyWord)
      ).length === 0 ? '0920-WET' : keyWord;

    
    if (Object.keys(variables[el]).length && eqId >=0) {
      return {
        variable: variables[el][Object.keys(variables[el])[eqId]].filter((el) =>
          el.includes(key)
        ),
        type: el,
        issue_ts: issueTsValue,
        source: Object.keys(variables[el]).filter((sourceVar) =>
          sourceVar.includes(source)
        )[0],
        da: daValue,
      };
    }
    else return null;
  });

  // Add EnergyMeteo forecast if exist
  const forecastEmVariables = [];
  if (
    variables?.Forecast?.EnergyMeteo &&
    (variable === "production_solar_forecast_ensemble" ||
      variable === "production_wind_forecast_ensemble")
  ) {
    forecastCurveLables.forEach((el, id) => {
      const eqId = Object.keys(variables[el]).indexOf(source);
      forecastEmVariables.push({
        variable: variables[el][Object.keys(variables[el])[eqId]].filter((el) =>
          el.includes(keyWord)
        ),
        type: el,
        issue_ts: issue_ts[id],
        source: Object.keys(variables[el]).filter((sourceVar) =>
          sourceVar.includes("EnergyMeteo")
        )[0],
        da: !issue_ts[id] && 1,
      });
    });
  }

  // Add EnAppSys forecast if exist
  const forecastEnAppSysVariables = [];
  if (
    (variables?.Forecast?.EnAppSys &&
      (variable === "consumption_forecast_ensemble" ||
      variable === "da_price_forecast" ||
      variable === "da_price_forecast_ensemble" ||
      variable === "production_solar_forecast_ensemble" ||
      variable === "production_wind_forecast_ensemble" ||
      variable === "consumption_divided_capacity")
    )
  ) {
    forecastCurveLables.forEach((el, id) => {
      const eqId = 
        variable === "production_solar_forecast_ensemble" 
          ? Object.keys(variables[el]).indexOf("EnAppSysSolar") 
          : Object.keys(variables[el]).indexOf("EnAppSys");

      const key =
        keyWord === '1200-CET' &&
        variables[el][Object.keys(variables[el])[eqId]].filter(
          (el) => el.includes(keyWord)
        ).length === 0 ? '0920-WET' : keyWord;
      
      if (eqId >= 0) {
        forecastEnAppSysVariables.push({
          variable: variables[el][Object.keys(variables[el])[eqId]].filter(
            (el) => el.includes(key)
          ),
          type: el,
          issue_ts: issue_ts[id],
          source: Object.keys(variables[el]).filter((sourceVar) =>
            variable === "production_solar_forecast_ensemble"
              ? sourceVar.includes("EnAppSysSolar") 
              : sourceVar.includes("EnAppSys")
          )[0],
          da: !issue_ts[id] && 1,
        });
      }
    });
  }

  // Add Temperature EnAppSys forecast if exist
  const temperatureEnAppSysVar = [];
  if (
    variables?.Forecast?.EnAppSysTemperature && 
    variable === "temperature_forecast_ensemble"
  ) {
    forecastCurveLables.forEach((el, id) => {
      const eqId = Object.keys(variables[el]).indexOf("EnAppSysTemperature");

      if (eqId >= 0) {
        temperatureEnAppSysVar.push({
          variable: variables[el][Object.keys(variables[el])[eqId]].filter(
            (el) => el.includes(keyWord)
          ),
          type: el,
          issue_ts: issue_ts[id],
          source: Object.keys(variables[el]).filter((sourceVar) =>
            sourceVar.includes("EnAppSysTemperature")
          )[0],
          da: !issue_ts[id] && 1,
        });
      }
    });
  }

  const allVariables = [
    ...fundVariables.filter((variable) => variable),
    ...forecastEmVariables,
    ...forecastEnAppSysVariables,
    ...temperatureEnAppSysVar,
  ];

  // Get array of promises with data of all curves
  const dpMarketData = allVariables.map(async (variable) => {
    const data = await getDpMarketData(
      variable.variable.length !== 2 ? variable.variable[0] : variable.variable[1],
      dateFrom,
      dateTo,
      variable.type,
      variable.source,
      variable.issue_ts && variable.type !== "Actual"
        ? moment.tz(variable.issue_ts, "UTC")
        : null,
      variable.da,
      apiLabel
    );
    return data;
  });

  // Get array of data of all curves
  const dpMarketDataCurve = await Promise.all(dpMarketData);
  return dpMarketDataCurve.map((el, id) => {
    let label = "";

    const source =
      allVariables[id].source.includes('EQ')
        ? 'DS1'
        : allVariables[id].source === 'EnergyMeteo'
        ? 'DS2'
        : allVariables[id].source.includes('EnAppSys')
        ? 'DS3'
        : allVariables[id].source;
    
    if (allVariables[id].type === "Actual") label = "Actual";
    else if (allVariables[id].da)
      label = `${allVariables[id].type} ${source} DA`;
    else
      label = `${allVariables[id].type} ${source} ${allVariables[id].issue_ts}`;
    return {
      data: el && !el.error ? serializeData(el) : null,
      label: label,
      issue_ts: allVariables[id].issue_ts,
      source: source,
      da: allVariables[id].da ? 1 : 0,
    };
  });
};

export const getParkData = async (API, park) => {
  const forageStr = `park_data_${park.id}_${API.API_NAME}`;
  const storedData = await localForage.getItem(forageStr);

  if (!storedData || storedData.updated_at < moment().unix() - 3600) {
    const url = `${API.API_URL}/api/v1/parks/${park.id}`;
    const [, headers] = buildAPIRequest('/api/v1/parks');
    const response = await fetch(url, {
      method: 'GET',
      headers,
      ...security_fetch_params
    });

    if (response.ok) {
      const data = await response.json();
      localForage.setItem(
        forageStr, 
        {data: data.data, updated_at: moment().unix()}, 
        (err) => err && console.err(err)
      );
      return data.data;
    } else if (response.status === 401) {
      logout();
      return;
    } else {
      alertify.error(`Error getting ${API.API_NAME} ${park.name} data!`, 7);
      return null;
    }
  }

  return storedData.data;
}

export const downloadParks = async (strategy = null, instanceUrl = null, nonActiveParks = false)  => { 
  const isAllParks = nonActiveParks ? '' : 'filter=active';
  const isStrategy = strategy ? `&strategy=${strategy}` : '';
  const [currentInstanceUrl, headers] = buildAPIRequest(
    `/api/windparks?${isAllParks}${isStrategy}`,
  );
  const strat = strategy ? `&strategy=${strategy}` : '';
  const url = instanceUrl ? `${instanceUrl}/api/windparks?${isAllParks}${strat}` : currentInstanceUrl;
  const localForgeParksString = !strategy ? `parks_${instanceUrl || currentInstanceUrl}${!nonActiveParks ? '_active' : ''}` : `parks_${instanceUrl || currentInstanceUrl}_${strategy}${!nonActiveParks ? '_active' : ''}`;
  
  const data = await localForage.getItem(localForgeParksString);
  
  if (!data || data.updated_at < moment().unix() - 28800) {
    const response = await fetch(url, {
      method: 'GET',
      headers,
      ...security_fetch_params,
    });
    if (response.status === 401) {
      logout();
      return;
    } else if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    }
    const resJson = await response.json();
    if (resJson.error) {
      alertify.error("Response error");
      console.log(resJson.error);
      return
    }
    resJson.updated_at = moment().unix();
    await localForage.setItem(localForgeParksString, resJson, (err) => {
      err && console.log(err);
    });
    
    return resJson;
  }
  
  return data;
}
export const getActualCapacity = async (report_productive, park_id, date_from, date_to, api_label) => {
  if (!report_productive.length) {
    const dataPark = await getParkVariableData(
      park_id,
      ['Actual'],
      'capacity',
      date_from,
      date_to,
      api_label,
    );
    if (dataPark.length) {
      dataPark[0].data.forEach((item, id) => {
        dataPark[0].data[id][1] = item[1] === null ? null : item[1] * 1000;
      });

      return dataPark
    }
  } else {
    // Get capacity data for all parks in portfolio
    const dataPortfolioPromises = report_productive.map(
      async (item, id) => {
        const parkId = item.id;
        const dataPark = await getParkVariableData(
          parkId,
          ['Actual'],
          'capacity',
          date_from,
          date_to,
          api_label,
        );
        if(dataPark.length) {
          dataPark[0].data.forEach((item, id) => {
            dataPark[0].data[id][1] = item[1] === null ? null : item[1] * 1000;
          });
          return dataPark;
        }
      }
    );
    const dataPortfolio = await Promise.all(dataPortfolioPromises);

    // Calculate capacity for portfolio
    const calcDataPortfolio = [...dataPortfolio[0][0].data];
    dataPortfolio.forEach((item, id) => {
      if (id > 0) {
        item[0].data.forEach((item, id) => {
          if (item[1] !== null) calcDataPortfolio[id][1] += item[1];
        });
      }
    });

    return [{ data: calcDataPortfolio, label: 'Actual' }]
  }
}