import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import LoginPage from 'views/Login/Oops.jsx';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

import { get_fundamentals_curve, get_markets_data } from 'actions/index';

import { makeSeries, stocksChartOptions } from 'variables/charts';
import moment from 'moment';
import 'moment-timezone';
import { calculateWAPE } from 'utils/kpi';
import { getVariableData } from 'utils/getDataMethods';

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    actual: state.fundamentals.fundamentals_curve
      ? state.fundamentals.fundamentals_curve.actual.map((x) => ({
          time: moment.tz(x.time, 'UTC').unix() * 1000,
          value: x.value,
        }))
      : [],
    forecast: state.fundamentals.fundamentals_curve
      ? state.fundamentals.fundamentals_curve.data.map((x) => ({
          time: moment.tz(x.time, 'UTC').unix() * 1000,
          value: x.value,
        }))
      : [],
    apiLabel: state.conn.label,
    markets: state.markets.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_fundamentals_curve: (data) => dispatch(get_fundamentals_curve(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

// To have initial 15 days forecast & previous 15 days
const DAYS_SHIFT = 15;

class FundamentalsData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marketDataCurve: [[], []],
      dpMarketDataCurve: [],
      sourcesList: [],
      selectedIssueTs: [this.props.location.state.issue_ts[0]],
      selectedForecastSource: [],
      showDaCurve: false,
    };
    this.calculateWAPEs = this.calculateWAPEs.bind(this);
    this.handleChangeIssueTs = this.handleChangeIssueTs.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);

    this.chartRef = React.createRef();
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');
  }

  calcRatio = (cons, wind, solar) => {
    if (cons === null || (!wind && !solar)) {
      return null;
    } else if (cons === 0) {
      return null;
    } else if (wind === null || solar === null) {
      return null;
    } else {
      return cons / (wind + solar);
    }
  };

  calcCapacity = (cons, capacity) => {
    if (cons === null || !capacity) {
      return null;
    } else if (cons === 0 || capacity === 0) {
      return null;
    } else {
      return cons / capacity;
    }
  }

  handleChangeIssueTs = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      selectedIssueTs: typeof value === 'string' ? value.split(',') : value,
    });
  };

  handleChangeSource = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      selectedForecastSource:
        typeof value === 'string' ? value.split(',') : value,
    });
  };

  handleChangeDa = (event) => {
    this.setState({showDaCurve: event.target.checked});
  };

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getMarkets();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const markets = this.props.markets;
    const stateLocation = this.props.location.state;
    if (markets.length && !prevProps.markets.length) {
      const dateFrom = moment
        .tz(stateLocation.issue_ts[0], 'UTC')
        .add(-DAYS_SHIFT, 'days');
      const dateTo = moment
        .tz(stateLocation.issue_ts[0], 'UTC')
        .add(DAYS_SHIFT, 'days');

      //Get market id
      let marketId;
      for (let i = 0; i < markets.length; i++) {
        if (markets[i].country_iso3166a2 === stateLocation.country) {
          marketId = markets[i].id;
          break;
        }
      }
      
      // Prepare list of variables's types
      const forecastCurveLables = stateLocation.issue_ts.map(
        (el) => `Forecast`
      );
      
      // Variable for DA
      forecastCurveLables.push('Forecast');

      const types = ['Actual', ...forecastCurveLables];

      let data;
      if (
        stateLocation.variable !== "consumption_production_forecast_ratio" &&
        stateLocation.variable !== "consumption_divided_capacity"
      ) {
        data = await getVariableData(
          marketId,
          types,
          stateLocation.variable,
          forecastCurveLables,
          dateFrom,
          dateTo,
          stateLocation.country,
          stateLocation.issue_ts,
          this.props.apiLabel
        );
      }
      //Calculate consumption / (wind + solar) || consumption / capacity
      else {
        const dataConsumption = await getVariableData(
          marketId,
          types,
          "consumption_forecast_ensemble",
          forecastCurveLables,
          dateFrom,
          dateTo,
          stateLocation.country,
          stateLocation.issue_ts,
          this.props.apiLabel
        );

        if (stateLocation.variable === "consumption_divided_capacity") {
          const dataCapacity = await getVariableData(
            marketId,
            types,
            "consumption_divided_capacity",
            forecastCurveLables,
            dateFrom,
            dateTo,
            stateLocation.country,
            stateLocation.issue_ts,
            this.props.apiLabel
          );

          let calcData = [];
          for (let i = 0; i < dataConsumption.length; i++) {
            calcData.push({
              data: dataConsumption[i].data.map((el, id) => [
                el[0],
                this.calcCapacity(el[1], dataCapacity[0].data[id][1]),
              ]),
              label: dataConsumption[i].label,
              issue_ts: dataConsumption[i].issue_ts,
              source: dataConsumption[i].source,
              da: dataConsumption[i].da,
            });
          }
          data = [...calcData];
        } else {
          const dataSolar = await getVariableData(
            marketId,
            types,
            "production_solar_forecast_ensemble",
            forecastCurveLables,
            dateFrom,
            dateTo,
            stateLocation.country,
            stateLocation.issue_ts,
            this.props.apiLabel
          );
          const dataWind = await getVariableData(
            marketId,
            types,
            "production_wind_forecast_ensemble",
            forecastCurveLables,
            dateFrom,
            dateTo,
            stateLocation.country,
            stateLocation.issue_ts,
            this.props.apiLabel
          );
          let calcData = [];
          for (let i = 0; i < dataConsumption.length; i++) {
            calcData.push({
              data: dataConsumption[i].data.map((el, id) => [
                el[0],
                this.calcRatio(
                  el[1],
                  dataSolar[i].data[id][1],
                  dataWind[i].data[id][1]
                ),
              ]),
              label: dataConsumption[i].label,
              issue_ts: dataConsumption[i].issue_ts,
              source: dataConsumption[i].source,
              da: dataConsumption[i].da,
            });
          }
          data = [...calcData];
        }
      }

      this.setState({ dpMarketDataCurve: data });
    }

    // Set list of sources for select
    if (this.state.dpMarketDataCurve !== prevState.dpMarketDataCurve) {
      this.setState({
        sourcesList: this.state.dpMarketDataCurve
          ?.slice(1)
          .map((el) => el.source)
          .filter((v, i, a) => a.indexOf(v) === i),
      });
    }

    // Set first first element of sources list
    if (this.state.sourcesList !== prevState.sourcesList) {
      this.setState({ selectedForecastSource: [this.state.sourcesList[0]] });
    }
  }
  // WAPE calculation
  calculateWAPEs(data, min, max) {
    const actualCurve = data[0]?.data?.map((el) => ({
      time: el[0],
      value: el[1],
    }));
    const forecastsCurves = data.slice(1);
    const wapeForecast = forecastsCurves.map((el) => {
      const forecastCurve = el?.data?.map((el) => ({
        time: el[0],
        value: el[1],
      }));
      return calculateWAPE(actualCurve, forecastCurve, min, max);
    });

    const objNameForecastsCurves = {};

    // Prepare object with names of forecasts for WAPE
    forecastsCurves.forEach((el, id) => {
      objNameForecastsCurves[el.label] = wapeForecast[id];
    });

    return {
      Actual: NaN,
      ...objNameForecastsCurves,
    };
  }

  makeChartSeries(data) {
    if (data) {
      return data.map((el,id) => id !== 0 ? makeSeries(el.label, el.data) : makeSeries(el.label, el.data, {lineWidth: 3})); 
    }
  }

  render() {
    const { country, variableName, issue_ts } =
      this.props.location.state;
    const { classes, tooltips } = this.props;
    const { dpMarketDataCurve } = this.state;

    // get data for actual curve
    const actualDataCurve = dpMarketDataCurve[0];
    const daDataCurve = dpMarketDataCurve.filter((el) => el.da === 1)

    // get data for forecast curve(s), only that filtered by selected issue_ts and source
    const forecastDataCurves = dpMarketDataCurve
      .filter((el) => el.da !== 1 && el.label !== 'Actual')
      .filter((el) => this.state.selectedIssueTs.includes(el.issue_ts))
      .filter((el) => this.state.selectedForecastSource.includes(el.source));

    // get data for chart
    let data = [];
    if (actualDataCurve && forecastDataCurves) {
      data = [actualDataCurve, ...forecastDataCurves];
    }
    if(this.state.showDaCurve && daDataCurve.length) {
      data = [...data, ...daDataCurve.filter(el => this.state.selectedForecastSource.includes(el.source))]
    }

    const minX =
      moment.tz(issue_ts[0], 'UTC').add(-DAYS_SHIFT, 'days').unix() * 1000;
    const maxX =
      moment.tz(issue_ts[0], 'UTC').add(DAYS_SHIFT, 'days').unix() * 1000;
    const wapes = this.calculateWAPEs(data, minX, maxX);

    const that = this;
    const fullCountryName = this.props.markets.filter(
      (el) => el.country_iso3166a2 === country
    )[0]?.name;
    const title = `${fullCountryName || country} - ${variableName} - ${
      issue_ts.length === 2 ? issue_ts[0] : 'ALL'
    }`;

    const options = data && {
      ...stocksChartOptions({
        filename: title,
        legend: {
          itemMarginTop: 16,
          labelFormatter: function () {
            return !Number.isNaN(wapes[this.name]) && this.visible
              ? `${this.name}<br>MAPE: ${wapes[this.name].toFixed(2)}%`
              : `${this.name}`;
          },
        },
        events: {
          redraw: function (e) {
            const { min, max } = this.xAxis[0];
            const updatedWAPEs = that.calculateWAPEs(data, min, max);

            this.series.forEach((s) => {
              if (s.legendItem?.element) {
                const value =
                  s.visible && !Number.isNaN(updatedWAPEs[s.name])
                    ? `</tspan>MAPE: ${updatedWAPEs[s.name].toFixed(2)}%`
                    : '</tspan>';
                s.legendItem.element.innerHTML =
                  s.legendItem.element.innerHTML.replace(
                    /<\/tspan>(MAPE: \d+\.?\d+%)?/g,
                    value
                  );
              }
            });
          },
        },
        rangeSelector: {
          buttons: [],
          inputStyle: {
            fontSize: '1rem',
            lineHeight: 1.3,
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
      }),
      yAxis: [
        {
          title: {
            text: 'value',
          },
        },
        {
          title: {
            text: 'issue timestamp, UTC',
          },
          type: 'datetime',
          opposite: true,
        },
      ],
      series: [...this.makeChartSeries(data)],
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    const getStyles = (name, personName) => {
      return {
        fontWeight: personName.indexOf(name) === -1 ? 'normal' : 'bold',
      };
    };

    const issueTsListMenu = issue_ts.map((el) => (
      <MenuItem
        key={el}
        value={el}
        style={getStyles(el, this.state.selectedIssueTs)}
      >
        {el}
      </MenuItem>
    ));

    const sourcesListMenu = this.state.sourcesList.map((el) => (
      <MenuItem
        key={el}
        value={el}
        style={getStyles(el, this.state.selectedForecastSource)}
      >
        {el}
      </MenuItem>
    ));

    return this.props?.isLoggedIn ? (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card style={{ backgroundColor: '#EEEEEE' }}>
            <CardHeader color="primary">
              <h3>{title}</h3>
            </CardHeader>
            <CardBody style={{ backgroundColor: '#EEEEEE' }}>
              <Grid container spacing={4}>
                <Grid item xs={4} sm={4}>
                  <FormControl className={classNames(classes.fullWidth)}>
                    <InputLabel id="date-label">Select issue TS:</InputLabel>
                    <Select
                      labelId="date-label"
                      id="multiple-date-label"
                      multiple
                      value={this.state.selectedIssueTs}
                      onChange={this.handleChangeIssueTs}
                      MenuProps={MenuProps}
                    >
                      {issueTsListMenu}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl className={classNames(classes.fullWidth)}>
                    <InputLabel id="product-label">Select Source:</InputLabel>
                    <Select
                      labelId="product-label"
                      id="multiple-product-label"
                      multiple
                      value={this.state.selectedForecastSource}
                      onChange={this.handleChangeSource}
                      MenuProps={MenuProps}
                    >
                      {sourcesListMenu}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControlLabel
                    control={
                      <CustomTooltip 
                        title={tooltips?.show_da || 'Allows users to display day-ahead forecast data for the selected sources'}
                        disableFocusListener={!this.props.helpModeActive}
                        disableHoverListener={!this.props.helpModeActive}
                        disableTouchListener={!this.props.helpModeActive}
                      >
                        <Checkbox 
                          color="primary"
                          checked={this.state.showDaCurve}
                          onChange={this.handleChangeDa}
                        />
                      </CustomTooltip>
                    }
                    label="Show DA"
                  />
                </Grid>
                <Grid item xs={12}>
                  {this.state.dpMarketDataCurve.length === 0 ? (
                    <div className="loader" alt="Loading report..."/>
                  ) : (
                    <HighchartsReact
                      ref={this.chartRef}
                      highcharts={Highcharts}
                      constructorType={'stockChart'}
                      options={options}
                    />
                  )}
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter xs={12}></CardFooter>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <LoginPage />
    );
  }
}

FundamentalsData.propTypes = {
  classes: PropTypes.object.isRequired,
};

const FundamentalsDataConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(FundamentalsData);
export default withStyles(dashboardStyle)(FundamentalsDataConnect);
