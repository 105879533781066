import {
  FormControl,
  TextField,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import moment from 'moment';

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CustomButton from 'components/CustomButtons/Button';

import LoginPage from 'views/Login/Oops';

import { listMenu, MenuProps } from 'variables/data';

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';

import { getDpMarketData, serializeData } from 'utils/getDataMethods';

import { makeSeries, stocksChartOptions } from 'variables/charts';

import { calculateWAPE } from 'utils/kpi';
import alertify from 'alertifyjs';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const DAYS_SHIFT = 15;

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  apiLabel: state.conn.label,
});

const mapDispatchToProps = (dispatch) => ({});

const DataAnalysisDetails = ({ location, isLoggedIn, classes, apiLabel, helpModeActive, tooltips }) => {
  const variable = location.state.variable;
  const variables = location.state.variables;
  const types = location.state.types;
  const varTypes = location.state.varTypes;

  const varExtended = location.state.variableExtended;
  const variablesExtend = location.state.variablesExtend;

  let initObj = {};
  variables.forEach((el, id) => (initObj[id] = []));

  const [varSelectedSources, setVarSelectedSources] = useState(initObj);
  const [varSelectedTypes, setVarSelectedTypes] = useState(initObj);
  const [varSources, setVarSources] = useState(initObj);
  const [varRows, setVarRows] = useState(initObj);

  const [variablesData, setVariablesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedTypes, setSelectedTypes] = useState([]);

  const [dateFrom, setDateFrom] = useState(
    moment().add(-DAYS_SHIFT, 'days').format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    moment().add(DAYS_SHIFT, 'days').format('YYYY-MM-DD')
  );

  HighchartsExporting(Highcharts);
  HighchartsExportingData(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart, 'beforeShowResetZoom');

  // create array of data for chart
  const getData = async () => {
    setLoading(true);
    const hourFrom = '00:00:00';
    const hourTo = '23:59:59';
    const dateFromFormatted = moment(`${dateFrom} ${hourFrom}`);
    const dateToFormatted = moment(`${dateTo} ${hourTo}`);

    const varDataAll = [];
    Object.keys(varRows).forEach((el, ind) => {
      varRows[ind].forEach((row) => {
        varDataAll.push(
          getDpMarketData(
            row.variable,
            dateFromFormatted,
            dateToFormatted,
            row.type,
            row.source,
            row.since ? moment(row.since) : null,
            row.da ? 1 : null,
            apiLabel,
            row.skip
          )
        );
      });
    });

    const dataRes = await Promise.all(varDataAll);
    const dataSerialized = [];

    const varRowsArr = [];
    Object.keys(varRows).forEach((el, id) => {
      varRows[id].forEach((row) => {
        varRowsArr.push(row);
      });
    });

    dataRes.forEach((item, index) => {
      if (!item) return;
      if (item.error) alertify.error(`Error getting data: ${item.error} `);
      return (
        !item.error &&
        dataSerialized.push({
          variable: item.columns[0],
          data: serializeData(item),
          source: varRowsArr[index].source,
          type: varRowsArr[index].type,
        })
      );
    });

    setVariablesData(dataSerialized);
    setLoading(false);
  };

  useEffect(() => {
    if (
      Object.keys(varSelectedSources).some((el) => el.length) &&
      Object.keys(varSelectedTypes).some((el) => el.length) &&
      dateFrom &&
      dateTo
    ) {
      const varRowsCalc = {};

      variables.forEach((variable, ind) => {
        const rowsCalc = [];

        // create list of rows with variables data for request curve data
        let index = 0;
        varSelectedTypes[ind].forEach((type, i) => {
          varSelectedSources[ind].forEach((source, j) => {
            // check if the type include source
            if (variablesExtend[ind][type].includes(source)) {
              rowsCalc.push({
                id: index++,
                variable: variable,
                type: type,
                source: source,
                since: null,
                skip: null,
                da: false,
              });
            }
          });
        });
        varRowsCalc[ind] = rowsCalc;
      });

      setVarRows(varRowsCalc);
    } else setVarRows(initObj);
    // eslint-disable-next-line
  }, [varSelectedSources, varSelectedTypes, dateFrom, dateTo]);

  // if there is only one type in list, set it as selected
  useEffect(() => {
    if (types.length === 1) setSelectedTypes(types);
    // eslint-disable-next-line
  }, [location]);

  // prepare list of sources for selected types
  useEffect(() => {
    const sourcesArr = [];
    selectedTypes.forEach((type) => {
      varExtended[type].forEach((source) => {
        if (!sourcesArr.includes(source)) sourcesArr.push(source);
      });
    });
    // eslint-disable-next-line
  }, [selectedTypes]);

  // prepare list of sources for selected types
  useEffect(() => {
    const varSorucesObj = {};
    variables.forEach((variable, index) => {
      const sourcesArr = [];
      varSelectedTypes[index].forEach((type) => {
        variablesExtend[index][type].forEach((source) => {
          if (!sourcesArr.includes(source)) sourcesArr.push(source);
        });
      });
      varSorucesObj[index] = sourcesArr;
    });
    setVarSources(varSorucesObj);
    // eslint-disable-next-line
  }, [varSelectedTypes]);

  const handleChangeSettings = (event, index, par, setFunc) => {
    const {
      target: { value },
    } = event;
    const newPar = JSON.parse(JSON.stringify(par));
    newPar[index] = typeof value === 'string' ? value.split(',') : value;
    setFunc(newPar);
  };

  const handleButtonClick = () => {
    // if (
    //   selectedSources.length > 0 &&
    //   selectedTypes.length > 0 &&
    //   dateFrom &&
    //   dateTo
    // ) {
    getData();
    // }
  };

  const handleVarCellEdit = (params, ind) => {
    const newVarRows = JSON.parse(JSON.stringify(varRows));
    newVarRows[ind][params.id][params.field] = params.props.value;
    setVarRows(newVarRows);
  };

  const handleDateFromChange = (e) => {
    setDateFrom(e.target.value);
  };

  const handleDateToChange = (e) => {
    setDateTo(e.target.value);
  };

  const makeChartSeries = (data) => {
    if (data) {
      return data.map((el) => {
        if (!el) return null;
        //find id of the variable
        const varId = Object.entries(varRows).find((rows) =>
          rows[1].some(
            (row) =>
              row &&
              `${row.variable}${row.type !== 'All' ? ` ${row.type}` : ''} : ${
                row.source
              }` === el.variable
          )
        )[0];

        return makeSeries(
          `${Number(varId) + 1} - ${el.type} : ${el.source}`,
          el.data
        );
      });
    }
  };

  const calculateWAPEs = (min, max) => {
    const variablesWapeArrObj = {};

    let baseDataActual = null;

    Object.keys(varSources).forEach((key) => {
      const varWapeObj = {};
      const sources = varSources[key];
      sources.forEach((source, id) => {
        varWapeObj[source] = { baseCurve: null, wapeCurves: [] };
        varRows[key].forEach((row) => {
          if (row.source === source) {
            if (row.type === 'Actual') {
              varWapeObj[source][
                'baseCurve'
              ] = `${row.variable} ${row.type} : ${row.source}`;
            }
            if (row.type === 'Forecast' || row.type === 'Backcast') {
              varWapeObj[source]['wapeCurves'].push(
                `${row.variable} ${row.type} : ${row.source}`
              );
            }
          }
        });
      });
      variablesWapeArrObj[key] = varWapeObj;
    });

    const sumWapeObj = {};
    const reformatData = (data) =>
      data?.map((el) => {
        return {
          time: el[0],
          value: el[1],
        };
      });

    const wapesCalcObj = {};

    Object.keys(varRows).forEach((key) => {
      if (variablesData.length) {
        wapesCalcObj[key] = {};
        // first actual curve for next calculations
        Object.keys(variablesWapeArrObj[key]).forEach((source) => {
          if (variablesWapeArrObj[key][source]['baseCurve']) {
            const base = variablesData.find(
              (el) =>
                el.variable === variablesWapeArrObj[key][source]['baseCurve']
            );
            if (base) {
              //it could be that there is no data for variable
              const isBaseFullOfNull = base.data.every((el) => !el[1]); // check if every value is null
              if (!isBaseFullOfNull) {
                baseDataActual = base?.data;
              }
            } else {
              variablesWapeArrObj[key][source]['baseCurve'] = null;
            }
          }
        });
        Object.keys(variablesWapeArrObj[key]).forEach((source) => {
          // if actual exist for source then use it as base
          if (
            variablesWapeArrObj[key][source]['wapeCurves'].length &&
            variablesWapeArrObj[key][source]['baseCurve']
          ) {
            variablesWapeArrObj[key][source]['wapeCurves'].forEach(
              (forecastCurve) => {
                const base = variablesData.find(
                  (el) =>
                    el.variable ===
                    variablesWapeArrObj[key][source]['baseCurve']
                );
                const forecast = variablesData.find(
                  (el) => el.variable === forecastCurve
                );
                if (base && forecast)
                  wapesCalcObj[key][forecastCurve] = calculateWAPE(
                    reformatData(base?.data),
                    reformatData(forecast?.data),
                    min,
                    max
                  );
              }
            );
            // if actual not exist for source then use first actual as base
          } else if (
            variablesWapeArrObj[key][source]['wapeCurves'].length &&
            baseDataActual
          ) {
            variablesWapeArrObj[key][source]['wapeCurves'].forEach(
              (forecastCurve) => {
                const forecast = variablesData.find(
                  (el) => el.variable === forecastCurve
                );
                if (baseDataActual && forecast)
                  wapesCalcObj[key][forecastCurve] = calculateWAPE(
                    reformatData(baseDataActual),
                    reformatData(forecast?.data),
                    min,
                    max
                  );
              }
            );
          }
        });

        varRows[key].forEach((el) => {
          const legendName =
            // el.variable;
            `${Number(key) + 1} - ${el.type} : ${el.source}`;
          if (wapesCalcObj[key][`${el.variable} ${el.type} : ${el.source}`])
            sumWapeObj[legendName] =
              wapesCalcObj[key][
                `${el.variable}${el.type !== 'All' ? ` ${el.type}` : ''} : ${
                  el.source
                }`
              ];
          else sumWapeObj[legendName] = NaN;
        });
      }
    });
    return sumWapeObj;
  };

  const minX = moment(dateFrom).unix() * 1000;
  const maxX = moment(dateTo).unix() * 1000;
  const wapes = useMemo(() => {
    return calculateWAPEs(minX, maxX);
    // eslint-disable-next-line
  }, [variablesData]);

  // calculateWAPEs(minX, maxX);
  const options = useMemo(
    () =>
      variablesData && {
        ...stocksChartOptions({
          filename: variable,
          legend: {
            itemMarginTop: 16,
            itemWidth: 210,
            labelFormatter: function () {
              return !Number.isNaN(wapes[this.name]) && this.visible
                ? `${this.name}<br>MAPE: ${wapes[this.name].toFixed(2)}%`
                : `${this.name}`;
            },
          },
          events: {
            redraw: function (e) {
              const { min, max } = this.xAxis[0];
              const updatedWAPEs = calculateWAPEs(min, max);
              this.series.forEach((s) => {
                if (s.legendItem?.element) {
                  const value =
                    s.visible && !Number.isNaN(updatedWAPEs[s.name])
                      ? `</tspan>MAPE: ${updatedWAPEs[s.name].toFixed(2)}%`
                      : '</tspan>';
                  s.legendItem.element.innerHTML =
                    s.legendItem.element.innerHTML.replace(
                      /<\/tspan>(MAPE: \d+\.?\d+%)?/g,
                      value
                    );
                }
              });
            },
          },
          rangeSelector: {
            buttons: [],
            inputStyle: {
              fontSize: '1rem',
              lineHeight: 1.3,
              color: 'rgba(0, 0, 0, 0.87)',
            },
          },
          title: 'Variables',
        }),
        yAxis: [
          {
            title: {
              text: 'value',
            },
          },
          {
            title: {
              text: 'issue timestamp, UTC',
            },
            type: 'datetime',
            opposite: true,
          },
        ],
        series: [...makeChartSeries(variablesData)],
      },
    // eslint-disable-next-line
    [variablesData]
  );

  const columns = [
    { field: 'variable', headerName: 'Name', width: 300 },
    { field: 'type', headerName: 'Type', width: 130 },
    { field: 'source', headerName: 'Source', width: 180 },
    {
      field: 'since',
      headerName: (
        <div className={classes.flexContainer}>
          <span>{'Since'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.since || 'Serves as a time barrier for the data being displayed'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      type: 'dateTime',
      width: 220,
      editable: true,
    },
    {
      field: 'skip',
      headerName: (
        <div className={classes.flexContainer}>
          <span>{'Skip'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.skip || 'The unit is measured in hours. Only data that was available a specified number of hours prior to delivery will be included'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      type: 'number',
      width: 130,
      editable: true,
    },
    {
      field: 'da',
      headerName: (
        <div className={classes.flexContainer}>
          <span>{'DA'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.da || 'If active, the data that is returned is the latest data published before the Day-Ahead auction time'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      type: 'boolean',
      width: 130,
      editable: true,
    },
  ];

  const loader = (
    <div className={classes.loadingContainer}>
      <div className="loader" alt="Loading report..." />
    </div>
  );

  if (!isLoggedIn) return <LoginPage />;
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={classNames(classes.bgColor, classes.noMargin)}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Variables</h4>
            </CardHeader>
            <CardBody
              className={classNames(
                classes.bgColor,
                classes.sticky,
                classes.textLeft
              )}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classNames(classes.fullWidth)}>
                    <TextField
                      type="date"
                      value={dateFrom}
                      onChange={handleDateFromChange}
                      inputProps={{
                        name: 'dateFrom',
                        id: 'dateFrom',
                      }}
                      label="Date From:"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classNames(classes.fullWidth)}>
                    <TextField
                      type="date"
                      value={dateTo}
                      onChange={handleDateToChange}
                      inputProps={{
                        name: 'dateTo',
                        id: 'dateTo',
                      }}
                      label="To:"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                {variables.map((variable, index) => (
                  <Grid item xs={12} key={index}>
                    <Card
                      className={classNames(classes.bgColor, classes.noMargin)}
                    >
                      <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>
                          {variables[index]}
                        </h4>
                      </CardHeader>
                      <CardBody
                        className={classNames(
                          classes.bgColor,
                          classes.sticky,
                          classes.textLeft
                        )}
                      >
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} key={`${index}_type`}>
                            <FormControl
                              className={classNames(classes.fullWidth)}
                            >
                              <InputLabel id="type-label">
                                Select Types:
                              </InputLabel>
                              <Select
                                labelId="type-label"
                                id="multiple-type-label"
                                multiple
                                value={varSelectedTypes[index]}
                                onChange={(e) =>
                                  handleChangeSettings(
                                    e,
                                    index,
                                    varSelectedTypes,
                                    setVarSelectedTypes
                                  )
                                }
                                MenuProps={MenuProps}
                              >
                                {listMenu(
                                  varTypes[index],
                                  varSelectedTypes[index]
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} key={`${index}_source`}>
                            <FormControl
                              className={classNames(classes.fullWidth)}
                            >
                              <InputLabel id="source-label">
                                Select Source:
                              </InputLabel>
                              <Select
                                labelId="source-label"
                                id="multiple-source-label"
                                multiple
                                value={varSelectedSources[index]}
                                onChange={(e) =>
                                  handleChangeSettings(
                                    e,
                                    index,
                                    varSelectedSources,
                                    setVarSelectedSources
                                  )
                                }
                                MenuProps={MenuProps}
                              >
                                {listMenu(
                                  varSources[index],
                                  varSelectedSources[index]
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} key={`${index}_table`}>
                            <DataGrid
                              rows={varRows[index]}
                              columns={columns}
                              autoHeight
                              onEditCellPropsChange={(params) =>
                                handleVarCellEdit(params, index)
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                    <CustomButton 
                      color="primary" 
                      onClick={handleButtonClick} 
                      tooltip={tooltips?.get_data_button || 'Loads data and displays it on a graph'}
                      helpModeActive={helpModeActive}
                    >
                      Get data
                    </CustomButton>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    loader
                  ) : variablesData.length ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'stockChart'}
                      options={options}
                    />
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const ConnectedDataAnalysisDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataAnalysisDetails);
export default withStyles(dashboardStyle)(ConnectedDataAnalysisDetails);
