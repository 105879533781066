import React from 'react';
import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/icons';
import moment from 'moment';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const defaultTooltips = {
  'summary_day-ahead_optimised': "Displays the total of day-ahead cash flow obtained through optimization. It is equal to day-ahead trades times day-ahead price",
  'summary_intraday_optimised': "Displays the total of intraday cash flow obtained through optimization. It is equal to ID price times volume",
  'summary_imbalance_optimised': "Displays the total of imbalance cash flow obtained through optimization. It is equal to imbalance volume times imbalance price",
  'summary_total_optimised': "The cumulative financial result combining all optimised values (day-ahead, intraday, and imbalance)",
  'summary_production_sales':	"Revenues obtained from the sales of the produced energy. It is equal to Actual production times Day-ahead price",
  'summary_da_open position':	"Revenues obtained from the open positions taken on the day-ahead market, calculated as the difference between DA executed trades and DA forecast, times the spread between imbalance and DA prices",
  'summary_forecasting_error': "Revenues obtained from the forecasting error, calculated as the difference between the actual production and the DA forecast, times the spread between the imbalance and the DA prices",
  'summary_intraday_pnl': "P&L resulting from position adjustments in the intraday market",
  'summary_day-ahead_base':	"PnL values obtained from the day-ahead market and which are used as a benchmark to evaluate the added value of the optimisation",
  'summary_imbalance_base':	"PnL values obtained from the imbalance market and which are used as a benchmark to evaluate the added value of the optimisation",
  'summary_total_base':	"The cumulative PnL values combining all baseline results (day-ahead and imbalance)",
  'summary_added_value':	'The added value obtained from the optimisation compared to the base values. Calculated as the difference between "Total Optimised" and "Total Base"',
  'summary_pnl_details':	'The "PnL details" column contains buttons that redirect the user to the daily Profit and Loss report for a more detailed analysis of the P&L for the selected day',
  'summary_day-ahead_naive':	"PnL values obtained from the day-ahead market and which are used as a benchmark to evaluate the added value of the optimisation",
  'summary_imbalance_naive':	"PnL values obtained from the imbalance market and which are used as a benchmark to evaluate the added value of the optimisation",
  'summary_total_naive':	"The cumulative PnL values combining all baseline results (day-ahead and imbalance)",
}

const ProfitLossPeriod = ({
  classes,
  tableHead,
  tableData,
  park,
  market,
  isSkipIntraday,
  winHeight,
  currency,
  battery,
  withoutCache,
  helpModeActive,
  tooltips,
  ppaCalcParams = null,
  indexData = null,
}) => {
  const headerStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    color: primaryColor,
    fontSize: '1em',
    padding: '12px 0',
    textAlign: 'center',
    fontWeight: 500,
  };

  const columns = tableHead.map((head) => {
    if (head === 'Date') {
      return {
        title: head,
        field: head.toLowerCase(),
        width: 50,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
          color: primaryColor,
        },
        headerStyle: {
          left: '0px',
          zIndex: 3,
        },
      };
    } else if (head === 'PnL details'|| head === '') {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip 
                title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || 
                  defaultTooltips[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`]
                }
              >
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 60,
        sorting: false,
      };
    } else if (head.includes('Total Optimised') || head.includes('Added value')) {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip 
                title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || 
                  defaultTooltips[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`]
                }
              >
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 86,
        sorting: false,
      };
    } else {
      return {
        title: (
          <div className={classes.flexContainer}>
            <span>{head}</span>
            {(head && helpModeActive) && (
              <CustomTooltip 
                title={tooltips?.[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`] || 
                  defaultTooltips[`summary_${head.split(',')[0].toLowerCase().replace(' ', '_')}`]
                }
              >
                <HelpOutlineIcon fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: head.toLowerCase(),
        width: 100,
        sorting: false,
      };
    }
  });

  const renderTableRow = (data) => {
    const period = { ...data };
    delete period.tableData;
    delete period.id;
    let index;
    if (ppaCalcParams && period[0] !== 'Total') {
      index = ppaCalcParams.findLastIndex((params) => 
        moment(period[0]).isBetween(moment(params.dateFrom).format('YYYY-MM-DD'), moment(params.dateTo).format('YYYY-MM-DD'), null, '[]')
      );
    }
    return (
      <TableRow hover key={data.tableData.id} id={data.tableData.id}>
        {Object.keys(period).map((key) => {
          let cellClasses = '';

          if (
            tableHead[key].includes('Total Optimised') ||
            tableHead[key].includes('Total base') ||
            tableHead[key].includes('Total naive')
          ) {
            if (!String(period[0]).includes('Total')) {
              cellClasses = classes.lightblueCell;
            }
            else {
              cellClasses = classes.boldCell;
            }            
          }

          if (
            tableHead[key] === 'Date' 
              || tableHead[key]?.includes('Added value') 
              || tableHead[key]?.includes('Customer PnL')
              || tableHead[key]?.includes('Alternative PnL')
          ) {
            if (String(period[0]).includes('Total')) {
              cellClasses = classes.boldCell;
            }
          }

          if (tableHead[key] === 'PnL details') {
            return period[0] !== 'Total' ? (
              <TableCell
                align="center"
                key={data.tableData.id + Number(key)}
                className={`${classes.fundamentalCell}`}
              >
                <CustomTooltip
                  title={tooltips?.details || 'Go to pnl details page'}
                  disableFocusListener={!helpModeActive}
                  disableHoverListener={!helpModeActive}
                  disableTouchListener={!helpModeActive}
                >
                  <Link
                    to={{
                      pathname: '/profitlossdaily',
                      state: {
                        data: period,
                        park,
                        isSkipIntraday,
                        market,
                        currency: currency,
                        battery,
                        withoutCache,
                        ...(index !== undefined ? {ppaCalcParams: ppaCalcParams[index] || null, indexData: indexData} : {})
                      },
                    }}
                  >
                    {' '}
                    <Input />
                  </Link>
                </CustomTooltip>
              </TableCell>
            ) : (
              <TableCell
                align="center"
                key={data.tableData.id + Number(key)}
                className={`${classes.fundamentalCell}`}
              ></TableCell>
            );
          } else {
            const firstCell =
              String(period[0]).includes('Total') || !period[0].includes('-')
                ? period[0]
                : moment(period[0]).format('DD MMM YYYY');
            const restCell =
              typeof period[key] === 'string'
                ? period[key]
                : window.numberWithCommas(period[key]?.toFixed(2));
            return (
              <TableCell
                className={`${
                  key === '0' ? `${classes.stickyCol}` : ''
                } ${cellClasses} ${classes.fundamentalCell}`}
                align="center"
                key={data.tableData.id + Number(key)}
              >
                {key === '0' ? firstCell : restCell}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  // need object data for material table
  const refData = tableData.map((data, index) => {
    const objData = {};
    data.forEach((element, i) => {
      objData[i] = element;
      objData['id'] = index;
    });
    return objData;
  });

  return (
    <div className={classes.tableResponsive}>
      <MaterialTable
        columns={columns}
        data={refData}
        options={{
          toolbar: false,
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: winHeight - 280,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
        }}
        components={{
          Row: ({ data }) => renderTableRow(data),
        }}
      />
    </div>
  );
};

ProfitLossPeriod.defaultProps = {
  tableHeaderColor: 'primary',
  park: PropTypes.object.isRequired,
};

ProfitLossPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(ProfitLossPeriod);
