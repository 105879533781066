import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  ButtonGroup,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Chip,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { HelpOutline, ReportProblemRounded } from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select, MenuItem, Checkbox } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

import Highcharts, { chart } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';

import { DATE_FORMAT_DASH, DATE_FORMAT } from 'constants/general';

import { getPNLAnalysisData, getParks, get_markets_data } from 'actions';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CustomButton from 'components/CustomButtons/Button';
import 'moment-timezone';
import NoData from 'components/NoData';
import { usePrevious } from 'utils/hooks';
import LoginPage from 'views/Login/Oops';
import * as helper from './PNLAnalysisHelper';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';
import dayjs from 'dayjs';

import { getMarketData } from 'utils/getDataMethods'
import { stocksChartOptions, makeSeries, xAxisInCET } from 'variables/charts';

import localForage from 'localforage';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  isLoading: state.pnlAnalysis.loading,
  parks: state.pnlAnalysis.parks,
  filterStrategies: state.pnlAnalysis.filter.strategies,
  isPreloading: state.pnlAnalysis.preloading,
  data: state.pnlAnalysis.data,
  error: state.pnlAnalysis.error,
  mainMarket: state.conn.mainMarket,
  fullData: state.pnlAnalysis.fullData,
  marketsCountries: state.markets.markets,
  conn: state.conn,
});

const mapDispatchToProps = (dispatch) => ({
  fetchParks: (data) => dispatch(getParks(data, 'pnlAnalysis')),
  fetchData: (data) => dispatch(getPNLAnalysisData(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

const PNLAnalysis = ({
  classes,
  isLoggedIn,
  isLoading,
  isPreloading,
  data,
  fetchData,
  error,
  parks,
  filterStrategies,
  fetchParks,
  mainMarket,
  fullData,
  getMarkets,
  marketsCountries,
  conn,
  battery,
  helpModeActive,
  tooltips
}) => {
  const [strategies, setStrategies] = useState(battery ? ['All'] : [helper.STRATEGIES[0]]);
  const [contracts, setContracts] = useState([helper.CONTRACTS[0], helper.CONTRACTS[1], helper.CONTRACTS[2]]);
  const [xAxis, setXAxis] = useState(helper.MEASURE_PROFIT_MWH);
  const [yAxis, setYAxis] = useState(helper.MEASURE_PROFIT);
  const [dateFrom, setDateFrom] = useState(helper.INITIAL_DATE_FROM);
  const [dateTo, setDateTo] = useState(helper.INITIAL_DATE_TO);
  const [daysBack, setDaysBack] = useState('');

  const [visibleLegends, setVisibleLegends] = useState();

  const [selectedPeriod, setSelectedPeriod] = useState(0);

  const previousPeriod = usePrevious(selectedPeriod);
  const [preloadedPeriods, setPreloadedPeriods] = useState([]);

  const [davsibParksId, setDavsibParksId] = useState();

  const [pageSize, setPageSize] = useState(10);
  const [markets, setMarkets] = useState([]);

  const [currency, setCurrency] = useState('');
  const [currencyType, setCurrencyType] = useState('Local');

  const [isStopLoadingData, setIsStopLoadingData] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isShowingTable, setIsShowingTable] = useState(false);

  const [parksInEUR, setParksInEUR] = useState([]);
  const [visibleSeriesNames, setVisibleSeriesNames] = useState(new Set());
  // const [seriesNames, setSeriesNames] = useState([]);
  const initialRender = useRef(true);

  const savedParks = useRef([]);

  const [restrictionsData, setRestrictionsData] = useState([]);
  const [restrictionsFromStorage, setRestrictionsFromStorage] = useState(null);
  const [calcRestrictedKpi, setCalcRestrictedKpi] = useState(false);
  const [filter, setFilter] = useState('');

  const [optsRows, setOptsRows] = useState({opts: [], rows: []});

  const [optionsState, setOptionsState] = useState([]);

  const chartRef = useRef(null);
  const [serieses, setSerieses] = useState([]);
  const [visibleSeriesTest, setVisibleSeriesTest] = useState([]);
  const [visibleLegendsTest, setVisibleLegendsTest] = useState();

  const ONE_DAY_INTERVAL = 86400;

  HighchartsExporting(Highcharts);
  HighchartsExportingData(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');

  // Create object with min and max dates of data for each park
  const parksInfoFullData = {};
  if(fullData?.length){
    fullData.forEach((el) => {
      if(el)
        parksInfoFullData[el.id] = {
          'minDate': el.data[0]['date'],
          'maxDate': el.data[el.data.length - 1]['date'],
        };
    });
  }

  // Get market values from loaded parks
  const allMarkets = useMemo(() => {
    const uniqueMarkets = [...new Set(parks.map((p) => p.market))];
    if (mainMarket && !markets.length) setMarkets([mainMarket]);
    else if (!mainMarket && uniqueMarkets.length && !markets.length) setMarkets(uniqueMarkets);
    return uniqueMarkets;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parks, mainMarket]);

  const calcOptsData = async () => {
    if(parksInEUR.length && parks.length) {
      const filteredData = parksInEUR.filter(park => markets.includes(park.market));
      const filteredRestrictions = restrictionsData.filter(el => el.enabled);
      const { opts, rows } =  await helper.calculateOptsAndRowsTemp(filteredData, filteredParks, xAxis, yAxis, davsibParksId, currencyType, filteredRestrictions, calcRestrictedKpi, dateFrom.format(DATE_FORMAT_DASH), dateTo.format(DATE_FORMAT_DASH), conn.label);
      // setOptsTemp(opts);
      // setRowsTemp(rows);
      setOptsRows({opts, rows});
    }
  }

  // useEffect for opts temp
  useEffect(() => {
    calcOptsData();
  }, 
    [parksInEUR, restrictionsData, calcRestrictedKpi
      // , parks, xAxis, yAxis, davsibParksId, currencyType, restrictionsData, calcRestrictedKpi
    ]
  )

  // Fetch parks on strategies change
  useEffect(() => {
    (strategies.length && !initialRender.current) && fetchParks({ strategies, contracts });
  }, [strategies, fetchParks, contracts]);

  // Show warnings on strategies update
  useEffect(() => {
    
    if (!initialRender.current && strategies.length && !parks.length && markets.length) {
      alertify.error(`No parks for ${markets.join(',')} in ${strategies[0]} strategy`, 5);
      setStrategies([]);
    } else if (strategies.length === 1 && parks.length && markets.length) {
      let arr = [...markets];
      markets.forEach(market => {
        const contain = parks.filter(park => park.market === market);
        if(!contain.length) {
          arr = arr.filter(el => el !== market);
        }
      })
      if(!arr.length) {
        alertify.error(`No parks for ${markets.join(',')} in ${strategies[0]} strategy`, 5);
        setStrategies([]);
      } else {
        setMarkets(arr);
      }
    } else if (strategies.length > 1 && parks.length && markets.length) {
      let arr = [...markets];
      markets.forEach(market => {
        const contain = parks.filter(park => park.market === market);
        if(!contain.length) {
          arr = arr.filter(el => el !== market);
        }
      })
      if(!arr.length) {
        setMarkets([]);
      } else {
        setMarkets(arr);
      }
    }

    if (initialRender.current) initialRender.current = false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[parks])

  // Filter parks depending on selected market
  const filteredParks = useMemo(
    () => {
      if (battery) {
        return parks.filter((p) => markets.includes(p.market) && p.name.includes("Battery"))
      } else {
        return parks.filter((p) => markets.includes(p.market) && !p.name.includes("Battery"))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parks, markets]
  );

  // Fetch parks with DAvsIB strategy on first mount
  useEffect(
    () => {
      fetchParks(battery ? { strategies: ['All'] } : { strategies: ['DAvsIB'] })
      getMarkets();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const restrictions = localStorage.getItem('restrictions') ? JSON.parse(localStorage.getItem('restrictions')) : {}
    setRestrictionsFromStorage(restrictions)
  }, []);

  useEffect(() => {
    restrictionsFromStorage && setRestrictionsData(restrictionsFromStorage[conn.label] && restrictionsFromStorage[conn.label].length 
      ? restrictionsFromStorage[conn.label] 
      : []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictionsFromStorage])

  useEffect(() => {
    const data = {
      ...restrictionsFromStorage,
      [conn.label]: [...restrictionsData]
    };
    localStorage.setItem('restrictions', JSON.stringify(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictionsData])

  useEffect(() => {
    if (filterStrategies === 'DAvsIB') {
      const parksId = parks.map((el) => el.id);
      setDavsibParksId(parksId);
    }
  }, [data, filterStrategies, parks]);

  useEffect(() => {
    document.getElementById('mainPanel').scroll(0,0);
  },[strategies, markets, contracts])

  // Reset states
  useEffect(() => {
    if(selectedPeriod !== 0) setSelectedPeriod(0);
    setPreloadedPeriods([]);
    setIsStopLoadingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredParks), setPreloadedPeriods]);

  // Load data
  const loadData = useCallback(
    () => {
      if(dateFrom < moment('2010-01-01') || dateTo < moment('2010-01-01')) {
        alertify.error("It seems that From or To date is too low.");
        return;
      }
      return (filteredParks.length &&
        markets.length &&
          fetchData({
            parks: filteredParks,
            dateFrom: dateFrom.format(DATE_FORMAT),
            dateTo: dateTo.format(DATE_FORMAT),
            preloading: false,
            key: 'pnl_analysis',
            periodKey: `pnl_analysis_${strategies.join().replaceAll(',','_')}_${markets.join().replaceAll(',','_')}_${contracts.join().replaceAll(',','_')}_${moment(dateFrom).format(DATE_FORMAT)}_${moment(dateTo).format(DATE_FORMAT)}_${conn.label}`,
            period: dateTo.unix() - dateFrom.unix(),
          }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [markets, JSON.stringify(filteredParks), dateFrom.format(DATE_FORMAT_DASH), dateTo.format(DATE_FORMAT_DASH), fetchData]
  );

  // Initial API call
  useEffect(() => {
    if (filteredParks.length && JSON.stringify(filteredParks) !== JSON.stringify(savedParks.current)) {
      loadData();
      savedParks.current = filteredParks;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredParks)]);

  const onRefreshHandle = async () => {

    const optsRowsFromForage = await getOptsRowsFromForage();
    if (optsRowsFromForage && optsRowsFromForage.updated_at > moment().unix() - ONE_DAY_INTERVAL) {
      document.getElementById('mainPanel').scroll(0, 0);
      // setOptsTemp(optsRowsFromForage.opts);
      // setRowsTemp(optsRowsFromForage.rows);
      setOptsRows(optsRowsFromForage);
    } else {
      document.getElementById('mainPanel').scroll(0, 0);
      loadData();
    }

  }


  const getOptsRowsFromForage = async (dtFrom = null,dtTo = null) => {
    let from = dtFrom ? dtFrom : dateFrom;
    let to = dtTo ? dtTo : dateTo;
    const filteredRestrictions = restrictionsData.filter(el => el.enabled);
    const forgeVariable = `${filteredParks.map((park) => park.id).join('_')}_${xAxis}_${yAxis}_${from.format(DATE_FORMAT_DASH)}_${to.format(DATE_FORMAT_DASH)}_${currencyType}_${calcRestrictedKpi}_${JSON.stringify(filteredRestrictions)}_${conn.label}`;
    return await localForage.getItem(forgeVariable);
  }

  const handleRangeButtonClick = async () => {
    const preloadPeriod = helper.rangeButtons.filter((i) => i.preloading);
    if (previousPeriod !== selectedPeriod && previousPeriod !== undefined) {
      const optsRowsFromForage = await getOptsRowsFromForage();
      if (optsRowsFromForage && optsRowsFromForage.updated_at > moment().unix() - ONE_DAY_INTERVAL) {
        // setOptsTemp(optsRowsFromForage.opts);
        // setRowsTemp(optsRowsFromForage.rows);
        setOptsRows(optsRowsFromForage);
      } else {
        loadData();
        const lastPreload = preloadedPeriods[preloadedPeriods.length - 1];
        let newLoadPeriod = [];
        if (lastPreload)
          newLoadPeriod = preloadPeriod
            .filter((el) => el.order <= selectedPeriod && el.order > lastPreload)
            .map((el) => el.order);
        else
          newLoadPeriod = preloadPeriod
            .filter((el) => el.order <= selectedPeriod)
            .map((el) => el.order);
        setPreloadedPeriods([...preloadedPeriods, ...newLoadPeriod]);
      }
    }
  }
  // Load data immediately when a range button was clicked
  useEffect(() => {
    handleRangeButtonClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPeriod, selectedPeriod]);

  const preloadData = async () => {
    if (!isStopLoadingData) {
      // Download data in background for periods marked for preloading
      const preloadPeriods = helper.rangeButtons
        .filter((i) => i.preloading)
        // And data for these periods isn't preloaded yet
        .filter((period) => !preloadedPeriods.includes(period.order));
      // Check that:
      // 1) there's some periods to preload
      // 2) preloading isn't running. Wait for previous preloading to complete
      // 3) there's some parks
      // 4) there's some already loaded data.
      // Preloading has to be run preload after the initial loading
      if (
        preloadPeriods.length &&
        !isPreloading &&
        filteredParks.length &&
        data.length
      ) {
        // Get only the first period & preload data for it
        const period = preloadPeriods[0];
        const { dtFrom, dtTo } = helper.getRange(period.type, period.count);
        const parks = filteredParks
        const parksId = parks.map((el) => el.id);
        let check = false;
        if(Object.keys(parksInfoFullData).length)
          check = true;
          parksId.forEach((parkId) => {
            const INTERVAL = ONE_DAY_INTERVAL;
            if(!parksInfoFullData[parkId] || moment(parksInfoFullData[parkId]['minDate'],DATE_FORMAT_DASH) > dtFrom
              || fullData.updated_at < moment().unix() - INTERVAL
            ) {
              check = false;
            }
          });
        if(!check) {
          const optsRowsFromForage = await getOptsRowsFromForage(dtFrom, dtTo);
          if (!optsRowsFromForage || optsRowsFromForage.updated_at < moment().unix() - ONE_DAY_INTERVAL) {
            fetchData({
              parks: parks,
              dateFrom: dtFrom.format(DATE_FORMAT),
              dateTo: dtTo.format(DATE_FORMAT),
              preloading: true,
              key: 'pnl_analysis',
              periodKey: `pnl_analysis_${strategies.join().replaceAll(',','_')}_${markets.join().replaceAll(',','_')}_${contracts.join().replaceAll(',','_')}_${dtFrom.format(DATE_FORMAT)}_${dtTo.format(DATE_FORMAT)}_${conn.label}`,
              period: dtTo.unix() - dtFrom.unix(),
            });
          }
        }
        setPreloadedPeriods([...preloadedPeriods, period.order]);
      }
    }
  };

  // Preload data
  useEffect(() => {
    preloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPreloading,
    JSON.stringify(filteredParks),
    fetchData,
    preloadedPeriods,
    data,
    setPreloadedPeriods,
    isStopLoadingData,
  ]);

  const getDataInEUR = async () => {
    const nonEurMarkets = allMarkets
      .map(market => marketsCountries.find(el => el.name === market))
      .filter(market => market.currency !== 'EUR');

    const uniqueCurrencyMarkets = Array.from(new Set(nonEurMarkets.map(market => market.currency)))
      .map(currency => nonEurMarkets.find(el => el.currency === currency))

    const exchangeRates = uniqueCurrencyMarkets.map(async (market) => {
      const data = await getMarketData(
        `${market.currency === 'JPY' ? 67 : market.id}`,
        `ecb.europa.eu/EUR${market.currency} rate`,
        dateFrom.add(-7, 'days'),
        dateTo
      );

      const dates = helper.getDatesObj(dateFrom.add(-7, 'days').format(DATE_FORMAT_DASH), dateTo.format(DATE_FORMAT_DASH));
      let daysRate = {...dates}
      if (data.data && data?.data?.length) {

        data.data[0].forEach((date) => {
          daysRate[moment(date[0]).format('YYYY-MM-DD')] = date[1]
        })

        helper.fillUndefinedValues(daysRate);
        return {
          currency: market.currency,
          data: daysRate
        }
      } else {
        return {
          currency: market.currency,
          data: daysRate
        }
      }
    })

    const rates = await Promise.all(exchangeRates);
    const currencyRates = {} 
    rates.forEach(item => {
      currencyRates[item.currency] = item.data
    })
    
    const filtered = data.filter(el => el && filteredParks.find(park => park.id === el.id));

    const newData = JSON.parse(JSON.stringify(filtered));
    filtered.forEach((parkData, index) => {
      const [park] = parks.filter(park => park.id === parkData.id);
      if (park) {
        const [market] = marketsCountries.filter(market => market.name === park.market);
        newData[index].currency = market.currency;
        newData[index].market = market.name;
        return market;
      } else  
        return null;
    });

    newData.forEach(park => {
      park.data.forEach(data => {
        // if there is no currency rate for the day, use the value for currency rate for previous day
        const currencyRateForDay = currencyRates?.[park.currency]?.[data.date] || currencyRates?.[park.currency]?.[moment(data.date).subtract(1, 'days').format('YYYY-MM-DD')]; 
        data.added_value_eur =
          park.currency !== 'EUR' &&
          currencyRates &&
          currencyRates[park.currency] &&
          (currencyRates[park.currency][data.date] ||
            currencyRates?.[park.currency]?.[
              moment(data.date).subtract(1, 'days').format('YYYY-MM-DD')
            ])
            ? Number((data.added_value / currencyRateForDay).toFixed(2))
            : data.added_value;
        data.exchangeRate = currencyRateForDay;
      });
    });

    setParksInEUR(newData);
  };

  useEffect(() => {
    if (data.length && parks.length && marketsCountries.length && chartRef.current) {
      chartRef.current.chart.showLoading()
      getDataInEUR()
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, marketsCountries])

  // Process data for creating Chart options and rows for the Data Grid
  // const { opts, rows } = useMemo(() => {
  //   const filteredData = parksInEUR.filter(park => markets.includes(park.market));
  //   const filteredRestrictions = restrictionsData.filter(el => el.enabled);

  //   return helper.calculateOptsAndRows(filteredData, parks, xAxis, yAxis, davsibParksId, currencyType, filteredRestrictions, calcRestrictedKpi);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [parksInEUR, parks, xAxis, yAxis, davsibParksId, currencyType, restrictionsData, calcRestrictedKpi]);

  useEffect(() => {
    let object = {};
    if(optsRows.opts?.length)
      object = helper.calculateChartData(optsRows.opts, xAxis, yAxis, visibleLegends, currency, 
    // visibleSeriesNames, setVisibleSeriesNames
  );
    else 
      object = {
        options: [],
        // optionsCumulative: defaultOptionsComulative,
      };
    const { 
      // optionsCumulative, 
      options } = object;
    // setOptionsCumulativeState(optionsCumulative);
    const filteredOptions = options.filter(el => filter ? el.name.toLowerCase().includes(filter.toLowerCase()) : el)
    setOptionsState(filteredOptions);

  },[optsRows.opts, visibleLegends, filter
    // , visibleSeriesNames, setVisibleSeriesNames
  ])





  //------------------------------------

  const calcSerieses = (opts) => {
    const accomulativeData = opts.map((el) => ({
        name: el.name,
        cumulative: el.cumulative,
    }));
    // Prepare chart data
    const serieses = accomulativeData.map((el, ind) =>
        makeSeries(
            el.name,
            el.cumulative.map((val) => [val[0], val[1]]),
            { valueDecimals: 2, colorIndex: ind, 
              // visible: false 
            }
        )
    );
    return serieses.filter(el => filter ? el.name.toLowerCase().includes(filter.toLowerCase()) : el);
  };

  useEffect(() => {
    const serieses = calcSerieses(optsRows.opts);
    setSerieses(serieses);
  }, [optsRows.opts, filter]);

  useEffect(() => {
    if (optsRows.opts.length && !visibleSeriesTest.length) {
      const names = optsRows.opts.map((el) => el.name);
      const firstSelectedNames = names.slice(0, 10);

      setVisibleSeriesTest(firstSelectedNames);
    }
    if (optsRows.opts.length && visibleSeriesTest.length) {
      const selected = optsRows.opts.filter(series => visibleSeriesTest.includes(series.name));
      if (!selected.length) {
        const names = optsRows.opts.map((el) => el.name);
        const firstSelectedNames = names.slice(0, 10);
  
        setVisibleSeriesTest(firstSelectedNames);
      }
    }
  }, [optsRows.opts?.map((el) => el.name).sort().join()]);

  useEffect(() => {
    setVisibleSeriesTest([]);
  }, [markets]);

  const optionsCumulativeTest = {
    ...stocksChartOptions({
      filename: `Daily P&L Accumulated `,
      events: {
        load: function () {
            const input = document.createElement('input');
            const button = document.createElement('button');
            
            input.type = 'text';
            input.placeholder = 'Filter series data...';
            input.id = 'custom-filter';
            input.value = filter;
            
            button.textContent = 'Filter';
            button.type = 'button';
            

            input.style.position = 'absolute';
            input.style.left = '300px';
            input.style.top = '10px';
            input.style.zIndex = '20';

            button.style.position = 'absolute';
            button.style.left = '480px'
            button.style.top = '10px';
            button.style.width = '50px';
            button.style.height = 'max-content';
            
            this.renderTo.childNodes[0].appendChild(input);
            this.renderTo.childNodes[0].appendChild(button);
            
            input.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    setFilter(input.value);
                } 
            });

            button.addEventListener('click', () => {
                setFilter(input.value);
            })
        }
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function () {
              const seriesName = this.name;
              setVisibleSeriesTest((prevVisibleSeries) => {
                if (prevVisibleSeries.includes(seriesName)) {
                  return prevVisibleSeries.filter(
                    (name) => name !== seriesName
                  );
                } else {
                  return [...prevVisibleSeries, seriesName];
                }
              });
              return true; // Allow default legend click behavior
            },
          },
        },
      },
    }),
    yAxis:
      {
        title: {
          text: `Profit(Loss), ${currency || ''}`,
        },
      },
      ...xAxisInCET,
    series: serieses,
  };

  //Change the height of the chart related on how much parks in it
  const defChartHeight = optionsCumulativeTest.chart.height;
  optionsCumulativeTest.chart.height = defChartHeight + serieses.length * 0.02 * defChartHeight;

  // update visible series in chart
  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      chart.series.forEach((series) => {
        series.legendIndex = serieses.findIndex((el) => el.name === series.name);
        // set colorIndex in chart.series the same that in serieses
        // const find = serieses.find((el) => el.name === series.name);
        series.colorIndex = serieses.find((el) => el.name === series.name)?.colorIndex
        if (visibleSeriesTest.includes(series.name)) {
          series.show();
        } else {
          series.hide();
        }
      });
      chart.hideLoading()
    }
  }, [serieses, visibleLegendsTest]);

useEffect(() => {
  if (chartRef.current && chartRef.current.chart) {
    const chart = chartRef.current.chart;
    const extremes = chart.xAxis[0].getExtremes();
    chart.xAxis[0].setExtremes(extremes.dataMin, extremes.dataMax);
  }
}, [optionsCumulativeTest]);

  useEffect(() => {
    if (!strategies.length || !markets.length) setVisibleLegends(undefined);
  }, [strategies, markets])

  useEffect(() => {
    setVisibleLegends(undefined);
  }, [markets])
  
  const handleChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (!value) return;

      const newDate = moment(value);
      // dateFrom can't be more than dateTo
      if (name === 'dateFrom' && newDate < dateTo) {
        setDateFrom(newDate);
        // dateTo can't be less than dateFrom
      } else if (name === 'dateTo' && newDate > dateFrom) {
        setDateTo(newDate);
      }
      setDaysBack('');
    },
    [dateFrom.format(DATE_FORMAT_DASH), setDateFrom, dateTo.format(DATE_FORMAT_DASH), setDateTo]
  );

  const handlePeriodClick = useCallback(
    (button) => {
      if (isLoading && button.order > selectedPeriod) return;

      const { dtFrom, dtTo } = helper.getRange(button.type, button.count);

      setCalcRestrictedKpi(false);
      setSelectedPeriod(button.order);
      setDateFrom(dtFrom);
      setDateTo(dtTo);
      // setParksInEUR([]);
      // setSeriesNames([]);
    },
    [isLoading, selectedPeriod]
  );

  const handleXAxesChange = useCallback(
    (e, v) => {
      setXAxis(v);
    },
    [setXAxis]
  );

  const handleYAxesChange = useCallback(
    (e, v) => {
      setYAxis(v);
    },
    [setYAxis]
  );

  const handleDaysBackChange = useCallback(
    (e) => {
      const value = e.target.value;
      setDaysBack(value);
      const diff = moment().diff(moment(dateTo), 'days');
      setDateFrom(moment().add(`-${value ? Number(value) + Number(diff) - 1 : 7}`, 'days'));
    },
    [setDaysBack, setDateFrom, dateTo.format(DATE_FORMAT_DASH)]
  );

  const handleStrategiesChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setStrategies(['All']);
      } else if (e.target.textContent === 'None') {
        setStrategies([]);
      } else {
        setStrategies(v);
      }
      setCalcRestrictedKpi(false);
    }, [setStrategies]);

  const handleContractsChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setContracts(helper.CONTRACTS);
      } else if (e.target.textContent === 'None') {
        setContracts([]);
      } else {
        setContracts(v);
      }
      setCalcRestrictedKpi(false);
    }, [setContracts]);
  
  const handleMarketsChange = useCallback(
    (e, v) => {
      if (e.target.textContent === 'All') {
        setMarkets(allMarkets);
      } else if (e.target.textContent === 'None') {
        setMarkets([]);
      } else {
        setMarkets(v);
      }
      setCalcRestrictedKpi(false);
    }, [setMarkets, allMarkets]);

    const handleCurrencyTypeChange = (e, v) => {
      setCurrencyType(v);
      setCalcRestrictedKpi(false);
    }

  const calculateCurrency = async () => {
    let currency = '';
    if(markets.length === 1 && marketsCountries.length) {
      const market = marketsCountries.filter(el => el.name === markets[0])
      currency = currencyType === 'Local' && market ? market[0].currency : 'EUR';
    } else 
      {
        const currencies = marketsCountries.filter(el => markets.includes(el.name)).map(el => el.currency);
        // check if all markets have the same currency
        if (currencies.every((val, i, arr) => val === arr[0])) {
          currency = currencyType === 'Local' ? currencies[0] : 'EUR';
        } else 
          currency = currencyType === 'Local' ? 'LCY' : 'EUR';
      }
    setCurrency(currency);
  }
  useEffect(() => {
    calculateCurrency();
    onRefreshHandle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets, marketsCountries, currencyType]);

  const loader = (
    <div className={classes.loadingContainer}>
      <div className="loader" alt="Loading report..."/>
    </div>
  );

  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const restrictionsColumns = [
    {
      title: 'Date from',
      field: 'dateFrom',
      render: rowData => moment(rowData.dateFrom).format('DD.MM.YYYY'),
      editComponent: props => (
        <TextField 
          variant="outlined"  
          type='date' 
          value={props.value} 
          onChange={(event) => props.onChange(event.target.value)}
          className={classNames(classes.fullWidth)}
        />
      ),
      initialEditValue: moment().format('YYYY-MM-DD')
    },
    {
      title: 'Date to',
      field: 'dateTo',
      render: rowData => moment(rowData.dateTo).format('DD.MM.YYYY'),
      editComponent: props => (
        <TextField 
          variant="outlined"  
          type='date' 
          value={props.value} 
          onChange={(event) => props.onChange(event.target.value)}
          className={classNames(classes.fullWidth)}
        />
      ),
      initialEditValue: moment().format('YYYY-MM-DD')
    },
    {
      title: 'Time from (Local)',
      field: 'timeFrom',
      render: rowData => moment(rowData.timeFrom).format('HH:mm'),
      editComponent: props => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              value={dayjs(props.value)}
              onChange={(value) => props.onChange(dayjs(value).format('YYYY-MM-DD HH:mm'))}
              ampm={false}
              sx={{
                width: '100%'
              }}
            />
        </LocalizationProvider>
      ),
      initialEditValue: moment().format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Time to (Local)',
      field: 'timeTo',
      render: rowData => moment(rowData.timeTo).format('HH:mm'),
      editComponent: props => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              value={dayjs(props.value)}
              onChange={(value) => props.onChange(dayjs(value).format('YYYY-MM-DD HH:mm'))}
              ampm={false}
              sx={{
                width: '100%'
              }}
            />
        </LocalizationProvider>
      ),
      initialEditValue: moment().format('YYYY-MM-DD HH:mm')
    },
    {
      title: 'Day of the week',
      field: 'days',
      render: rowData => rowData.days.join(', '),
      editComponent: props => (
        <Select
          multiple
          value={props.value}
          onChange={
            (e) => props.onChange(e.target.value.length 
              ? e.target.value.sort((a, b) => weekdays.indexOf(a) - weekdays.indexOf(b)) 
              : weekdays)
          }
          className={classNames(classes.fullWidth)}
        >
          {weekdays.map(day => (
            <MenuItem key={day} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
      ),
      initialEditValue: weekdays
    },
    { 
      title: 'Type',
      field: 'type', 
      lookup: { buy: 'Buy', sell: 'Sell', both: 'Buy & Sell' }, 
      initialEditValue: 'buy' 
    },
    { 
      title: 'Enabled', 
      field: 'enabled',
      render: rowData =>  (
        <Checkbox
          checked={rowData.enabled}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
          onChange={(_, value) => {
            const { tableData, ...data } = rowData;
            const newData = { ...data, enabled: value };
            const newRestrictionsData = restrictionsData.map(el => el.id === newData.id ? newData : el);
            setCalcRestrictedKpi(false);
            setRestrictionsData(newRestrictionsData);
          }}
        />
      ),
      editComponent: props => (
        <Checkbox
          checked={!!props.value}
          onChange={(event) => props.onChange(event.target.checked)}
          name="enabled"
          className={classes.checkboxAdnRadioStyle}
        />
      ),
      initialEditValue: true
    }
  ]

  const content = !isLoading && marketsCountries.length ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card
          className={`${!isShowingTable ? classes.hidden : ''} ${classes.bgColor} ${
            classes.noMargin
          }`}
        >
          <CardHeader
            color="primary"
            className={!isShowingTable ? classes.visible : ''}
          >
            <h4
              className={classes.cardTitleWhite}
              style={{ position: 'relative' }}
            >
              Restrictions
              <IconButton
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  display: 'inline-flex',
                  position: 'absolute',
                  right: '0',
                }}
                onClick={() => setIsShowingTable(!isShowingTable)}
              >
                {isShowingTable ? (
                  <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                )}
              </IconButton>
            </h4>
          </CardHeader>
          <CardBody className={`${!isShowingTable ?  classes.hiddenTable : ''}`}>
            <div className={classNames(classes.fullWidth)}>
              <MaterialTable
                className={classes.headerMatTable}
                columns={restrictionsColumns}
                data={restrictionsData}
                title={"Restrictions Table"}
                editable={{
                  onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        newData.id = `uuid-${Math.random() * 10000}`
                        setCalcRestrictedKpi(false);
                        setRestrictionsData([...restrictionsData, newData]);
                        resolve();
                      }, 1000)
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        let dataUpdate = [...restrictionsData];
                        const updatedData = {...newData};
                        delete updatedData.tableData;

                        const target = dataUpdate.find(el => el.id === oldData.id);
                        const index = dataUpdate.indexOf(target);
                        dataUpdate[index] = updatedData;
                        setCalcRestrictedKpi(false);
                        setRestrictionsData([...dataUpdate]);
                        resolve();
                      }, 1000)
                    }),
                    onRowDelete: oldData => 
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataDelete = restrictionsData.filter((el) => el.id !== oldData.id);
                          setCalcRestrictedKpi(false);
                          setRestrictionsData([...dataDelete]);
                          resolve();
                        }, 1000)
                      }),
                }}
                localization={{
                  body: {
                    editRow: {
                      deleteText: 'Delete this term?'
                    }
                  }
                }}
                onRowsPerPageChange={setPageSize}
                options={{...helper.options(pageSize), actionsColumnIndex: -1}}
              />
            </div>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardBody className={classes.bgColor}>
            {filteredParks.length && strategies.length && contracts.length ? (
              <>
                <h4
                  className={classNames(
                    classes.cardTitleBlack,
                    classes.textColor
                  )}
                >
                  Accumulated Daily PnL
                </h4>
                <div className={classes.marginBottomL}>
                  <div 
                    className={classes.marginBottomL}
                    onDoubleClick={() => {
                      // if (optionsCumulativeState.series.length) {
                      //   const seriesNames = new Set(optionsCumulativeState.series.map(el => el.name));
                      //   setVisibleLegends(!visibleLegends);
                      //   visibleLegends ? setVisibleSeriesNames(new Set()) : setVisibleSeriesNames(seriesNames);
                      // }
                      if (optionsCumulativeTest.series.length) {
                        const seriesNames = optionsCumulativeTest.series.map(el => el.name);
                        setVisibleLegendsTest(!visibleLegendsTest);
                        visibleLegendsTest ? setVisibleSeriesTest([]) : setVisibleSeriesTest(seriesNames);
                      }
                    }}
                  >
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"stockChart"}
                      // options={optionsCumulativeState}
                      options={optionsCumulativeTest}
                      ref={chartRef}
                      oneToOne={true}
                      // immutable = {true}
                    />
                  </div>
                </div>
                <Grid container spacing={4}>
                  <Grid item xs={6} sm={6}>
                    <FormControl className={classes.marginBottom}>
                      <FormLabel id="xAxis">
                        <small>X Axis:</small>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="xAxis"
                        value={xAxis}
                        name="xAxis"
                        onChange={handleXAxesChange}
                      >
                        <FormControlLabel
                          value={helper.MEASURE_PROFIT}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_PROFIT}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_PROFIT_MWH}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_PROFIT_MWH}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_DAILY_AC}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_DAILY_AC}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_MAX_DRAWDOWN}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_MAX_DRAWDOWN}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_SHARPE_RATIO}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_SHARPE_RATIO}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControl className={classes.marginBottom}>
                      <FormLabel id="yAxis">
                        <small>Y Axis:</small>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="yAxis"
                        value={yAxis}
                        name="yAxis"
                        onChange={handleYAxesChange}
                      >
                        <FormControlLabel
                          value={helper.MEASURE_PROFIT}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_PROFIT}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_PROFIT_MWH}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_PROFIT_MWH}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_DAILY_AC}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_DAILY_AC}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_MAX_DRAWDOWN}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_MAX_DRAWDOWN}
                        />
                        <FormControlLabel
                          value={helper.MEASURE_SHARPE_RATIO}
                          control={<Radio color="primary" />}
                          label={helper.MEASURE_SHARPE_RATIO}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.marginBottomL}>
                  <Plot
                    className={classNames(classes.fullWidth, classes.bgColor)}
                    data={optionsState}
                    layout={helper.layoutOptions(
                      data,
                      strategies,
                      xAxis,
                      yAxis,
                      currency,
                    )}
                    config={{ displaylogo: false }}
                    useResizeHandler
                  />
                </div>
                <div className={classNames(classes.marginBottomL, classes.fullWidth)}>
                  <MaterialTable
                    className={classes.headerMatTable}
                    columns={helper.columns(currency, helpModeActive, battery)}
                    data={optsRows.rows.filter(el => filter ? el.name.toLowerCase().includes(filter.toLowerCase()) : el)}
                    title={"P&L KPI"}
                    onRowsPerPageChange={setPageSize}
                    options={helper.options(pageSize)}
                    components={{
                      Toolbar: props => (
                        <>
                          <MTableToolbar {...props}/>
                          <div style={{display: 'flex', padding: '0px 24px'}}>
                            <CustomButton 
                              color="primary" 
                              onClick={() => {
                                setCalcRestrictedKpi(true);
                                document.getElementById('mainPanel').scroll(0, 0);
                                setParksInEUR([]);
                                loadData();
                              }} 
                              disabled={!restrictionsData.filter(el => el.enabled).length}
                              tooltip={tooltips?.calculate_restricted_button || 'Displays KPIs with applied restrictions in the table, enabling direct comparison between unrestricted and restricted strategy performance for deeper insights into the effectiveness of constraints'}
                              helpModeActive={helpModeActive}
                            >
                              Calculate Restricted KPI
                            </CustomButton>
                          </div>
                        </>
                      )
                    }}
                  />
                </div>
              </>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  ) : (
    loader
  );

  const rangeButtonsGroup = helper.rangeButtons.map((button) => {
    const loadedPeriods = [...preloadedPeriods];
    if (isPreloading) {
      // Remove the last one as it's still preloading
      loadedPeriods.splice(-1);
    }
    const isPeriodLoaded =
      Math.max(...loadedPeriods) >= button.order;
    return (
      <Button
        key={button.order}
        color={
          button.order === selectedPeriod
            ? 'primary'
            : 'default'
        }
        onClick={() => handlePeriodClick(button)}
        variant={isPeriodLoaded && 'contained'}
      >
        {button.text}
      </Button>
    );
  });

  const strategiesOptions = [
    ...['All', 'None'],
    ...(battery ? ['Battery', 'BatteryAFRR'] : helper.STRATEGIES.filter((s) => !strategies.includes(s))),
  ];

  const contractsOptions = [
    ...['All', 'None'],
    ...helper.CONTRACTS.filter((s) => !contracts.includes(s)),
  ];

  const marketsOptions = [
    ...(allMarkets.length ? ['All', 'None'] : []),
    ...allMarkets.filter((m) => !markets.includes(m)),
  ];

  const currencyOptions = ['Local', 'EUR'];

  const renderOptions = (tagValue, getTagProps) =>
    tagValue.map((option, index) => (
      <Chip
        label={option}
        size="small"
        {...getTagProps({ index })}
      />
    ));

  if (!isLoggedIn) return <LoginPage />;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.stickyHead}>
        <Card
          className={`${!isOpen ? classes.hidden : ''} ${classes.bgColor} ${
            classes.noMargin
          }`}
        >
          <CardHeader
            color="primary"
            className={!isOpen ? classes.visible : ''}
          >
            <h4
              className={classes.cardTitleWhite}
              style={{ position: 'relative' }}
            >
              Profit & Loss Analysis
              <IconButton
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  display: 'inline-flex',
                  position: 'absolute',
                  right: '0',
                }}
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                )}
              </IconButton>
            </h4>
          </CardHeader>
          <CardBody
            className={classNames(
              classes.bgColor,
              classes.sticky,
              classes.textLeft
            )}
            style={!isOpen ? { display: 'none' } : undefined}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateFrom.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateFrom',
                      id: 'dateFrom',
                    }}
                    label="From:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateTo.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateTo',
                      id: 'dateTo',
                    }}
                    label="To:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.flexContainer}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                      <TextField
                        type="number"
                        label="Days range:"
                        variant="standard"
                        onChange={handleDaysBackChange}
                        value={daysBack}
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                      />
                  </FormControl>
                  {helpModeActive && (
                    <CustomTooltip title={tooltips?.days_range || 'Allows users to select the number of days for analysis without linking it to specific dates'}>
                      <HelpOutline fontSize='small'/>
                    </CustomTooltip>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} lg={6} className={classes.relative}>
                <FormLabel
                  className={classNames(classes.block, classes.marginBottomXs)}
                >
                  <small>Select range:</small>
                </FormLabel>
                <div className={classNames(classes.flexContainer, classes.fromStart)}>
                  <ButtonGroup size="small" aria-label="small button group">
                    {rangeButtonsGroup}
                  </ButtonGroup>
                  {helpModeActive && (
                    <CustomTooltip title={tooltips?.range || 'Allows users to quickly switch between specified time intervals'}>
                      <HelpOutline fontSize='small'/>
                    </CustomTooltip>
                  )}
                </div>

                {isPreloading && (
                  <div
                    className={classNames(
                      classes.loadingContainer,
                      classes.sizeS
                    )}
                    onClick={() => setIsStopLoadingData(true)}
                  >
                    <div
                      className={classNames('loader', 'sizeS')}
                      alt="Loading report..."
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={6} lg={3} className={classes.marginTopXs}>
                <CustomButton
                  color="primary"
                  onClick={() => setIsStopLoadingData(true)}
                  tooltip={tooltips?.stop_loading_button || 'Allows users to stop the data preloading process'}
                  helpModeActive={helpModeActive}
                >
                  Stop Loading
                </CustomButton>
              </Grid>
              <Grid item xs={6} lg={3} className={classes.marginTopXs}>
                <CustomButton
                  color="primary"
                  disabled={isLoading}
                  onClick={() => onRefreshHandle()}
                  tooltip={tooltips?.refresh_button || 'Updates the report using the selected parameters'}
                  helpModeActive={helpModeActive}
                >
                  Refresh
                </CustomButton>

                {error && (
                  <Tooltip
                    title={
                      <Typography fontSize={16}>Error: {error}</Typography>
                    }
                    className={classes.error}
                  >
                    <IconButton>
                      <ReportProblemRounded />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="strategies"
                    label="Select strategies:"
                    options={strategiesOptions}
                    value={strategies}
                    getOptionLabel={(option) => option}
                    onChange={handleStrategiesChange}
                    multiple
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="market"
                    label="Select markets:"
                    options={marketsOptions}
                    value={markets}
                    getOptionLabel={(option) => option}
                    onChange={handleMarketsChange}
                    multiple
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="contracts"
                    label="Select contracs:"
                    options={contractsOptions}
                    value={contracts}
                    getOptionLabel={(option) => option}
                    onChange={handleContractsChange}
                    multiple
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <ParkAutocomplete
                    id="currency"
                    label="Select currency:"
                    options={currencyOptions}
                    value={currencyType}
                    getOptionLabel={(option) => option}
                    onChange={handleCurrencyTypeChange}
                    limitTags={1}
                    disableClearable
                    renderTags={renderOptions}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.container}>{content}</div>
      </Grid>
    </Grid>
  );
};

PNLAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPreloading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  error: PropTypes.string,
  parks: PropTypes.array.isRequired,
  fetchParks: PropTypes.func.isRequired,
};

PNLAnalysis.defaultProps = {
  error: null,
};

const ConnectedPNLAnalysis = connect(
  mapStateToProps,
  mapDispatchToProps
)(PNLAnalysis);
export default withStyles(helper.styles)(ConnectedPNLAnalysis);
