const initPart = {
    'label': null,
    'parks': [],
    'activePark': null,
    'curPage': 0,
    'curRegion': 'ALL',
    'mainMarket': null,
    'reporting': false,    
    'showInstanceReport': false,
    'showAuditDashboard': false,
    'showCreation': false,
    'showJobsDetails': false
}
export const APIS = {
    'BEE': {
        'API_URL': 'https://bee.sama.energy',
        target: 'bee',
        ...initPart,
        reporting: true,
        shutdownSignals: true,
    },
    'Dev': {
        'API_URL': 'https://dev-005.sama.energy:8443',
        'target': 'default@dev005',
        ...initPart,
    },
    'Enexia': {
        'API_URL': 'https://enexia.sama.energy',
        target: 'enexia',
        ...initPart,
        reporting: true,
    },
    'EWZ': {
        'API_URL': 'https://ewz.sama.energy',
        target: 'ewz',
        stopTradingChannel: 'ewzfr-scheduler-state',
        ...initPart,
        reporting: true,
        shutdownSignals: true,
    },
    'globaltechone': {
        'API_URL': 'https://globaltechone.sama.energy',
        target: 'globaltechone',
        ...initPart,
        reporting: true,
    },
    'market': {
        'API_URL': 'https://datafeeds-02.sama.energy',
        target: 'market',
        ...initPart,
    },
    'Prod-models':{
        'API_URL': 'https://prod-models.sama.energy',
        target: 'prod-models',
        ...initPart,
        reporting: true,
        showJobsDetails: true,
    },
    'Stage': {
        'API_URL': 'https://stage.sama.energy:8043',
        'target': 'staging',
        ...initPart,
        showCreation:  true,
    },
    'Staging': {
        'API_URL': 'https://staging.sama.energy',
        'target': 'staging',
        ...initPart,
        showCreation:  true,
    },
    'Statarbitrage': {
        'API_URL': 'https://statarbitrage-002.sama.energy',
        target: 'statarbitrage',
        ...initPart,
        mainMarket: 'Germany',
    },
    'Statarbitrage-FR': {
        'API_URL': 'https://statarbitrage-001.sama.energy',
        target: 'fr_statarbitrage',
        ...initPart,
        mainMarket: 'France',
    },
    'Strat-customers': {
        'API_URL': 'https://strat-customers.sama.energy',
        target: 'strat-customers',
        ...initPart,
        reporting: true,
        showCreation: true,
    },
    'Test': {
        'API_URL': 'https://staging-test.sama.energy',
        'target': 'staging',
        ...initPart,
        showCreation: true,
    },
    'Trial': {
        'API_URL': 'https://trial.sama.energy',
        target: 'trial',
        ...initPart,
        reporting: true,
        showCreation:  true,
    },
    'Uniper': {
        'API_URL': 'https://uniper.sama.energy',
        target: 'uniper',
        ...initPart,
        reporting: true,
        showInstanceReport: true,
        showAuditDashboard: true,
        showCreation: true,
    }
}

export const API_DEFAULT = 'AXPO DE';

export const DEFAULT_PAGE_SIZE = 20;

// Date Formats
export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_DASH = 'YYYY-MM-DD';
export const DATE_FORMAT_DOTS = 'DD.MM.YYYY';

// Units
export const EUR = '\u20AC';
export const EUR_MWH = '\u20AC/MWh';

export const ACCOUNTS_API_PORT = process.env['REACT_APP_ACCOUNTS_API_PORT'];
export const OAMONITOR_IMAGE_TAG = process.env['REACT_APP_OAMONITOR_IMAGE_TAG'];

// DAvsID, DAvsIB, IDvsID, IDvsIB, DA+IDvsIB, DAvsID+IB, DA+IDvsID+IB
export const STRATEGIES = ['DAvsIB', 'DAvsID', 'IDvsID', 'IDvsIB', 'DAvsDA', 'DAIDvsIB', 'DAvsIDIB'];

export const CONTRACTS = ['1h','30min','15min'];
