import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

// @material-ui/core components
import {
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  withStyles,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

import ProfitLossReportDetails from 'components/Reports/ProfitLossReportDetails';
import ProfitLossReportSummary from 'components/Reports/ProfitLossReportSummary';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import DownloadButton from 'components/CustomButtons/DownloadButton';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import CustomButton from 'components/CustomButtons/Button'; 
import LoginPage from 'views/Login/Oops.jsx';
import { stocksChartOptions, makeSeries, xAxisInUTC } from 'variables/charts';
import { getParkData } from 'utils/getDataMethods';
import { calcGranData } from 'utils/calcFunctions';
import { oneDimVol, resampleArrToHoursDim, resampleArrToGran } from 'utils/calcFunctions';
import { get_generation_data, get_plreport_data, set_pnl_interval_currency_type } from 'actions/index';
import { DATE_FORMAT, DATE_FORMAT_DASH } from 'constants/general';
import { getActualCapacity, getMarketData } from 'utils/getDataMethods';
import * as helper from '../KPI/PNLAnalysisHelper';

const mapStateToProps = (state) => {
  return {
    tableDataGran: state.reports.plreport.tableDataGran,
    daOpenPositionGran: state.reports.plreport.daOpenPositionGran,
    prodSalesGran: state.reports.plreport.prodSalesGran,
    idPnlGran: state.reports.plreport.idPnlGran,
    tableHead: state.reports.plreport.tableHead,
    tableDetailsData: state.reports.plreport.tableDetailsData,
    tableDetailsDataGran: state.reports.plreport.tableDetailsDataGran,
    idTradesData: state.reports.plreport.idTradesData,
    auctionsData: state.reports.plreport.auctionsData,
    tableDetailsHead: state.reports.plreport.tableDetailsHead,
    allData: state.reports.plreport.allData,
    hourly: state.reports.plreport.hourly,
    parameters: state.reports.plreport.parameters,
    loading: state.reports.plreport.loading,
    isSkipIntraday: state.reports.plreport.isSkipIntraday,
    isLoggedIn: state.login.loggedIn,
    conn: state.conn,
    report_productive: state.generation.report_productive,
    currencyType: state.reports.currencyType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_generation_data: (data) => dispatch(get_generation_data(data)),
    get_plreport_data: data => dispatch(get_plreport_data(data)),
    set_currency_type: (data) => dispatch(set_pnl_interval_currency_type(data)),
  }
}

const styles = theme => ({
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  spacing: {
    margin: '0 1rem',
  },
  bgColor: {
    backgroundColor: '#fff',
  },
  textColor: {
    color: '#000',
  },
  flex: {
    display: 'flex',
  },
  sticky: {
    position: 'sticky',
    top: '-10px',
    zIndex: '99',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  fromStart: {
    display: 'flex',
    justifyContent: 'start',
  }
});

class ProfitLossReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      after_loggedin: false,
      forceReCalculate: false,
      cashFlow: false,
      date: moment(this.props.location.state.data[0]).format(DATE_FORMAT_DASH),
      // Should use this state variables because make two requests to metricst data
      tableData: [],
      tableDetailsData: undefined,
      tableDetailsHead: undefined,
      allData: undefined,
      hourly: undefined,
      parameters: undefined,
      loading: true,
      parkData: null,
      isOpen: true,
      curGran: '1',
      capacity: [],
      daOpenPosGran: null,
      forecastingErrorGran: null,
      exchangeRate: null,
      indexData: null,
      benchmark: this.props.location.state.ppaCalcParams?.benchmark || 'Index - Service fee',
      selectedIndex: this.props.location.state.ppaCalcParams?.index || 'Day-Ahead price',
      fixedPrice: this.props.location.state.ppaCalcParams?.fixedPrice ||'',
      indexMultiplier: this.props.location.state.ppaCalcParams?.indexMultiplier || '',
      serviceFee: this.props.location.state.ppaCalcParams?.serviceFee || '',
      isSimulationActive: this.props.location.state.ppaCalcParams ? true : false,
      indexOptions: [
        "Day-Ahead price",
        ...(this.props.location.state.park.market === 'Germany'   
          ? [
            "Monatsmarktwerte wind onshore (Germany)",
            "Monatsmarktwerte wind offshore (Germany)",
            "Monatsmarktwerte wind solar (Germany)",
          ] : []
        ),
        ...(this.props.location.state.park.market === 'France' 
          ? [
            "M0 Wind (France)", 
            "M0 Solar (France)",
          ] : []
        ),
      ]
    };
    this.handleChkChange = this.handleChkChange.bind(this);
    this.getExchangeRate = this.getExchangeRate.bind(this);
    this.da_data = [];
    this.pnl_data = [];
    this.id_data = [];
    this.ib_data = [];
    this.vol_data = [];
    this.battery_data = [];

    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart, 'beforeShowResetZoom');
  }

  handleChkChange(event) {
    this.setState({
      ...this.state,
      [event.target.id]: !this.state[event.target.id],
    });
  }

  formPPATableData = (tableData, indexData) => {
    const {
      curGran,
      exchangeRate,
      benchmark, 
      selectedIndex: indexName, 
      indexMultiplier, 
      fixedPrice, 
      serviceFee,
    } = this.state;
    
    const idxValue = indexData?.find(el => el.name === indexName)?.data.find( 
        el => el[0] === moment.utc(this.state.date).startOf('month').unix() * 1000
      )?.[1] || 0;

    const IBU = this.props.allData?.imbalance_prices.IBU;
    const IBD = this.props.allData?.imbalance_prices.IBD;
    const prodActual = this.props.allData?.generation;
    const forecast = this.props.allData?.base_volumes;
    const ibNaive = prodActual.map((prod, i) => (prod - forecast[i]) * (prod - forecast[i] > 0 ? IBU[i] : IBD[i]));
    const ibNaiveGran = resampleArrToGran(ibNaive, this.props.allData?.parameters.ib_dt, parseFloat(curGran));
    const da_prices = this.props.allData?.granDaPrices[0][this.props.allData.parameters.ib_dt];
    const total_samawatt = this.props.allData?.report.pl_samawatt && JSON.parse(this.props.allData.report.pl_samawatt);

    const convertToEUR = (num) => {
      return this.props.currencyType === 'EUR' && this.props.location.state.currency !== 'EUR' ? num / exchangeRate : num;
    } 

    const ppaDaData = prodActual.map((generation, i) => {
      if (benchmark === 'Naive approach') return convertToEUR(forecast[i] * da_prices[i]);

      const indexMultiplierValue = !isNaN(parseFloat(indexMultiplier)) ? parseFloat(indexMultiplier) : 0;
      const serviceFeeValue = !isNaN(parseFloat(serviceFee)) ? parseFloat(serviceFee) : 0;
      const indexValue = indexName === 'Day-Ahead price' ? da_prices[i] : idxValue;
      const ppaDaValue = generation * (benchmark === 'Index - Service fee'
        ? (indexValue || 0) * indexMultiplierValue - serviceFeeValue
        : fixedPrice);
      
      return convertToEUR(ppaDaValue);
    });
    const ppaTotalData = ppaDaData.map((daValue, i) => {
      return benchmark === 'Naive approach' ?  daValue + convertToEUR(ibNaive[i]) : daValue;
    });
    const ppaAddedValueData = total_samawatt.map((el, i) => convertToEUR(el) - ppaTotalData[i]);
    
    const ppaDaGranData = resampleArrToGran(ppaDaData, this.props.allData?.parameters.ib_dt, parseFloat(curGran));
    const ppaTotalGranData = resampleArrToGran(ppaTotalData, this.props.allData?.parameters.ib_dt, parseFloat(curGran));
    const ppaAddedValueGranData = resampleArrToGran(ppaAddedValueData, this.props.allData?.parameters.ib_dt, parseFloat(curGran));

    const newTableData = tableData.slice(0, -1).map((row, i) => {
      return [
        ...row.slice(0, 13), 
        window.numberWithCommas(ppaDaGranData[i].toFixed(2)), 
        window.numberWithCommas(convertToEUR(benchmark === 'Naive approach' ? ibNaiveGran[i] : 0).toFixed(2)), 
        window.numberWithCommas(ppaTotalGranData[i].toFixed(2)), 
        window.numberWithCommas(ppaAddedValueGranData[i].toFixed(2))
      ]
    });

    const newTableTotal = [
      ...tableData.at(-1).slice(0, -4), 
      window.numberWithCommas(ppaDaGranData.reduce((acc, v) => acc += v, 0).toFixed(2)),
      window.numberWithCommas(convertToEUR(benchmark === 'Naive approach' ? ibNaiveGran.reduce((acc, v) => acc += v, 0) : Number(0)).toFixed(2)),
      window.numberWithCommas(ppaTotalGranData.reduce((acc, v) => acc += v, 0).toFixed(2)),
      window.numberWithCommas(ppaAddedValueGranData.reduce((acc, v) => acc += v, 0).toFixed(2)),
    ];

    return [...newTableData, newTableTotal];
  }

  async getExchangeRate(date) {
    const startDate = moment(date).add(-7, 'days');
    const endDate = moment(date).endOf('day');
    const dates = helper.getDatesObj(startDate.format(DATE_FORMAT_DASH), endDate.format(DATE_FORMAT_DASH));
    let exchangeRates = {...dates};
    const rates = await getMarketData(
      this.props.location.state.currency !== 'JPY' ? this.props.location.state.marketId : 67, 
      `ecb.europa.eu/EUR${this.props.location.state.currency} rate`, 
      startDate, 
      endDate,
    );

    if (rates.data && rates.data.length) {
      rates.data[0].forEach((el, i) => {
        exchangeRates[moment(el[0]).format(DATE_FORMAT_DASH)] = el[1];
      })

      helper.fillUndefinedValues(exchangeRates);
    }

    return exchangeRates[date];
  }

  getIndexData = async (park, date, toState = true) => {
    if (park.market === 'Germany' || park.market === 'France') {
      const indexArr = [
        ...(park.market === 'Germany'
          ? [
              {
                name: 'Monatsmarktwerte wind onshore (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Wind Onshore',
                altVariable:
                  'Proxy-Monthly-Index/Wind Onshore Proxy Monthly Index',
              },
              {
                name: 'Monatsmarktwerte wind offshore (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Wind Offshore',
                altVariable:
                  'Proxy-Monthly-Index/Wind Offshore Proxy Monthly Index',
              },
              {
                name: 'Monatsmarktwerte wind solar (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Solar',
                altVariable: 'Proxy-Monthly-Index/Solar Proxy Monthly Index',
              },
            ]
          : [
              {
                name: 'M0 Solar (France)',
                market_id: 14,
                mainVariable: 'cre.fr/Monthly M0 for Solar Power',
                altVariable: 'Proxy-Monthly-Index/Solar Proxy Monthly Index',
              },
              {
                name: 'M0 Wind (France)',
                market_id: 14,
                mainVariable: 'cre.fr/Monthly M0 for Wind Power',
                altVariable: 'Proxy-Monthly-Index/Wind Proxy Monthly Index',
              },
            ]),
      ];
  
      const indexData = await Promise.all(
        indexArr.map(async ({name, market_id, mainVariable, altVariable}) => {
          const mainData = await getMarketData(
            market_id, 
            mainVariable, 
            moment(date).startOf('month'), 
            moment(date).add(1, 'day'), 
            this.props.conn.label, 
            null, 
            86400
          );
          const altData = await getMarketData(
            market_id, 
            altVariable, 
            moment(date).startOf('month'), 
            moment(date).add(1, 'day'), 
            this.props.conn.label, 
            null, 
            86400
          );
          
          const data = altData.data?.[0]?.map((el, index) => {
            return mainData.data[0]?.[index] || altData.data[0][index]
          }) || [];
          
          return { name: name, data: data }
        })
      );

      toState && this.setState({indexData: indexData});

      return indexData;
    }
  }

  getIndexData = async (park, date, toState = true) => {
    if (park.market === 'Germany' || park.market === 'France') {
      const indexArr = [
        ...(park.market === 'Germany'
          ? [
              {
                name: 'Monatsmarktwerte wind onshore (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Wind Onshore',
                altVariable:
                  'Proxy-Monthly-Index/Wind Onshore Proxy Monthly Index',
              },
              {
                name: 'Monatsmarktwerte wind offshore (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Wind Offshore',
                altVariable:
                  'Proxy-Monthly-Index/Wind Offshore Proxy Monthly Index',
              },
              {
                name: 'Monatsmarktwerte wind solar (Germany)',
                market_id: 19,
                mainVariable: 'netztransparenz/MW Solar',
                altVariable: 'Proxy-Monthly-Index/Solar Proxy Monthly Index',
              },
            ]
          : [
              {
                name: 'M0 Solar (France)',
                market_id: 14,
                mainVariable: 'cre.fr/Monthly M0 for Solar Power',
                altVariable: 'Proxy-Monthly-Index/Solar Proxy Monthly Index',
              },
              {
                name: 'M0 Wind (France)',
                market_id: 14,
                mainVariable: 'cre.fr/Monthly M0 for Wind Power',
                altVariable: 'Proxy-Monthly-Index/Wind Proxy Monthly Index',
              },
            ]),
      ];
  
      const indexData = await Promise.all(
        indexArr.map(async ({name, market_id, mainVariable, altVariable}) => {
          const mainData = await getMarketData(
            market_id, 
            mainVariable, 
            moment(date).startOf('month'), 
            moment(date).add(1, 'day'), 
            this.props.conn.label, 
            null, 
            86400
          );
          const altData = await getMarketData(
            market_id, 
            altVariable, 
            moment(date).startOf('month'), 
            moment(date).add(1, 'day'), 
            this.props.conn.label, 
            null, 
            86400
          );
          
          const data = altData.data?.[0]?.map((el, index) => {
            return mainData.data[0]?.[index] || altData.data[0][index]
          }) || [];
          
          return { name: name, data: data }
        })
      );

      toState && this.setState({indexData: indexData});

      return indexData;
    }
  }

  async componentDidMount() {
    this.props.get_plreport_data({
      'parkId': this.props.location.state.park.id,
      'date': this.state.date,
      'isSkipIntraday': this.props.location.state.isSkipIntraday,
      'forceReCalculate': this.state.forceReCalculate,
      'withoutCache': this.props.location.state.withoutCache,
    })
    const parkData = await getParkData(
      { API_NAME: this.props.conn.label, API_URL: this.props.conn.API_URL },
      this.props.location.state.park
    );
    
    let exchangeRate = null;
    if (this.props.location.state.currency !== 'EUR') {
      exchangeRate = await this.getExchangeRate(this.props.location.state.data[0])
    }

    this.getIndexData(this.props.location.state.park, this.state.date);
    
    this.setState({ parkData, exchangeRate });

    this.props.get_generation_data({
      windpark: this.props.location.state.park.id,
      dateFrom: moment(this.props.location.state.data[0]).add(-1, 'day').format(DATE_FORMAT),
      dateTo: moment(this.props.location.state.data[0]).format(DATE_FORMAT),
      isDownloadingByChunks: false,
    })
  }

  handleChangeGranularity = (event) => {
    this.setState({ curGran: event.target.value });
  };

  getCapacityData = async () => {
    const data = await getActualCapacity(
      this.props.report_productive, 
      this.props.location.state.park.id,
      moment.tz(`${this.state.date} 00:00`, 'UTC').add(-1,'day'),
      moment.tz(`${this.state.date} 24:00`, 'UTC'),
      this.props.conn.label,
    )

    this.setState({ capacity: data });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.conn.shutdownSignals && (
      this.props.report_productive !== prevProps.report_productive || this.state.date !== prevState.date
    )) {
      this.getCapacityData();
    }
    if (
      this.props.isSkipIntraday !== prevProps.isSkipIntraday ||
      this.props.parameters !== prevProps.parameters ||
      this.props.hourly !== prevProps.hourly ||
      this.state.parkData !== prevState.parkData ||
      this.state.curGran !== prevState.curGran ||
      this.props.currencyType !== prevProps.currencyType
    ) {
      if (
        this.props.parameters &&
        this.props.hourly &&
        this.state.parkData &&
        this.props.auctionsData &&
        this.props.idTradesData &&
        this.props.tableDataGran
      ) {
        let calcTableDetailsData = [];
        const tableDetailsDataGran = JSON.parse(
          JSON.stringify(this.props.tableDetailsDataGran)
        );
        if (Object.keys(tableDetailsDataGran).length) {
          Object.keys(tableDetailsDataGran).forEach((stage, stageInd) => {
            if (stageInd === 0)
              calcTableDetailsData =
                tableDetailsDataGran[stage][this.state.curGran];
            else
              tableDetailsDataGran[stage][this.state.curGran].forEach(
                (el, id) => {
                  el.forEach((el2, i) => {
                    if (i !== 0) calcTableDetailsData[id].push(el2);
                  });
                }
              );
          });
        }

        // Object with common data for all auctions for selected granularity
        let commonAuData = {};
        if (Object.keys(this.props.auctionsData).length) {
          commonAuData = JSON.parse(
            JSON.stringify(this.props.auctionsData[0][this.state.curGran])
          );
          for (
            let i = 1;
            i < Object.keys(this.props.auctionsData).length;
            i++
          ) {
            Object.keys(commonAuData).forEach((time, j) => {
              commonAuData[time] = commonAuData[time].concat(
                this.props.auctionsData[i][this.state.curGran][time]
              );
            });
          }
        }

        let commonData = {}; // union auction and ID data
        if (Object.keys(this.props.idTradesData).length) {
          const arrayOfEmptyStrings = Array(
            this.props.idTradesData[Object.keys(this.props.idTradesData)[0]]
              .length
          ).fill('');
          Object.keys(commonAuData).forEach((time, j) => {
            if (this.props.idTradesData[time])
              commonData[time] = commonAuData[time].concat(
                this.props.idTradesData[time]
              );
            else
              commonData[time] = commonAuData[time].concat(arrayOfEmptyStrings);
          });
        } else commonData = commonAuData;

        const tableDetailsData = Object.keys(commonData).map((time, id) => {
          const convertedData = commonData[time].map((el, i) => {
            if (i % 2 !== 0) {
              return  el &&
                this.props.currencyType === 'EUR' && 
                this.props.location.state.currency !== 'EUR' 
                  ? window.numberWithCommas((parseFloat(`${el}`.replaceAll(' ', '')) / this.state.exchangeRate).toFixed(2)) 
                  : typeof el === 'string' ? el : window.numberWithCommas((el.toFixed(2)))
            } else {
              return el
            }
          })
          return [time, ...convertedData];
        });

        const tableDetailsHead = [...this.props.tableDetailsHead];
        tableDetailsHead.forEach((head, id) => {
          if (Object.keys(this.props.auctionsData).length > 1) {
            if (id !== 0 && id <= Object.keys(this.props.auctionsData).length) {
              tableDetailsHead[id] = `${head} ${
                this.state.parkData.stages.optimization_job?.[id - 1][0]
              }`;
            }
          }
        });
        const {currency} = this.props.location.state;
        let tableHeadWithCurrency = this.props.tableHead.map((level) => {
          return level.map((head) => {
            const replaceHead = head.replace('currency', `${!this.props.currencyType || 
              this.props.currencyType === 'Local' 
              ? currency || 'EUR' 
              : 'EUR'}`);
            return replaceHead;
          })
        });
        let tableData = this.props.tableDataGran[this.state.curGran].map(row => 
          row.map((el, i) => {
            if (i > 5) {
              return el && 
                this.props.currencyType === 'EUR' &&
                this.props.location.state.currency !== 'EUR' 
                  ? window.numberWithCommas((parseFloat(el.replaceAll(' ', '')) / this.state.exchangeRate).toFixed(2)) 
                  : el
            } else {
              return el
            }
          })
        )
        
        if (this.state.isSimulationActive) {
          if (this.props.location.state.ppaCalcParams.benchmark === "Naive approach") {
            tableHeadWithCurrency = !tableHeadWithCurrency[0][4].includes('Naive') ? tableHeadWithCurrency.map(
              (el, i) => i === 0 ? [...el.slice(0, 4), el[4].replace('Base', 'Naive'), el.slice(-1)]  : [
                ...el.slice(0, 12), 
                ...el.slice(12, 15).map(el => el.replace(',', ' naive,')),
                el.slice(-1)
              ]
            ) : tableHeadWithCurrency
          }
          const indexData = this.state.indexData || this.props.location.state.indexData;
          tableData = this.formPPATableData(tableData, indexData);
        }

        this.setState({
          tableData: tableData,
          // add name of the auction if several auctions
          tableHead: tableHeadWithCurrency,
          // check if park have DAvsDA or other strategy
          tableDetailsData: tableDetailsData,
          tableDetailsHead: tableDetailsHead,
          allData: this.props.allData,
          hourly: this.props.hourly,
          parameters: this.props.parameters,
          loading: this.props.loading,
        });
      }
    }
    if (
      (
        this.props.conn.shutdownSignals &&
        this.props.allData && 
        this.state.capacity.length && 
        JSON.stringify(this.state.capacity) !== JSON.stringify(prevState.capacity)
      ) || (
        !this.props.conn.shutdownSignals &&
        this.props.allData &&
        this.props.tableDataGran !== prevProps.tableDataGran
      )
    ) {
      const ibDt = this.props.allData.parameters.ib_dt;
      const daDt = this.props.parameters.dt.length ? this.props.parameters.dt : [1]
      const uniqueDt = [...daDt, ibDt, 1].filter(
        (item, i, ar) => ar.indexOf(item) === i
      );
      
      let daOpenPosGran = {};
      let forecastingErrorGran = {};
      daOpenPosGran[ibDt] = [];
      forecastingErrorGran[ibDt] = [];

      const generation = this.props.allData.generation;
      const da_trades = this.props.allData.granDaVolumes[0][ibDt];
      const base_volumes = this.props.allData.base_volumes;
      const da_prices = this.props.allData.granDaPrices[0][ibDt];
      const ib_price_up = this.props.allData.imbalance_prices.IBU;
      const ib_price_down = this.props.allData.imbalance_prices.IBD;
      const capacity = this.props.conn.shutdownSignals ? this.state.capacity[0].data.filter(
        el => moment.utc(el[0]).tz('Europe/Berlin').format(DATE_FORMAT_DASH) === this.props.allData.parameters.date
      ) : []

      for (let i = 0; i < da_trades.length; i++) {
        const capacityIsZero = capacity.length && capacity[Math.floor(i * ibDt)][1] === 0 ? true : false
        const daOpenPos = da_trades[i] - generation[i] > 0
          ? (da_trades[i] - ((capacityIsZero ? 0 : base_volumes[i]))) *
            (da_prices[i] - ib_price_down[i])
          : (da_trades[i] - ((capacityIsZero ? 0 : base_volumes[i]))) *
            (da_prices[i] - ib_price_up[i]);
        const forecastingErr = da_trades[i] - generation[i] > 0
          ? ((capacityIsZero ? 0 : base_volumes[i]) - generation[i]) *
            (da_prices[i] - ib_price_down[i])
          : ((capacityIsZero ? 0 : base_volumes[i]) - generation[i]) *
            (da_prices[i] - ib_price_up[i]);
        daOpenPosGran[ibDt].push(daOpenPos);
        forecastingErrorGran[ibDt].push(forecastingErr);
      }
      
      uniqueDt.forEach(gran => {
        if (gran === ibDt) return;
        daOpenPosGran[gran] = resampleArrToGran(
          daOpenPosGran[ibDt],
          ibDt,
          gran
        );
        forecastingErrorGran[gran] = resampleArrToGran(
          forecastingErrorGran[ibDt],
          ibDt,
          gran
        );
      })
      
      this.setState({ daOpenPosGran, forecastingErrorGran });
    }
  }

  renderGraphs() {
    let da_dt = this.state.parameters.dt;
    let { ib_dt, id_dt } = this.state.parameters;
    if (da_dt.length === 0) da_dt = [1];
    if (id_dt.length === 0) id_dt = [1];

    const auctionsCount = JSON.parse(
      this.state.hourly.da_volumes_samawatt
    ).length;

    let da_volumes_samawatt = oneDimVol(
      JSON.parse(this.state.hourly.da_volumes_samawatt),
      da_dt,
      this.state.allData.imbalance_prices.IBU * ib_dt
    );
    if (!da_volumes_samawatt.length) {
      da_volumes_samawatt = Array(this.state.allData.imbalance_prices.IBU * ib_dt).fill(0);
    }
    let da_volumes_customer = resampleArrToHoursDim(
      JSON.parse(this.state.hourly.da_volumes_customer),
      ib_dt
    );

    let showFirstAuction = false;
    let hourMult = 1;
    if (
      da_volumes_samawatt.every((el) => el === 0) &&
      JSON.parse(this.state.hourly.da_volumes_samawatt)?.some(
        (arr) => !arr.every((el) => el[1] === 0)
      )
    ) {
      showFirstAuction = true;
      hourMult = Math.min(...da_dt);
    }

    let pl_samawatt = resampleArrToHoursDim(
      JSON.parse(this.state.hourly.pl_samawatt),
      ib_dt
    );
    let pl_customer = resampleArrToHoursDim(
      JSON.parse(this.state.hourly.pl_customer),
      ib_dt
    );
    let ib_volumes_samawatt = JSON.parse(this.state.hourly.ib_volumes_samawatt);
    let ib_volumes_customer = JSON.parse(this.state.hourly.ib_volumes_customer);

    let ts0 = moment(this.state.hourly.date).unix();

    let volumes1 = [];
    let volumes2 = [];
    let volumes3 = [];
    const daData = [];
    const volumesData = [];
    const allVolumes = [];
    const daColors = ['#73BCFE', '#A8D5FE', '#1B92FE', '#45A6FE', '#0180F2'];
    const idColors = ['#6BEB7E', '#B1F4BB', '#1BC134', '#32E34D', '#159929'];

    const makeGranVolumesSeries = (
      granVolumes,
      pushToAllVolumes,
      seriesName,
      color,
      type
    ) => {
      const volumesArr = [];
      for (let i = 0; i < granVolumes.length; i++) {
        volumesArr.push([
          parseInt(ts0 + i * 3600 * this.state.curGran) * 1000,
          granVolumes[i],
        ]);
      }

      if (pushToAllVolumes) allVolumes.push(volumesArr);
      volumesData.push(
        makeSeries(seriesName, volumesArr, {
          valueDecimals: 2,
          color: color,
          type: type,
        })
      );

      return volumesArr;
    };

    const daLoopCount = showFirstAuction ? auctionsCount - 1 : 0;
    for (let i = 0; i <= daLoopCount; i++) {
      if (showFirstAuction) {
        da_volumes_samawatt = JSON.parse(this.state.hourly.da_volumes_samawatt)[
          i
        ];
        da_volumes_customer = calcGranData(
          JSON.parse(this.state.hourly.da_volumes_customer),
          ib_dt,
          da_dt[i],
          'vol'
        );
      }
      if (da_volumes_samawatt.length === 0) {
        da_volumes_samawatt = Array(this.state.allData.imbalance_prices.IBU * ib_dt / da_dt[i]).fill(0);
      }
      volumes1 = [];
      volumes2 = [];
      volumes3 = [];
      for (let j = 0; j < da_volumes_samawatt.length; j++) {
        let v = da_volumes_samawatt[j];
        volumes1.push([
          parseInt(ts0 + j * (3600 * da_dt[i])) * 1000,
          Math.round(v * 1000.0) / 1000.0,
        ]);
      }

      for (let j = 0; j < da_volumes_customer.length; j++) {
        let v2 = da_volumes_customer[j];
        let v3 = volumes1[j]?.[1] - v2;

        volumes2.push([
          parseInt(ts0 + j * (3600 * hourMult)) * 1000,
          Math.round(v2 * 1000.0) / 1000.0,
        ]);
        volumes3.push([
          parseInt(ts0 + j * (3600 * hourMult)) * 1000,
          Math.round(v3 * 1000.0) / 1000.0,
        ]);
      }
      // it is could be that there is no AU stage
      let stageName = this.state.parameters.dt.length
        ? this.state.parkData?.stages.optimization_job?.[i][0]
        : 'AU';
      daData.push(
        makeSeries(
          !showFirstAuction ? 'Optimised' : `Optimised ${stageName ?? ''}`,
          volumes1,
          { valueDecimals: 2 }
        )
      );
      i === 0 &&
        daData.push(makeSeries('Base', volumes2, { valueDecimals: 2 }));
      i === 0 &&
        daData.push(makeSeries('Difference', volumes3, { valueDecimals: 2 }));

      const granDaVolumes = calcGranData(
        JSON.parse(this.state.hourly.da_volumes_samawatt)[i],
        da_dt[i],
        this.state.curGran,
        'vol'
      );
      this.state.parameters.dt.length &&
        makeGranVolumesSeries(granDaVolumes, true, `${stageName}`, daColors[i]);
    }
    this.da_data = daData;

    volumes1 = [];
    volumes2 = [];
    volumes3 = [];

    for (let j = 0; j < pl_samawatt.length; j++) {
      let v = this.props.currencyType === 'EUR' && this.props.location.state.currency !== 'EUR' 
        ? pl_samawatt[j] / this.state.exchangeRate 
        : pl_samawatt[j];

      volumes1.push([
        parseInt(ts0 + j * 3600) * 1000,
        // Divide to "da_dt" period for converting MWh to MW (energy to power).
        // To have equal values for DA, ID and IB charts (OP-975).
        Math.round(v * 1000.0) / 1000.0,
      ]);
    }

    for (let j = 0; j < pl_customer.length; j++) {
      let v2 = this.props.currencyType === 'EUR' && this.props.location.state.currency !== 'EUR' 
      ? pl_customer[j] / this.state.exchangeRate 
      : pl_customer[j];
      let v3 = volumes1[j][1] - v2;

      volumes2.push([
        parseInt(ts0 + j * 3600) * 1000,
        Math.round(v2 * 1000.0) / 1000.0,
      ]);

      volumes3.push([
        parseInt(ts0 + j * 3600) * 1000,
        Math.round(v3 * 1000.0) / 1000.0,
      ]);
    }

    this.pnl_data = [
      makeSeries('Optimised', volumes1, {
        valueDecimals: 2,
      }),
      makeSeries('Base', volumes2, {
        valueDecimals: 2,
      }),
      makeSeries('Difference', volumes3, { valueDecimals: 2 }),
    ];
    const id_volumes_samawatt = JSON.parse(this.state.hourly.id_volumes_samawatt);
    const idData = [];
    const idStages = this.state.parkData.stages.optimization_job?.filter(el => el[0].includes('ID'));
    if (idStages && idStages.length) {
      idStages.forEach((stageArr, i) => {
        const id_volumes = id_volumes_samawatt[i].map((value, i) => {
          return [
            parseInt(ts0 + i * 3600 * stageArr[2]) * 1000,
            ((value / stageArr[2]) * 1000) / 1000,
          ]
        });
        idData.push(makeSeries(`Optimised ${stageArr[0]}`, id_volumes, { valueDecimals: 2 }));
      });
    } else {
      idData.push(makeSeries('Optimised', [], { valueDecimals: 2 }));
    }

    const minDt = Math.min(...id_dt);
    const dtIndex = id_dt.findIndex(num => num === minDt);
    const id_volumes_base = id_volumes_samawatt[dtIndex]?.map((el, i) => [
      parseInt(ts0 + i * 3600 * minDt) * 1000,
      0
    ]);
    idData.push(makeSeries('Base', id_volumes_base, { valueDecimals: 2 }));
    this.id_data = idData;

    JSON.parse(this.state.hourly.id_volumes_samawatt).forEach((arr, index) => {
      const granIdVolumes = calcGranData(
        arr,
        id_dt[index],
        this.state.curGran,
        'vol'
      );
      // it is could be that there is no AU stage, then ID stage will be the first
      const idStageNumber =
        this.state.parameters.dt.length && volumesData.length
          ? volumesData.length
          : 0;
      let stageName =
        this.state.parkData?.stages.optimization_job?.[idStageNumber][0];
      makeGranVolumesSeries(
        granIdVolumes,
        true,
        `${stageName}`,
        idColors[index]
      );
    });

    volumes1 = [];
    volumes2 = [];

    for (let j = 0; j < ib_volumes_samawatt.length; j++) {
      let v = ib_volumes_samawatt[j];

      volumes1.push([
        parseInt(ts0 + j * 3600 * ib_dt) * 1000,
        Math.round((v / ib_dt) * 1000.0) / 1000.0,
      ]);
    }

    for (let j = 0; j < ib_volumes_customer.length; j++) {
      let v = ib_volumes_customer[j];

      volumes2.push([
        parseInt(ts0 + j * 3600 * ib_dt) * 1000,
        Math.round((v / ib_dt) * 1000.0) / 1000.0,
      ]);
    }
    this.ib_data = [
      makeSeries('Optimised', volumes1, { valueDecimals: 2 }),
      makeSeries('Base', volumes2, { valueDecimals: 2 }),
    ];

    const granIbVolumes = calcGranData(
      JSON.parse(this.state.hourly.ib_volumes_samawatt),
      ib_dt,
      this.state.curGran,
      'vol'
    );
    makeGranVolumesSeries(granIbVolumes, true, 'Imbalance volumes', '#C0C0C0');

    let volumesSum = [];
    for (let i = 0; i < allVolumes[0].length; i++) {
      volumesSum.push([
        parseInt(ts0 + i * 3600 * this.state.curGran) * 1000,
        0,
      ]);
    }
    allVolumes.forEach((volumesArr) => {
      const arr = volumesArr.map((el, i) => [
        volumesSum[i]?.[0],
        volumesSum[i]?.[1] + el[1],
      ]);
      volumesSum = arr;
    });
    volumesData.push(
      makeSeries('Traded volume', volumesSum, {
        valueDecimals: 2,
        type: 'spline',
        color: '#FFB000',
      })
    );

    const granGenVolumes = calcGranData(
      this.state.allData.generation,
      ib_dt,
      this.state.curGran,
      'vol'
    );
    makeGranVolumesSeries(
      granGenVolumes,
      false,
      'Actual production',
      '#808080',
      'spline'
    );

    this.vol_data = volumesData;

    const formBatteryArr = (data) => {
      let arr;
      if (!data) {
        let zeroArr = [];
        for (let i = 0; i < 96; i++) {
          zeroArr.push(0);
        }
        arr = zeroArr;
      } else {
        const { Pmax, beta } = this.state.parkData.optimization_job;
        const batteryCapacity = Number(Pmax) * Number(beta);
        const soc_detail = JSON.parse(this.state.allData.report.soc_detail);
        arr = soc_detail.map(el => batteryCapacity 
          ? (el / (this.state.curGran / 0.25)) * 100 / batteryCapacity 
          : 0
        );
      }

      const granBatteryVolumes = calcGranData(
        arr,
        0.25,
        this.state.curGran,
        'vol'
      );

      const dateArr = [];
      for (let i = 0; i < granBatteryVolumes.length; i++) {
        dateArr.push([
          parseInt(ts0 + i * 3600 * this.state.curGran) * 1000,
          granBatteryVolumes[i],
        ]);
      }

      return dateArr;
    };

    const batteryData = formBatteryArr(this.state.allData.report.soc_detail);

    this.battery_data = [
      makeSeries('State of Charge', batteryData, { valueDecimals: 2 }),
    ];
  }

  handleDateChange = async (event) => {
    const date = event.target.value;
    let exchangeRate = null;

    if (this.props.location.state.currency !== 'EUR') {
      exchangeRate = await this.getExchangeRate(date);
    }

    const indexData = await this.getIndexData(this.props.location.state.park, date, false);

    this.setState({ date: date, exchangeRate, curGran: '1', indexData });

    this.props.get_plreport_data({
      'parkId': this.props.location.state.park.id,
      'date': date,
      'isSkipIntraday': this.props.location.state.isSkipIntraday,
      'forceReCalculate': this.state.forceReCalculate,
      'withoutCache': this.props.location.state.withoutCache,
    });
  }

  handleNumericChange = (e, field) => {
    const newValue = e.target.value.replace(/[^0-9.-]/g, ""); 
    this.setState({[field]: newValue});
  }

  calcPPATableData = (remove) => {
    const { tableData, tableHead, benchmark, curGran, exchangeRate, indexData } = this.state;

    const getDefaultData = () => {
      return this.props.tableDataGran[curGran].map(row => 
        row.map((el, i) => {
          if (i > 5) {
            return el && 
              this.props.currencyType === 'EUR' &&
              this.props.location.state.currency !== 'EUR' 
                ? window.numberWithCommas((parseFloat(el.replaceAll(' ', '')) / exchangeRate).toFixed(2)) 
                : el
          } else {
            return el
          }
        })
      );
    }
    
    let newTableHead = tableHead[0][4].includes('Naive') 
      ? tableHead.map((el, i) => i === 0 ? [...el.slice(0, 4), el[4].replace('Naive', 'Base'), el.slice(-1)] : [
        ...el.slice(0, 12), 
        ...el.slice(12, 15).map(el => el.replace(' naive', '')),
        el.slice(-1)
      ])
      : tableHead
    
    if (benchmark === "Naive approach" && !remove) {
      newTableHead = !tableHead[0][4].includes('Naive') ? tableHead.map(
        (el, i) => i === 0 ? [...el.slice(0, 4), el[4].replace('Base', 'Naive'), el.slice(-1)]  : [
          ...el.slice(0, 12), 
          ...el.slice(12, 15).map(el => el.replace(',', ' naive,')),
          el.slice(-1)
        ]
      ) : tableHead;
    }

    this.setState({
      tableData: remove ? getDefaultData() : this.formPPATableData(tableData, indexData),
      tableHead: newTableHead,
      isSimulationActive: !remove
    });
  }

  render() {
    const { classes, tooltips } = this.props;
    const { currency, market } = this.props.location.state;
    if (this.state.hourly !== undefined) {
      this.renderGraphs();
    }

    const formatNumber = (num) => {
      return window.numberWithCommas(num.toFixed(2));
    };
    let prodSalesSum = 0;
    let daOpenPositionSum = 0;
    let daOpenPositionGranWithErrorSum = 0;
    let forecastingErrorSum = 0;
    let netPnlSum = 0;
    const tableData =
      this.props.tableDataGran &&
      this.state.capacity.length &&
      this.props.tableDataGran[this.state.curGran].length ===
        this.state.tableData.length
        ? this.state.tableData.map((dataArr, i) => {
            const convertedCapacity = this.props.conn.shutdownSignals ? this.state.capacity[0].data
              .map((el) => {
                return [
                  moment.utc(el[0]).tz('Europe/Berlin').format(`${DATE_FORMAT_DASH} HH:mm`),
                  el[1],
                ];
              })
              .filter((el) => el[0].includes(this.state.date) && el[1] === 0) : [];

            if (dataArr[0] !== 'Total') {
              const hour = `${dataArr[0].split(':')[0]}`;
              return convertedCapacity.length &&
                convertedCapacity.find(
                  (el) => el[0].split(' ')[1].split(':')[0] === hour
                )
                ? [...dataArr.slice(0, 2), '0.000', ...dataArr.slice(3)]
                : dataArr;
            } else {
              return dataArr;
            }
          })
        : this.state.tableData;
    const cashFlowTableData =
      this.props.daOpenPositionGran &&
      this.props.prodSalesGran &&
      this.state.daOpenPosGran &&
      this.state.forecastingErrorGran &&
      this.props.tableDataGran[this.state.curGran].length ===
        this.state.tableData.length
        ? tableData.map((dataArr, id) => {
            const convertToEUR = this.props.currencyType === 'EUR' && this.props.location.state.currency !== 'EUR';
            // Calulate Total row
            if (dataArr[0] === 'Total') { 
              return [
                ...dataArr.slice(0, 9),
                formatNumber(convertToEUR ? prodSalesSum / this.state.exchangeRate : prodSalesSum),
                ...[
                  formatNumber(convertToEUR ? daOpenPositionGranWithErrorSum / this.state.exchangeRate : daOpenPositionGranWithErrorSum),
                  formatNumber(convertToEUR ? forecastingErrorSum / this.state.exchangeRate : forecastingErrorSum),
                ],
                formatNumber(convertToEUR ? netPnlSum - (daOpenPositionSum + prodSalesSum) / this.state.exchangeRate : netPnlSum - (daOpenPositionSum + prodSalesSum)),
                formatNumber(netPnlSum),
                ...dataArr.slice(13),
              ];
            }

            const daOpenPos = this.props.daOpenPositionGran[this.state.curGran][id];
            const daOpenPosWithErr = this.state.daOpenPosGran[this.state.curGran][id];
            const forecastingErr = this.state.forecastingErrorGran[this.state.curGran][id];
            const prodSales = this.props.prodSalesGran[this.state.curGran][id];
            const netPnl = convertToEUR 
              ? parseFloat(this.props.tableDataGran[this.state.curGran][id][12].replaceAll(' ', '')) / this.state.exchangeRate
              : parseFloat(this.props.tableDataGran[this.state.curGran][id][12].replaceAll(' ', ''));
            const intraDayPnl = this.props.idPnlGran?.[this.state.curGran][id];

            daOpenPositionSum += daOpenPos;
            daOpenPositionGranWithErrorSum += daOpenPosWithErr;
            forecastingErrorSum += forecastingErr;
            prodSalesSum += prodSales;
            netPnlSum += netPnl;
            return [
              ...dataArr.slice(0, 9),
              formatNumber(convertToEUR ? prodSales / this.state.exchangeRate : prodSales),
              ...[
                formatNumber(convertToEUR ? daOpenPosWithErr / this.state.exchangeRate : daOpenPosWithErr), 
                formatNumber(convertToEUR ? forecastingErr / this.state.exchangeRate : forecastingErr)
              ],
              formatNumber(convertToEUR ? intraDayPnl / this.state.exchangeRate : intraDayPnl),
              formatNumber(netPnl),
              ...dataArr.slice(13),
            ];
          })
        : tableData.map((dataArr, id) => [
            ...dataArr.slice(0, 9),
            'NaN',
            'NaN',
            'NaN',
            'NaN',
            ...dataArr.slice(13),
          ]);

    const cashFlowTableHead = this.props.tableHead
      .map((headArr, id) => {
      if (id === 0) {
        return [...headArr.slice(0, 3), 'Net pnl, currency', ...headArr.slice(4)];
      } else {
        return [
          ...headArr.slice(0, 8),
          'Production sales, currency',
          ...['DA open position, currency', 'Forecasting error, currency'],
          'Intraday pnl, currency',
          'Total Pnl, currency',
          ...headArr.slice(12),
        ];
      }
    })
      .map((level) => {
        return level.map((head) => {
          const replaceHead = head.replace('currency', `${!this.props.currencyType || this.props.currencyType === 'Local' ? currency || 'EUR' : 'EUR'}`);
          return replaceHead;
        })
      });

    let activePark = this.props.conn.parks.filter(
      (p) => p.id === this.props.location.state.park.id
    );
    activePark = activePark.length ? activePark[0] : null;
    const parkName = activePark?.name ?? '';

    const currencyOptions = [
      {title: `${market?.currency ?? 'EUR'}`, value: 'Local'},
      ...(market && market.currency !== 'EUR' ? [{title: 'EUR', value: 'EUR'}] : []),
    ];

    const pnl_options = {
      ...stocksChartOptions({ filename: `P&L Optimised vs Base ${parkName}` }),
      yAxis: [
        {
          title: {
            text: `Difference, ${!this.props.currencyType || this.props.currencyType === 'Local' ? currency : 'EUR' || ''}`,
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: this.pnl_data,
    };

    const da_options = {
      ...stocksChartOptions({ filename: `Day-ahead volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'Difference, MW',
          },
          opposite: true,
        },
      ],
      ...xAxisInUTC,
      series: this.da_data,
    };

    const id_options = {
      ...stocksChartOptions({ filename: `Intraday volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'Power, MW',
          },
        },
      ],
      ...xAxisInUTC,
      series: this.id_data,
    };
    const ib_options = {
      ...stocksChartOptions({ filename: `Imbalance volumes ${parkName}` }),
      yAxis: [
        {
          title: {
            text: 'Power, MW',
          },
        },
      ],
      ...xAxisInUTC,
      series: this.ib_data,
    };
    const column_options = {
      ...stocksChartOptions({ filename: `Imbalance volumes ${parkName}` }),
      chart: {
        type: 'column',
      },
      yAxis: [
        {
          title: {
            text: 'Power, MW',
          },
        },
      ],
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        borderColor: '#2c3e50',
        shared: true,
        formatter: function (tooltip) {
          return tooltip.bodyFormatter(this.points).join('');
        },
      },
      ...xAxisInUTC,
      series: [...this.vol_data],
    };

    const renderBatterySoC = () => {
      const options = {
        ...stocksChartOptions({ filename: `Battery SoC` }),
        yAxis: [
          {
            title: {
              text: 'Charge, %',
            },
          },
        ],
        ...xAxisInUTC,
        series: [...this.battery_data]
      }

      return (
        <Grid item xs={12}>
          <Card className={classes.bgColorAlt}>
            <CardHeader>
              <h4
                className={classNames(
                  classes.cardTitleWhite,
                  classes.textColor
                )}
              >
                State of Charge
              </h4>
            </CardHeader>
            <CardBody className={classes.bgColorAlt}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={options}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      );
    };

    if (this.props?.isLoggedIn) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.sticky}>
            <Card className={!this.state.isOpen ? classes.hidden : ''}>
              <CardHeader
                color="primary"
                className={!this.state.isOpen ? classes.visible : ''}
              >
                <Link
                  to={{
                    pathname: this.props.location.state.battery
                      ? '/battery_trading_report'
                      : '/profitlossperiod',
                  }}
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '10px',
                    zIndex: '10',
                  }}
                >
                  <IconButton
                    title={
                      this.props.location.state.battery
                        ? 'Back to Battery Report'
                        : 'Back to P&L Period'
                    }
                  >
                    <ArrowBackIcon htmlColor="#fff" />
                  </IconButton>
                </Link>
                <h4
                  className={classes.cardTitleWhite}
                  style={{ position: 'relative' }}
                >
                  Energy Delivery Day, {parkName}
                  <IconButton
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      display: 'inline-flex',
                      position: 'absolute',
                      right: '0',
                    }}
                    onClick={() =>
                      this.setState({ isOpen: !this.state.isOpen })
                    }
                  >
                    {this.state.isOpen ? (
                      <KeyboardArrowUpIcon style={{ fill: 'white' }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fill: 'white' }} />
                    )}
                  </IconButton>
                </h4>
              </CardHeader>
              <CardBody
                style={!this.state.isOpen ? { display: 'none' } : undefined}
              >
                <Grid container spacing={4} justifyContent="center">
                  <Grid item sm={12} md={12} lg={4} xl={6} className={classes.flex}>
                    <form className={classes.container} noValidate>
                      <TextField
                        id="date"
                        label="Date"
                        type="date"
                        defaultValue={this.state.date}
                        className={classNames(classes.textField)}
                        onChange={this.handleDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          className: classes.input
                        }}
                      />
                    </form>
                    <DownloadButton
                      data={this.state.tableData}
                      tables={document.getElementsByTagName('table')}
                      filename={`PNL_Daily_Report_${parkName}_${this.state.allData?.parameters.date}`}
                      className={classes.spacing}
                      tooltip={tooltips?.download_button || 'Download daily report'}
                      helpModeActive={this.props.helpModeActive}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={8} xl={6} className={classes.flex}>
                    <FormControl
                      className={classNames(
                        classes.formControl,
                      )}
                      style={{ marginRight: '15px', width: '150px' }}
                    >
                      <ParkAutocomplete
                        id="currency"
                        label="Select currency:"
                        options={currencyOptions}
                        value={currencyOptions.find(el => currencyOptions.length === 1 
                          ? el.value === 'Local' 
                          : el.value === (this.props.currencyType ?? 'Local'))
                        }
                        getOptionLabel={(option) => option.title}
                        onChange={(e, v) => this.props.set_currency_type(v.value)}
                        limitTags={1}
                        disableClearable
                      />
                    </FormControl>
                    <FormControlLabel
                      control={
                        <CustomTooltip 
                          title='Ensures data is freshly fetched rather than retrieved from cache'
                          disableFocusListener={!this.props.helpModeActive}
                          disableHoverListener={!this.props.helpModeActive}
                          disableTouchListener={!this.props.helpModeActive}
                        >
                          <Checkbox
                            color="primary"
                            id="forceReCalculate"
                            onChange={this.handleChkChange}
                            checked={this.state.forceReCalculate}
                            value="forceReCalculate"
                          />
                        </CustomTooltip>
                      }
                      label="Force Recalculate"
                    />
                    <FormControlLabel
                      control={
                        <CustomTooltip 
                          title='Enables functionality for detailed insights into net profit/loss in tables'
                          disableFocusListener={!this.props.helpModeActive}
                          disableHoverListener={!this.props.helpModeActive}
                          disableTouchListener={!this.props.helpModeActive}
                        >
                          <Checkbox
                            color="primary"
                            id="cashFlow"
                            onChange={this.handleChkChange}
                            checked={this.state.cashFlow}
                            value="cashFlow"
                          />
                        </CustomTooltip>
                      }
                      label="Net pnl"
                    />
                    <FormControlLabel
                      control={
                        <CustomTooltip 
                          title='Defines if the ppa calculation simulation is active'
                          disableFocusListener={!this.props.helpModeActive}
                          disableHoverListener={!this.props.helpModeActive}
                          disableTouchListener={!this.props.helpModeActive}
                        >
                          <Checkbox
                            color="primary"
                            id="isSimulationActive"
                            onChange={(e) => this.calcPPATableData(!e.target.checked)}
                            checked={this.state.isSimulationActive}
                            disabled={!this.props.location.state?.ppaCalcParams}
                            value="isSimulationActive"
                          />
                        </CustomTooltip>
                      }
                      label="Simulation is active"
                    />
                    {this.props.tableDetailsDataGran &&
                    Object.entries(this.props.tableDetailsDataGran).length &&
                    Object.keys(
                      Object.entries(this.props.tableDetailsDataGran)?.[0]?.[1]
                    ).length > 1 ? (
                      <CustomTooltip 
                        title={tooltips?.granularity ? `${tooltips?.granularity}` : (
                          <div>
                            <div>{'Data Frequency. Defines the time interval between data points:'}</div>
                            <ul style={{margin: '0', paddingLeft: '15px'}}>
                              <li>{'1 → Hourly intervals (1 hour);'}</li>
                              <li>{'0.5 → Every 30 minutes;'}</li>
                              <li>{'0.25 → Every 15 minutes.'}</li>
                            </ul>
                            <div>{'This allows for data analysis with varying levels of detail'}</div>
                          </div>
                        )}
                        disableFocusListener={!this.props.helpModeActive}
                        disableHoverListener={!this.props.helpModeActive}
                        disableTouchListener={!this.props.helpModeActive}
                      >
                        <FormControl className={classNames(classes.fullWidth)}>
                          <InputLabel
                            className={this.props.classes.selectLabel}
                            htmlFor="mode"
                          >
                            Granularity
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={this.state.curGran}
                            onChange={this.handleChangeGranularity}
                            autoWidth
                            label="Age"
                          >
                            {Object.keys(
                              Object.entries(
                                this.props.tableDetailsDataGran
                              )?.[0]?.[1]
                            ).map((el, id) => {
                              return (
                                <MenuItem key={id} value={el}>
                                  {el}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </CustomTooltip>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {this.state.tableDetailsData?.length > 0 ? (
              <Card>
                <CardBody>
                  <ProfitLossReportDetails
                    tableHead={this.state.tableDetailsHead}
                    tableData={this.state.tableDetailsData}
                    winHeight={window.innerHeight}
                    currency={!this.props.currencyType || this.props.currencyType === 'Local' ? currency : 'EUR'}
                  />
                </CardBody>
              </Card>
            ) : this.state.loading ? (
              <div className="loader" alt="Loading report..." />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {this.state.tableData?.length > 0 ? (
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    Summary, {parkName}
                  </h4>
                </CardHeader>

                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                          <ParkAutocomplete
                            id="benchmark"
                            label="Benchmark"
                            options={["Fixed price", "Index - Service fee", "Naive approach"]}
                            value={this.state.benchmark}
                            getOptionLabel={(option) => option}
                            onChange={(e, v) => this.setState({benchmark: v})}
                            limitTags={1}
                            disableClearable
                          />
                        </Grid>
                        {this.state.benchmark === 'Index - Service fee' && (
                          <>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                              <ParkAutocomplete
                                id="index"
                                label="INDEX"
                                options={this.state.indexOptions}
                                value={this.state.selectedIndex}
                                getOptionLabel={(option) => option}
                                onChange={(e, v) => this.setState({selectedIndex: v})}
                                limitTags={1}
                                disableClearable
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                              <TextField 
                                id='indexMultiplier'
                                type="text" 
                                label="Index multiplier"
                                value={this.state.indexMultiplier} 
                                onChange={(e) => this.handleNumericChange(e, 'indexMultiplier')}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                              <TextField 
                                id="serviceFee"
                                type="text" 
                                label={`Service fee (${
                                  !this.props.currencyType || this.props.currencyType === 'Local' ? currency : 'EUR'
                                }/MWh)`}
                                value={this.state.serviceFee} 
                                onChange={(e) => this.handleNumericChange(e, 'serviceFee')}
                                fullWidth
                              />
                            </Grid>
                          </>
                        )}
                        {this.state.benchmark === 'Fixed price' && (
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                            <TextField 
                              id="fixedPrice"
                              type="text" 
                              label={`Fixed price (${
                                !this.props.currencyType || this.props.currencyType === 'Local' ? currency : 'EUR'
                              }/MWh)`}
                              value={this.state.fixedPrice} 
                              onChange={(e) => this.handleNumericChange(e, 'fixedPrice')}
                              fullWidth
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={8} lg={6} xl={4} className={classes.fromStart}>
                          <CustomButton
                            className={classes.spacing}
                            color="primary"
                            round
                            onClick={() => this.calcPPATableData()}
                            tooltip={tooltips?.build_button || ''}
                            helpModeActive={this.props.helpModeActive}
                          >
                            Calculate Added value
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <ProfitLossReportSummary
                        tableHead={
                          this.state.cashFlow
                            ? cashFlowTableHead
                            : this.state.tableHead
                        }
                        tableData={
                          this.state.cashFlow
                            ? cashFlowTableData
                            : tableData
                        }
                        cashFlow={this.state.cashFlow}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            ) : null}
          </Grid>
          {this.props.location.state.battery && renderBatterySoC()}
          {this.state.tableData?.length > 0 ? (
            <Grid item xs={12}>
              <Card className={classes.bgColor}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleWhite,
                      classes.textColor
                    )}
                  >
                    Total submitted energy
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColor}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={column_options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ) : null}

          {this.state.tableData?.length > 0 ? (
            <Grid item xs={12}>
              <Card className={classes.bgColor}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleWhite,
                      classes.textColor
                    )}
                  >
                    P&L Optimised vs Base
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColor}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={pnl_options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ) : null}
          {this.state.tableData?.length > 0 ? (
            <Grid item xs={12}>
              <Card className={classes.bgColor}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleWhite,
                      classes.textColor
                    )}
                  >
                    Day-ahead volumes
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColor}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={da_options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ) : null}
          {this.state.tableData?.length > 0 ? (
            <Grid item xs={12}>
              <Card className={classes.bgColor}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleWhite,
                      classes.textColor
                    )}
                  >
                    Intraday volumes
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColor}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={id_options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ) : null}
          {this.state.tableData?.length > 0 ? (
            <Grid item xs={12}>
              <Card className={classes.bgColor}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleWhite,
                      classes.textColor
                    )}
                  >
                    Imbalance volumes
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColor}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={ib_options}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      );
    } else {
      return <LoginPage />;
    }
  }
}

const Report = connect(mapStateToProps, mapDispatchToProps)(ProfitLossReport);
export default withStyles(styles)(Report);
