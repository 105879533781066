// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import LoginPage from 'views/Login/Oops.jsx';
import ModelName from "views/Models/ModelName.jsx";
import {
  get_optimisation_data, 
  get_metrics_data, 
  get_generation_data, 
  get_markets_data,
} from 'actions/index';
import IntraDayNomExecReport from 'components/Reports/IntraDayNomExecReport';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

import 'moment-timezone';
import moment from 'moment';

import { getTradesData } from 'utils/getDataMethods';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';

import * as helper from './OptimisationLogHelper';
import { calcGranData } from 'utils/calcFunctions';
import { buildAPIRequest, security_fetch_params } from 'actions';

const mapStateToProps = (state) => {
  return {
    loading: state.optimisation.loading,
    isLoggedIn: state.login.loggedIn,
    optimisation: state.optimisation.result,
    parkModels: state.dictionaries.parkModels.parkModels,
    loadingModels: state.dictionaries.parkModels.parkModels.loading,
    metricsData: state.metrics.prices,
    loadingMetrics: state.metrics.loading,
    conn: state.conn,
    generation: state.generation,
    markets: state.markets.markets,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_optimisation_data: (data) => dispatch(get_optimisation_data(data)),
  get_metrics_data: (data) => dispatch(get_metrics_data(data)),
  get_generation_data: data => dispatch(get_generation_data(data)),
  getMarkets: () => dispatch(get_markets_data()),
});



class OptimisationLog extends React.Component {
  constructor(props) {
    super(props);
    HighchartsExporting(Highcharts);
    HighchartsExportingData(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');
    this.state = {
      deliveryDayTrades: null,
      posNegType: ['FR', 'CH', 'NL', 'RO'],
      metricsData: [],
      startLoadingSecPartMarketData: false,
      locationState: null,
      currency: '',
      daOptsData: [],
    };
  }


  getData (locationState) {
    this.props.get_optimisation_data({
      id: locationState.currentData.id,
    });
    getTradesData(
      locationState.currentData.date.replaceAll('-', ''),
      locationState.park,
      { API_URL: this.props.conn.API_URL, API_NAME: this.props.conn.label},
      true
    ).then((data) => {
      if (data) {
        this.setState({
          deliveryDayTrades: data,
        })
      }
    })

    const dateFrom = moment(locationState.currentData.date).format('YYYYMMDD');
    const dateTo = moment(locationState.currentData.date).add(1, 'day').format('YYYYMMDD');

    // Get generation data
    this.props.get_generation_data({
      windpark: locationState.park.id,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });

    this.setState({metricsData: []});
  }

  async getOptimisationsData (arr) {
    const allOpts = await Promise.all(arr.map(
      async opt => {
        const [url, headers] = buildAPIRequest('/api/reports/optimisation/' + opt.id);
        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
          ...security_fetch_params
        });
        if (response.ok) {
          const data = await response.json();
          return data.result;
        }
      }
    ));
    this.setState({ daOptsData: allOpts });
  }

  componentDidUpdate(prevProps, prevState) {
    let locationState = this.props.location.state;
    if (!locationState)
      locationState = this.state.locationState;

    if (prevProps.location.state.currentData.id && prevProps.location.state.currentData.id !== locationState.currentData.id) {
      this.getData(locationState);
      const index = this.props.location.state.optimisations.findIndex(opt => opt.id === this.props.location.state.currentData.id);
      if (!this.props.location.state.optimisations[index].afternoon_run) {
        const optsArr = this.props.location.state.optimisations
        .filter((opt, i) => 
          i > index && 
          opt.date === this.props.location.state.currentData.date &&
          opt.afternoon_run === this.props.location.state.optimisations[index].afternoon_run
        );
        this.getOptimisationsData(optsArr);
      }
    }
    
    if (this.props.markets !== prevProps.markets) {
      let market = this.props.markets.filter(el => el.name === this.props.location.state.park.market.name);
      const currency = market && market.length ? market[0].currency : 'EUR';
      this.setState({currency});
    }
  }
 
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getMarkets();
      let locationState = this.props.location.state;
      if (locationState)
        sessionStorage.setItem('curDataForOpt', JSON.stringify(this.props.location.state));
      else {
        locationState = JSON.parse(sessionStorage.getItem('curDataForOpt'));
        this.setState({locationState: locationState})
      }
      this.getData(locationState);
      const index = this.props.location.state.optimisations.findIndex(opt => opt.id === this.props.location.state.currentData.id);
      if (!this.props.location.state.optimisations[index].afternoon_run) {
        const optsArr = this.props.location.state.optimisations
        .filter((opt, i) => 
          i > index && 
          opt.date === this.props.location.state.currentData.date && 
          opt.afternoon_run === this.props.location.state.optimisations[index].afternoon_run
        );
        this.getOptimisationsData(optsArr);
      }
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return <LoginPage />;
    };
    const loadingBlock = (
      <div className="loader" alt="Loading report..."/>
    );
    let locationState = this.props.location.state;
    if (!locationState)
      locationState = this.state.locationState;

    if (locationState) {
      const { classes} = this.props;
      const { result } = this.props.optimisation;
      const deliveryDayTrades = this.state.deliveryDayTrades;
      const enteryOver = locationState.park.optimization_job.entry_over;
      const optimizTimestamp = Number(
        moment
          .tz(locationState.currentData.optTime, 'UTC')
          .format('x')
      );
      const {optimisations} = locationState;
      const index = optimisations.findIndex(opt => opt.id === locationState.currentData.id);
      const prevOpt = optimisations[index > 0 ? index - 1 : index]
      const nextOpt = optimisations[index + 1]

      if (this.props.optimisation.result.computing_finish === undefined) {
        return <div />;
      } else {
        const datesAr = result.dates;
        const optimisationDt = result.parameters.dt;
        const fullStageNumber = result.parameters.schedule_entry;
        const afternoonRun = result.parameters.afternoon_run;
        let ibDt = null;
        let paData = []; //ID nomination summary data
        let pnData = [];
        let pfData = [];
        let forceType = '';
        let intraDayPricesLimits = [];
        let intraDayPricesLambda = [];
        let intraDayFprices = [];
        let daConfirmationData = []; //DA confirmation
        let daDifferenceForecast = []; //DA Nom - DA Forecast
        let daDifferenceActual = []; //DA Nom - DA Actual
        let imbData = []; //IMB volumes
        let imbGranData = [];
        let daPricesData = [];
        let imbDPricesData = [];
        let imbDPricesGranData = [];
        let imbUPricesData = [];
        let imbUPricesGranData = [];
        let imbPricesData = [];

        // for metrics "ib - da" or "da - da" prices
        let substractedPrices = []
        let legend = 'IB-DA prices'

        let intraDayVolExec = []; // data of all ID vol for this day
        let intraDayOrders = {};
        const idAllTrades = [];
        // arr of arr of full trade data: [0] - id nom time, [1] -delivery time,
        // [2] - vol nom, [3] - price nom, [4] - executed vol,
        // [5] - executed price
        let allIdTradeData = [];

        let isIntraDayTradingDay = false;
        let isIBData = true;
        let isForced = false;
        let isWithGeneration = false;

        let modelsWeightsString = '';
        const modelsWeights = result?.input.ensemble_details?.weights ?? {};

        // Calculate nuber of auction or continuous stage
        let stageNumber = 0;
        const auStages = [];
        const contStages = [];
        if (enteryOver) {
          Object.keys(enteryOver).forEach((key) => {
            if (enteryOver[key].afternoon_run)
              contStages.push(Number(key));
            else
              auStages.push(Number(key));
          });
          if (!afternoonRun) {
            stageNumber = auStages.indexOf(fullStageNumber)
          } else {
            stageNumber = contStages.indexOf(fullStageNumber)
          }
        }

        const keys = Object.keys(modelsWeights);
        if (keys.length) {
          modelsWeightsString = (
            <>
              <br />
              {keys.map(
                (key, i) =>
                  `Model ${i} = ${(modelsWeights[key] * 100).toFixed(0)}% `
              )}
            </>
          );
        }

        const tableHeader = [
          'Time of nomination',
          'Time of delivery',
          'Nominated Volume',
          'Bid',
          'Order type',
          'Executed Volume',
          'Executed Price',
        ];

        if (result && !result.error) {
          forceType = result.parameters['forced_type'];
          if (typeof forceType === 'number') {
            forceType = 'GTD';
          }
          pnData = [...result['Pn']];
          if (result['FPrices']) {
            paData = [...result['Pa']];
            pfData = [...result['Pf']];
            intraDayPricesLambda = [...result.input['lambdaA']];
            intraDayFprices = [...result['FPrices']];
            intraDayPricesLimits = [...result['Prices'][0]];
            intraDayOrders = { ...result['orders'] };

            isForced =
              pnData.length === pfData.length &&
              pnData.every((element, i) => element === pfData[i]);
          }
          if (result.parameters['fixed_generation'] === null){
            isWithGeneration = true;
            // If park have generation then calculate DA nom - DA forecast and DA not - DA actual
            if (this.props.generation?.power?.length && this.props.generation?.forecast?.length) {
              let power = this.props.generation.power.map((el) => Number(el[1].toFixed(3)));
              let forecast =  this.props.generation.forecast.map((el) => Number(el[1].toFixed(3)));
              
              if (pnData.length !== power.length || paData.length !== forecast.length) {
                const gran = (pnData.length === 24 || pnData.length === 23 || pnData.length === 25) 
                  ? 1 
                  : (pnData.length === 48 || pnData.length === 46 || pnData.length === 50) 
                    ? 0.5 
                    : 0.25;
                const curGran = (power.length === 24 || power.length === 23 || power.length === 25) 
                  ? 1 
                  : (power.length === 48 || power.length === 46 || power.length === 50) 
                    ? 0.5 
                    : 0.25;
                
                power = calcGranData(power, curGran, gran, 'price');
                forecast = calcGranData(forecast, curGran, gran, 'price');
              }
              daDifferenceActual = pnData.map ((el, i) => el - power[i]);
              daDifferenceForecast = pnData.map ((el, i) => el - forecast[i]);
            };
          };
        }

        //----Nomination data
        isIntraDayTradingDay = paData.some((vol) => vol !== 0);
        const nomIntraDayData = [];
        //If data of nominated ID is simulated
        if (Object.entries(intraDayOrders).length) {
          for (const n in intraDayOrders) {
            let sumVol = 0;
            let sumCashflow = 0;
            let nomTime = 0;
            intraDayOrders[n].forEach((el) => {
              sumVol = sumVol + el['qty'];
              sumCashflow = sumCashflow + el['qty'] * el['price'];
              nomTime = el['start'];
            });
            nomIntraDayData.push([
              parseInt(n),
              nomTime,
              sumVol,
              (sumCashflow / sumVol).toFixed(2),
            ]);
          }
        } else if (intraDayFprices.length > 0) {
          //If data of nominated ID is on EPEX
          //force type is always GTD on the real parks
          forceType = 'GTD';
          pnData.forEach((vol, i) => {
            if (pnData[i] !== 0.0) {
              //if vol > 0 then we sell and Fprice with sign minus or invert
              //if orders forced we calculate lambdaA +(-) FPrices,
              //if not use Prices but it is Price initial limit
              nomIntraDayData.push([
                parseInt(datesAr[i]),
                null,
                vol,
                isForced
                  ? (
                    intraDayPricesLambda[i] +
                    intraDayFprices[i] * (vol > 0 ? -1 : 1)
                  ).toFixed(2)
                  : intraDayPricesLimits[i],
              ]);
            }
          });
        }

        // Get data of trades
        if (deliveryDayTrades && !deliveryDayTrades?.error) {
          const dateDelivery = [...deliveryDayTrades.parameters['date']].join(
            ''
          );
          ibDt = deliveryDayTrades.parameters['ib_dt'];
          //start of the day in timestamp in UTC
          const dateStart = Number(
            moment.tz(dateDelivery, 'Europe/Berlin').valueOf()
          );
          const timeOfOrdersToFind =
            (optimizTimestamp - dateStart) / (60 * 1000);

          daConfirmationData = deliveryDayTrades['da_trades'].length ? deliveryDayTrades['da_trades'][stageNumber] : [];
          imbData = JSON.parse(deliveryDayTrades.report['ib_volumes_samawatt']);
          imbDPricesData = [...deliveryDayTrades.imbalance_prices['IBD']];
          imbUPricesData = [...deliveryDayTrades.imbalance_prices['IBU']];

          imbData.forEach((vol, id) => {
            let value = null;
            if (vol > 0) {
              value = imbUPricesData[id];
            }
            if (vol < 0) {
              value = imbDPricesData[id];
            }
            if (imbUPricesData[id] === imbDPricesData[id])
              value = imbUPricesData[id];
            imbPricesData.push(value);
          });

          daPricesData = deliveryDayTrades['da_prices'].length ? deliveryDayTrades['da_prices'][stageNumber] : [];

          imbGranData = calcGranData(
            imbData,
            ibDt,
            optimisationDt,
            'vol'
          );

          imbUPricesGranData = calcGranData(
            imbUPricesData,
            ibDt,
            optimisationDt,
            'price'
          );

          imbDPricesGranData = calcGranData(
            imbDPricesData,
            ibDt,
            optimisationDt,
            'price'
          );

          //---- Executed data. ID trades [executed time, delivery time,
          // executed volum, executed price]

          intraDayVolExec =  
            JSON.parse(deliveryDayTrades.report['id_volumes_samawatt']).length ? 
            JSON.parse(deliveryDayTrades.report['id_volumes_samawatt'])[stageNumber] : [];
          // All executed orders
          const intraDayAllTrades = 
            deliveryDayTrades['id_trades'].length 
            ? deliveryDayTrades['id_trades'][stageNumber] : [];
          // Only those orders that is in this optimisation time
          const execOrders = intraDayAllTrades.filter(
            (el) => el[0] === timeOfOrdersToFind
          );

          // May be the situations when there is several executed orders
          // for one delivery time. There is need to summarize volumes and
          // cashflows of this orders
          // In the empty object collect keys which are unic delivery timestamps,
          // in each key there is array with sum vol and cashflow
          // trade[1] - delivery time, trade[2] - vol, trade[3] - cashflow

          const unique = {};
          for (const trade of execOrders) {
            const key = trade[1];
            const volume = trade[2];
            const cashflow = trade[3];
            if (!unique.hasOwnProperty(key)) {
              unique[key] = { key, volume, cashflow };
            } else {
              unique[key].volume += volume;
              unique[key].cashflow += cashflow;
            }
          }
          for (const k in unique) {
            const obj = unique[k];
            idAllTrades.push([
              obj.key * 60 * 60 * 1000 + dateStart,
              obj.volume,
              Number((obj.cashflow / obj.volume).toFixed(2)),
            ]);
          }
          //Prepare comon data of Nomination and IntraDay in one array
          const prepAllData = [];
          if (nomIntraDayData.length) {
            nomIntraDayData.forEach((el) => {
              //Take only that executed orders that fit to nominated timestamps
              const idTradesThisPeriod = idAllTrades.filter(
                (execOrd) => execOrd[0] === el[0]
              );

              //If there is no executed orders for nominations then zero data
              if (idTradesThisPeriod.length > 0) {
                prepAllData.push([...el, ...idTradesThisPeriod[0]]);
              } else {
                prepAllData.push([...el, 0, 0, '-']);
              }
              allIdTradeData = prepAllData.map((el) => [
                el[1] !== null
                  ? moment(el[1]).tz('UTC').format('MMM DD HH:mm') + ' UTC'
                  : moment(optimizTimestamp).tz('UTC').format('MMM DD HH:mm') +
                  ' UTC',
                moment(el[0]).tz('UTC').format('MMM DD HH:mm') + ' UTC',
                el[2],
                el[3],
                forceType,
                el[5],
                el[6],
              ]);
            });
          };
        } else if (deliveryDayTrades?.error.includes('There is no data for report on')) {
          if (nomIntraDayData.length) {
            allIdTradeData = nomIntraDayData.map(el => [
              el[1] !== null
                ? moment(el[1]).tz('UTC').format('MMM DD HH:mm') + ' UTC'
                : moment(optimizTimestamp).tz('UTC').format('MMM DD HH:mm') +
                ' UTC',
              moment(el[0]).tz('UTC').format('MMM DD HH:mm') + ' UTC',
              el[2],
              el[3],
              forceType,
              'not available yet',
              'not available yet',
            ])
          }
        }
        
        // Calculate IB - DA or DA - DA 
        
        if (deliveryDayTrades && deliveryDayTrades['da_prices']?.length === 2) {
          const [otherDaPrices, granValue] = deliveryDayTrades['da_prices']
            .map((arr, index) => index !== stageNumber ? [arr, deliveryDayTrades.parameters.dt[index]] : null)
            .filter(el => el)
            .flat();
          const otherDaPricesGran = calcGranData(otherDaPrices, granValue, optimisationDt, 'price');

          substractedPrices = otherDaPricesGran.map((el, i) => Number((el - daPricesData[i]).toFixed(2)));
          legend = 'DA-DA prices'

        } else if (ibDt) {
          const imbPriceDataGran = calcGranData(
            imbPricesData,
            ibDt,
            optimisationDt,
            'price'
          );
          substractedPrices = daPricesData.map((el, i) => {
            if (imbPriceDataGran[i] === null)
              return null;
            else if (imbPriceDataGran[i] === 0)
              return Number(((imbUPricesGranData[i] + imbDPricesGranData[i])/2 - el).toFixed(2));
            else
              return Number((imbPriceDataGran[i] - el).toFixed(2));
          });
        }

        const pnArr = [
          result['Pn'], 
          ...this.state.daOptsData
            .filter(opt => opt.parameters.schedule === result.parameters.schedule && 
              opt.parameters.dt === result.parameters.dt)
            .map(el => el.Pn)
          ];
        const pnDataSum = pnArr.reduce((acc, curArr) => acc.map((val, i) => val + curArr[i]));
        //-------------------------------DA and IB chart data
        const {
          optimisationPdVol,
          optimisationImbVol,
          optimisationPrices,
          optimisationPaVol,
        } = helper.chartOptions (
          datesAr,
          substractedPrices,
          legend,
          daConfirmationData,
          isWithGeneration,
          daDifferenceForecast,
          daDifferenceActual,
          imbGranData,
          daPricesData,
          imbUPricesGranData,
          imbDPricesGranData,
          pnDataSum,
          idAllTrades,
          intraDayVolExec,
          this.state.currency
        );

        const header = (
          <>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Optimisation results - Park: {locationState.park.name}
                  <br />
                  <div className={classes.cardTitleWhite}>
                    {modelsWeightsString}
                    <ModelName
                      parkId={locationState.park.id}
                      idOptimisation={locationState.currentData.id}
                    />
                  </div>
                  <br />
                  {nextOpt && (
                    <Link
                      className={classes.arrowLeft}
                      to={{
                        pathname: "/optimisation_log",
                        state: {
                          currentData: {
                            id: nextOpt.id,
                            date: nextOpt.date,
                            optTime: nextOpt.time,
                          },
                          optimisations,
                          park: locationState.park,
                        },
                      }}
                    >
                      <IconButton title='prev optimisation'>
                        <ArrowBackIcon className={classes.whiteArrow}/>
                      </IconButton>
                    </Link>
                  )}
                  Optimisation time:{" "}
                  {moment
                    .tz(locationState.currentData.optTime, "UTC")
                    .format("DD MMM YYYY HH:mm:SS")}{" "}
                  GMT - Delivery day:{" "}
                  {moment
                    .tz(locationState.currentData.date, "UTC")
                    .format("DD MMM YYYY")}
                  {index > 0  && <Link
                    className={classes.arrowRight}
                    to={{
                      pathname: "/optimisation_log",
                      state: {
                        currentData: {
                          id: prevOpt.id,
                          date: prevOpt.date,
                          optTime: prevOpt.time,
                        },
                        optimisations,
                        park: locationState.park,
                      },
                    }}
                  >
                    <IconButton title='next optimisation'>
                      <ArrowForwardIcon className={classes.whiteArrow} />
                    </IconButton>
                  </Link>}
                </h4>
              </CardHeader>
              <CardBody></CardBody>
            </Card>
          </>
        );
        
        const intraDayTableReport = allIdTradeData.length ? (
          <>
            <Grid item xs={12}>
              <Card className={classes.bgColorAlt}>
                <CardHeader>
                  <h4
                    className={classNames(
                      classes.cardTitleBlack,
                      classes.textColor
                    )}
                  >
                    ID trades of this optimisation
                  </h4>
                </CardHeader>
                <CardBody className={classes.bgColorAlt}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <IntraDayNomExecReport
                        tableHead={tableHeader}
                        tableData={allIdTradeData}
                      />
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </Grid>
          </>
        ) : null;

        let content;
        if (this.props.optimisation) {
          content = (
            <>
              <Grid item xs={12}>
                <Card className={classes.bgColorAlt}>
                  <CardHeader>
                    <h4
                      className={classNames(
                        classes.cardTitleBlack,
                        classes.textColor
                      )}
                    >
                      Day ahead nomination & confirmation
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.bgColorAlt}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          constructorType={'stockChart'}
                          options={optimisationPdVol}
                        />
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </Grid>
              {isIntraDayTradingDay ? (
                <>
                  <Grid item xs={12}>
                    <Card className={classes.bgColorAlt}>
                      <CardHeader>
                        <h4
                          className={classNames(
                            classes.cardTitleBlack,
                            classes.textColor
                          )}
                        >
                          ID trades
                        </h4>
                      </CardHeader>
                      <CardBody className={classes.bgColorAlt}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'stockChart'}
                              options={optimisationPaVol}
                            />
                          </Grid>
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                  {intraDayTableReport}
                </>
              ) : null}
              {isIBData ? (
                <>
                  <Grid item xs={12}>
                    <Card className={classes.bgColorAlt}>
                      <CardHeader>
                        <h4
                          className={classNames(
                            classes.cardTitleBlack,
                            classes.textColor
                          )}
                        >
                          IMB Volumes
                        </h4>
                      </CardHeader>
                      <CardBody className={classes.bgColorAlt}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'stockChart'}
                              options={optimisationImbVol}
                            />
                          </Grid>
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className={classes.bgColorAlt}>
                      <CardHeader>
                        <h4
                          className={classNames(
                            classes.cardTitleBlack,
                            classes.textColor
                          )}
                        >
                          Prices
                        </h4>
                      </CardHeader>
                      <CardBody className={classes.bgColorAlt}>
                        <Grid container spacing={4}>
                          <Grid item xs={12}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              constructorType={'stockChart'}
                              options={optimisationPrices}
                            />
                          </Grid>
                        </Grid>
                      </CardBody>
                    </Card>
                  </Grid>
                </>
              ) : null}
            </>
          );
        } else if (this.props.loading) {
          content = loadingBlock;
        }
        return (
          <>
            {header}
            {content}
          </>
        );
      }
    }
    else
      return loadingBlock;
  }
}

const ConnectedOptimisationLog = connect(
  mapStateToProps,
  mapDispatchToProps
)(OptimisationLog);
export default withStyles(helper.styles)(ConnectedOptimisationLog);

