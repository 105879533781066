import React from 'react';

import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
import MaterialTable from '@material-table/core';

import withStyles from '@material-ui/core/styles/withStyles';
import { HelpOutline } from '@material-ui/icons';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const ScoreDetails = ({ scores, classes, winHeight, helpModeActive, tooltips }) => {
  let scoreses = {};
  let total_count = 0;
  let total_delay = 0;
  for (let i = 0; i < scores.length; i++) {
    if (!scoreses[scores[i].var]) {
      scoreses[scores[i].var] = {};
      scoreses[scores[i].var][scores[i].property] = scores[i].value;
    }
    scoreses[scores[i].var][scores[i].property] = scores[i].value;
    if (
      scoreses[scores[i].var]['updated'] === null ||
      scoreses[scores[i].var]['updated'] === undefined ||
      scoreses[scores[i].var]['updated'] > scores[i].updated
    ) {
      scoreses[scores[i].var]['updated'] = scores[i].updated;
    }
    if (
      scoreses[scores[i].var]['last_hour'] === null ||
      scoreses[scores[i].var]['last_hour'] === undefined
    ) {
      scoreses[scores[i].var]['last_hour'] = scores[i].last_hour;
    }
    if (
      scoreses[scores[i].var]['exact_count'] === null ||
      scoreses[scores[i].var]['exact_count'] === undefined
    ) {
      scoreses[scores[i].var]['exact_count'] = scores[i].exact_count;
    }
    if (
      scoreses[scores[i].var]['max_delay'] === null ||
      scoreses[scores[i].var]['max_delay'] === undefined
    ) {
      scoreses[scores[i].var]['max_delay'] = scores[i].max_delay;
    }
    if (
      scoreses[scores[i].var]['update_hour'] === null ||
      scoreses[scores[i].var]['update_hour'] === undefined
    ) {
      scoreses[scores[i].var]['update_hour'] = scores[i].update_hour;
    }
    if (scores[i].property === 'count') {
      total_count += scores[i].value;
    }
    if (scores[i].property === 'delay') {
      total_delay += scores[i].value;
      scoreses[scores[i].var]['retrieve_ts'] = scores[i].retrieve_ts;
    }
  }

  const headerStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    color: primaryColor,
    fontSize: '1em',
    padding: '12px 8px',
    textAlign: 'center',
    fontWeight: 500,
  };

  const columns = [
    {
      title: 'Num',
      field: 'num',
      width: 50,
      sorting: false,
      cellStyle: {
        backgroundColor: 'white',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      headerStyle: {
        left: '0px',
        zIndex: 3,
      },
    },
    {
      title: 'Variable',
      field: 'variable',
      width: 350,
      sorting: false,
      cellStyle: {
        backgroundColor: 'white',
        position: 'sticky',
        left: 50,
        zIndex: 1,
      },
      headerStyle: {
        left: 50,
        zIndex: 3,
      },
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Latest hour'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.latest_hour || 'Shows the timestamp of the latest available data. "DB" indicates that the value is taken from the database, and the specific timestamp, displayed in the "DD-MM hh:mm" format, is calculated using certain algorithms'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'latest_hour',
      width: 150,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'24 hours count'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.['24_hours_count'] || 'Represents the number of available data points over the last 24 hours. For hourly data, 24 points are expected, while for data with 15-minute intervals, 96 points are expected'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: '24_hours_count',
      width: 150,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Max delay(hour)'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.max_delay || 'Indicates the expected delay in data availability. For example, a value of "2" means that data in the database should have a 2-hour delay. If no data is available with such a delay, it means the data is arriving late'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'max_delay',
      width: 120,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Count'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.count || 'Represents the score awarded for data completeness (no gaps). The maximum value is 100. It is calculated based on the "24 h Count." For instance, if 24 values are expected but only 23 are available, the calculation would be: 23/24×100=95.83'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'count',
      width: 100,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Delay'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.delay || 'Represents the score awarded for data timeliness. The maximum value is 100. It is calculated based on the "Max Delay (Hour)" and the "Retrieved" timestamp. For example, if a 2-hour delay is expected but the latest data point arrived 3 hours ago, the score will be less than 100'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'delay',
      width: 100,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Audited'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.audited || 'Timestamp indicating when the data was last audited'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'audited',
      width: 150,
      sorting: false,
    },
    {
      title: (
        <div className={classes.flexContainer}>
          <span>{'Retrieved'}</span>
          {helpModeActive && (
            <CustomTooltip title={tooltips?.retrieved || 'Timestamp indicating when the data was fetched and loaded into the system'}>
              <HelpOutline fontSize='small'/>
            </CustomTooltip>
          )}
        </div>
      ),
      field: 'retrieved',
      width: 150,
      sorting: false,
    },
  ];

  const data = Object.keys(scoreses).map((key, index) => {
    const lastHour =
      scoreses[key].last_hour === 0
        ? 'DB'
        : scoreses[key].last_hour === 1
        ? moment.tz(moment(), 'Europe/Paris').hour() < 15
          ? moment
              .tz(
                moment
                  .tz(moment(), 'Europe/Paris')
                  .startOf('day')
                  .add(23, 'hour'),
                'Europe/Paris'
              )
              .utc()
              .format('MMM, DD HH:mm')
          : moment
              .tz(
                moment
                  .tz(moment(), 'Europe/Paris')
                  .startOf('day')
                  .add(47, 'hour'),
                'Europe/Paris'
              )
              .utc()
              .format('MMM, DD HH:mm')
        : scoreses[key].last_hour === 2
        ? moment
            .tz(moment(), 'UTC')
            .add(
              scoreses[key].max_delay > 0 ? -scoreses[key].max_delay : -1,
              'hour'
            )
            .format('MMM, DD HH:mm')
        : 'Unknown';

    const maxDelay =
      scoreses[key].max_delay === -10
        ? ''.concat('(', scoreses[key].update_hour, ')')
        : scoreses[key].max_delay === -2
        ? moment
            .tz(
              moment()
                .add(-1, 'month')
                .startOf('month')
                .add(-15, 'minute')
                .format('YYYY-MM-DD HH:mm'),
              'Europe/Paris'
            )
            .utc()
            .format('MMM, DD HH:mm')
        : scoreses[key].max_delay;

    const updated = moment
      .tz(scoreses[key].updated, 'UTC')
      .format('MMM DD HH:mm');

    const retrieve = moment
      .tz(scoreses[key].retrieve_ts, 'UTC')
      .format('MMM DD HH:mm');

    return {
      id: index,
      var: key,
      number: `${index + 1}`,
      ...scoreses[key],
      last_hour: lastHour,
      max_delay: maxDelay,
      updated,
      retrieve_ts: retrieve,
    };
  });

  data.push({
    var: 'Total',
    id: '',
    count: total_count,
    delay: total_delay,
    last_hour: '',
    max_delay: '',
    updated: '',
    retrieve_ts: '',
    exact_count: '',
  });

  const renderData = (data) => {
    const dataCountry = { ...data };
    delete dataCountry.update_hour;
    delete dataCountry.var;
    delete dataCountry.tableData;
    delete dataCountry.number;
    delete dataCountry.id;
    return (
      <TableRow hover>
        <TableCell className={`${classes.stickyCol}`}>{data.number}</TableCell>
        <TableCell className={`${classes.stickyCol} ${classes.variableCell}`}>
          {data.var}
        </TableCell>
        <TableCell align="center">{dataCountry.last_hour}</TableCell>
        <TableCell align="center">{dataCountry.exact_count}</TableCell>
        <TableCell align="center">{dataCountry.max_delay}</TableCell>
        <TableCell align="center">{dataCountry.count}</TableCell>
        <TableCell align="center">{dataCountry.delay}</TableCell>
        <TableCell align="center">{dataCountry.updated}</TableCell>
        <TableCell align="center">{dataCountry.retrieve_ts}</TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.tableResponsive}>
      <MaterialTable
        columns={columns}
        data={data}
        sor
        options={{
          toolbar: false,
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: winHeight - 250,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
        }}
        components={{
          Row: ({ data }) => renderData(data),
        }}
      />
    </div>
  );
};

ScoreDetails.defaultProps = {
  tableHeaderColor: 'primary',
};

ScoreDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default withStyles(tableStyle)(ScoreDetails);
