import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import { TextField, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete } from '@material-ui/lab';

import CustomButton from 'components/CustomButtons/Button.jsx';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import LoginPage from 'views/Login/Oops.jsx';
import {
  security_fetch_params,
  get_markets_data,
} from 'actions/index';
import { logout } from 'utils/auth';
import { downloadParks } from 'utils/getDataMethods';

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import moment from 'moment';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    conn: state.conn
  }
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: (data) => dispatch(get_markets_data(data)),
});

dayjs.extend(utc);

const MaintenanceCreate = ({ isLoggedIn, classes, helpModeActive, tooltips, markets, getMarkets, conn, location }) => {
  const [config, setConfig] = useState({
    message_kind: "",
    contract_type: {
      exchanges: ['EPEX']
    },
    comment: "",
    start_time: dayjs().format('YYYY-MM-DDTHH:mm:00'),
    end_time: dayjs().format('YYYY-MM-DDTHH:mm:00'),
  });
  const [isActive, setIsActive] = useState(false);
  const [parks, setParks] = useState([]);

  const getParks = async () => {
    const arr = await downloadParks();
    setParks(arr?.data || []);
  }

  const filteredParks = useMemo(() => {
    if (config.contract_type.markets) {
      const selectedMarkets = markets
        .filter(el => config.contract_type.markets.includes(el.country_iso3166a2))
        .map(el => el.name);
      const fParks = parks.filter(park => selectedMarkets.includes(park.market));
      return fParks;
    } else {
      return [];
    }
  }, [parks, config.contract_type.markets]);

  const getMaintenanceData = async (maintenanceId) => {
    const response = await fetch(`https://kube.sama.energy/gateway/api/maintenances/${maintenanceId}`, {
      method: 'GET',
      headers: {
      'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...security_fetch_params
    });

    const data = await response.json();
    
    delete data.id;
    setConfig({...config, ...data});
    setIsActive(false);
  }

  useEffect(() => {
    getMarkets();
    getParks();
    location.state.typeOperation === 'edit' && getMaintenanceData(location.state.maintenance_id)
  }, [])

  useEffect(() => {
    const isValidDate = (date) => date !== 'Invalid Date';

    const hasMarketsOrExchanges = location.state.maintenanceType === 'Local'
        ? config.contract_type?.markets?.length > 0
        : config.contract_type?.exchanges?.length > 0 &&
        config.message_kind; 

    const hasAllRequiredFields = 
        isValidDate(config.start_time) &&
        isValidDate(config.end_time) &&
        hasMarketsOrExchanges; 

    if (!isActive && hasAllRequiredFields) {
        setIsActive(true);
    } else if (isActive && !hasAllRequiredFields) {
        setIsActive(false);
    }
  }, [config]);

  const handleValueChange = (value, field, key) => {
    let maintenance = {...config};

    if (key === 'contract_type') {
      if (field === 'exchanges') maintenance.message_kind = '';
      maintenance['contract_type'][field] = value;
    } else {
      maintenance[field] = value;
    }

    setConfig(maintenance);
  };

  const handleCreateClick = async () => {
    const event_id = `${config.message_kind}:id-continuous:${moment(config.start_time).format('YYYYMMDDTHH:mm:ss')}`;
    const data = JSON.parse(JSON.stringify(location.state.typeOperation === 'edit' ? { ...config } : {...config, event_id}));

    if (location.state.maintenanceType === 'Local') {
      delete data.message_kind;
      delete data.contract_type.exchanges;
      delete data.event_id;

      data['message_kind'] = 'Local maintenance';
      data['contract_type'] = JSON.stringify(data.contract_type);
    } else {
      delete data.contract_type.markets;
      delete data.contract_type.parks;
    }

    const url = location.state.maintenanceType === 'Local' 
      ? `${conn.API_URL}/api/v1/maintenance` 
      : 'https://kube.sama.energy/gateway/api/maintenances';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: location.state.maintenanceType === 'Local' ? JSON.stringify({events: [data]}) : JSON.stringify(data),
      ...security_fetch_params
    })

    if (response.ok) {
      const data = await response.json();
      alertify.success(`Maintenance is successfully ${location.state.typeOperation === 'edit' ? 'updated' : 'created'}!`, 7);
    } else if (response.status === 401) {
      logout();
      return null;
    } else {
      alertify.error(`Error ${location.state.typeOperation === 'edit' ? 'updating' : 'creating new'} maintenance!`, 7);
      return null;
    }
  };

  const messageOptions = [
    ...(config.contract_type.exchanges?.includes('EPEX') ? ['ETS', 'M7'] : []),
    ...(config.contract_type.exchanges?.includes('AXPO DE') ? ['ID'] : [])
  ];
  
  if (isLoggedIn) {
    return (
      <Grid container spacing={4}>
        <Card>
          <CardHeader color="primary">
            <h4 
              className={classNames(classes.cardTitleWhite, classes.hidden)}
            >
              {'Create Maintenance'}
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} md={6} lg={4}>
                <Grid container spacing={2}>
                  {location.state.maintenanceType === 'Global' ? (
                    <>
                      <Grid item xs={12}>
                        <CustomTooltip 
                          title={tooltips?.market || ''}
                          disableFocusListener={!helpModeActive}
                          disableHoverListener={!helpModeActive}
                          disableTouchListener={!helpModeActive}
                        >
                          <Autocomplete 
                            value={config?.contract_type?.exchanges[0] || ''}
                            onChange={(e, v) => handleValueChange([v], 'exchanges', 'contract_type')}
                            options={['EPEX', 'AXPO DE']}
                            getOptionLabel={(option) => option}
                            disableClearable={true}
                            renderInput={(params) => (
                              <TextField {...params} variant='outlined' label='Exchanges'/>
                            )}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTooltip 
                          title={tooltips?.market || ''}
                          disableFocusListener={!helpModeActive}
                          disableHoverListener={!helpModeActive}
                          disableTouchListener={!helpModeActive}
                        >
                          <Autocomplete 
                            freeSolo
                            limitTags={1}
                            value={config.message_kind.split('-')[0].toUpperCase()}
                            onChange={(e, v) => handleValueChange(v ? `${v.toLowerCase()}-maintenance` : '', 'message_kind')}
                            options={messageOptions}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField {...params} variant='outlined' label='Message kind'/>
                            )}
                          />
                        </CustomTooltip>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <CustomTooltip 
                          title={tooltips?.market || ''}
                          disableFocusListener={!helpModeActive}
                          disableHoverListener={!helpModeActive}
                          disableTouchListener={!helpModeActive}
                        >
                          <Autocomplete 
                            multiple
                            value={markets.filter(el => config.contract_type.markets?.includes(el.country_iso3166a2)) || []}
                            onChange={(e, v) => handleValueChange(v.map(el => el.country_iso3166a2), 'markets', 'contract_type')}
                            options={markets}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField {...params} variant='outlined' label='Markets'/>
                            )}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <CustomTooltip 
                          title={tooltips?.market || ''}
                          disableFocusListener={!helpModeActive}
                          disableHoverListener={!helpModeActive}
                          disableTouchListener={!helpModeActive}
                        >
                          <Autocomplete 
                            multiple
                            value={filteredParks.filter(el => config.contract_type.parks?.includes(el.id)) || []}
                            onChange={(e, v) => handleValueChange(v.map(el => el.id), 'parks', 'contract_type')}
                            options={filteredParks}
                            disabled={!filteredParks.length}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField {...params} variant='outlined' label='Parks'/>
                            )}
                          />
                        </CustomTooltip>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={'Start time (UTC)'}
                        format="DD.MM.YYYY HH:mm"
                        ampm={false}
                        value={dayjs(config.start_time)}
                        className={classes.fullWidth}
                        onChange={(value) => handleValueChange(dayjs(value).format('YYYY-MM-DDTHH:mm:00'), 'start_time')}
                      />
                  </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={'End time (UTC)'}
                        format="DD.MM.YYYY HH:mm"
                        ampm={false}
                        value={dayjs(config.end_time)}
                        className={classes.fullWidth}
                        onChange={(value) => handleValueChange(dayjs(value).format('YYYY-MM-DDTHH:mm:00'), 'end_time')}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {location.state.maintenanceType === 'Global' && (
                    <Grid item xs={12}>
                      <CustomTooltip 
                        title={tooltips?.schedule || ''}
                        disableFocusListener={!helpModeActive}
                        disableHoverListener={!helpModeActive}
                        disableTouchListener={!helpModeActive}
                      >
                        <TextField 
                          type='text'
                          label={`Comment`}
                          value={config?.comment || ''}
                          variant='outlined'
                          fullWidth
                          onChange={(e) => handleValueChange(e.target.value, 'comment')}
                        />
                      </CustomTooltip>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <CustomButton 
                      color="primary" 
                      className={classes.fullWidth}
                      disabled={!isActive}
                      onClick={handleCreateClick} 
                      tooltip={tooltips?.save_button || ''}
                      helpModeActive={helpModeActive}
                    >
                      {location.state.typeOperation === 'edit' ? 'Save' : 'Create'}
                    </CustomButton>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    );
  } else {
    return (
      <LoginPage />
    )
  }
};

const ConnectedMaintenanceCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceCreate);
export default withStyles(dashboardStyle)(ConnectedMaintenanceCreate);
